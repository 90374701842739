/* prettier-ignore-start */
/* tslint-disable */
/* eslint-disable */

/* 该文件由 @pangu/end-type-to-front-type 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from '@pangu/end-type-to-front-type'
import request from '@/utils/request'
// @ts-ignore

/**
 * 接口 [新增账号↗](http://fe.jiuworker.com:3000/project/25/interface/api/279)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/add/account`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostBasicAddAccountBody {
  defaultPwd?: string
  enterName?: string
  experienceAccount?: boolean
  id?: number
  name?: string
  otherInfo?: string
  password?: string
  phone?: string
  powerIdList?: number[]
  roleId?: number
}

/* **返回类型** */
export type PostBasicAddAccountResponse = boolean

/* **请求函数** */
export async function postBasicAddAccount(body: PostBasicAddAccountBody): Promise<any> {
  return request(`/basic/add/account`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [添加角色↗](http://fe.jiuworker.com:3000/project/25/interface/api/281)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/add/role`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostBasicAddRoleBody {
  id?: number
  name?: string
}

/* **返回类型** */
export interface PostBasicAddRoleResponse {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefaultAdmin: number
  name: string
}

/* **请求函数** */
export async function postBasicAddRole(body: PostBasicAddRoleBody): Promise<any> {
  return request(`/basic/add/role`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除账户↗](http://fe.jiuworker.com:3000/project/25/interface/api/283)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `DELETE /basic/delete/account/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface DeleteBasicDeleteAccountByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteBasicDeleteAccountByIdResponse = boolean

/* **请求函数** */
export async function deleteBasicDeleteAccountById(params: DeleteBasicDeleteAccountByIdParams): Promise<any> {
  return request(`/basic/delete/account/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [删除角色↗](http://fe.jiuworker.com:3000/project/25/interface/api/285)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `DELETE /basic/delete/role/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface DeleteBasicDeleteRoleByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteBasicDeleteRoleByIdResponse = boolean

/* **请求函数** */
export async function deleteBasicDeleteRoleById(params: DeleteBasicDeleteRoleByIdParams): Promise<any> {
  return request(`/basic/delete/role/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑账号↗](http://fe.jiuworker.com:3000/project/25/interface/api/287)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `PUT /basic/edit/account`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PutBasicEditAccountBody {
  defaultPwd?: string
  enterName?: string
  experienceAccount?: boolean
  id?: number
  name?: string
  otherInfo?: string
  password?: string
  phone?: string
  powerIdList?: number[]
  roleId?: number
}

/* **返回类型** */
export type PutBasicEditAccountResponse = boolean

/* **请求函数** */
export async function putBasicEditAccount(body: PutBasicEditAccountBody): Promise<any> {
  return request(`/basic/edit/account`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [修改密码↗](http://fe.jiuworker.com:3000/project/25/interface/api/289)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/edit/password`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetBasicEditPasswordQuery {
  /**
   * newPassword
   */
  newPassword: string
  /**
   * md5加密
   */
  oldPassword?: string
}

/* **返回类型** */
export type GetBasicEditPasswordResponse = boolean

/* **请求函数** */
export async function getBasicEditPassword(query: GetBasicEditPasswordQuery): Promise<any> {
  return request(`/basic/edit/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [编辑角色↗](http://fe.jiuworker.com:3000/project/25/interface/api/291)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `PUT /basic/edit/role`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PutBasicEditRoleBody {
  id?: number
  name?: string
}

/* **返回类型** */
export type PutBasicEditRoleResponse = boolean

/* **请求函数** */
export async function putBasicEditRole(body: PutBasicEditRoleBody): Promise<any> {
  return request(`/basic/edit/role`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [更新企业基本信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/293)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `PUT /basic/enter/edit`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PutBasicEnterEditBody {
  address?: string
  businessLicense?: string
  city?: string
  /**
   * 10：标准版;20：客房派单
   */
  companyType?: number
  contractPerson?: string
  contractPhone?: string
  country?: string
  /**
   * 当前余额
   */
  currentCommission?: number
  email?: string
  /**
   * 消费记录
   */
  enterCommissionDTOList?: {
    commission?: string
    createDate?: string
    remark?: string
  }[]
  externalLogonId?: string
  financeAccountId?: number
  financeAccountName?: string
  /**
   * 已使用余额
   */
  hadUsedCommission?: number
  isCanSendToLabor?: boolean
  latitude?: string
  longitude?: string
  name?: string
  password?: string
  /**
   * 0:平台定价；1自己定价
   */
  priceType?: number
  province?: string
  /**
   * 客房数量
   */
  roomAmount?: number
  roomMoney?: number
  roomMoneyDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomSelfDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomStatus?: boolean
  sign?: boolean
}

/* **返回类型** */
export type PutBasicEnterEditResponse = boolean

/* **请求函数** */
export async function putBasicEnterEdit(body: PutBasicEnterEditBody): Promise<any> {
  return request(`/basic/enter/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询企业基本信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/295)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/enter/query`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export interface GetBasicEnterQueryResponse {
  bizData: string
  city: string
  country: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  /**
   * 0:平台定价；1自己定价
   */
  priceType: number
  province: string
  status: number
}

/* **请求函数** */
export async function getBasicEnterQuery(): Promise<any> {
  return request(`/basic/enter/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取当前登陆账户信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/297)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/info/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export interface GetBasicInfoAccountResponse {
  companyType: number
  currentTime: number
  enterId: number
  enterName: string
  isFirstLogin: boolean
  name: string
  phone: string
  roleId: number
  userId: number
}

/* **请求函数** */
export async function getBasicInfoAccount(): Promise<any> {
  return request(`/basic/info/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [账户登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/299)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/login/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicLoginAccountQuery {
  /**
   * md5加密
   */
  password?: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetBasicLoginAccountResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getBasicLoginAccount(query: GetBasicLoginAccountQuery): Promise<any> {
  return request(`/basic/login/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取随机验证码↗](http://fe.jiuworker.com:3000/project/25/interface/api/301)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/login/code`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicLoginCodeQuery {
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetBasicLoginCodeResponse = string

/* **请求函数** */
export async function getBasicLoginCode(query: GetBasicLoginCodeQuery): Promise<any> {
  return request(`/basic/login/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取登陆人的菜单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/303)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/menu/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicMenuAccountResponse = {
  id: number
  key: string
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getBasicMenuAccount(): Promise<any> {
  return request(`/basic/menu/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取短信验证码↗](http://fe.jiuworker.com:3000/project/25/interface/api/305)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/out/code`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicOutCodeQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetBasicOutCodeResponse = string

/* **请求函数** */
export async function getBasicOutCode(query: GetBasicOutCodeQuery): Promise<any> {
  return request(`/basic/out/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [短信验证码登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/307)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/out/code/login`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicOutCodeLoginQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetBasicOutCodeLoginResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getBasicOutCodeLogin(query: GetBasicOutCodeLoginQuery): Promise<any> {
  return request(`/basic/out/code/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [支付账户获取充值链接↗](http://fe.jiuworker.com:3000/project/25/interface/api/309)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/payAccount/charge`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostBasicPayAccountChargeBody {
  accountId?: number
  outBizNo?: string
  remark?: string
  transAmount?: string
}

/* **返回类型** */
export type PostBasicPayAccountChargeResponse = string

/* **请求函数** */
export async function postBasicPayAccountCharge(body: PostBasicPayAccountChargeBody): Promise<any> {
  return request(`/basic/payAccount/charge`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [isSign↗](http://fe.jiuworker.com:3000/project/25/interface/api/311)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/payAccount/isSign`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicPayAccountIsSignResponse = boolean

/* **请求函数** */
export async function getBasicPayAccountIsSign(): Promise<any> {
  return request(`/basic/payAccount/isSign`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取签约地址↗](http://fe.jiuworker.com:3000/project/25/interface/api/313)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/payAccount/preSign`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostBasicPayAccountPreSignBody {
  address?: string
  businessLicense?: string
  city?: string
  /**
   * 10：标准版;20：客房派单
   */
  companyType?: number
  contractPerson?: string
  contractPhone?: string
  country?: string
  /**
   * 当前余额
   */
  currentCommission?: number
  email?: string
  /**
   * 消费记录
   */
  enterCommissionDTOList?: {
    commission?: string
    createDate?: string
    remark?: string
  }[]
  externalLogonId?: string
  financeAccountId?: number
  financeAccountName?: string
  /**
   * 已使用余额
   */
  hadUsedCommission?: number
  isCanSendToLabor?: boolean
  latitude?: string
  longitude?: string
  name?: string
  password?: string
  /**
   * 0:平台定价；1自己定价
   */
  priceType?: number
  province?: string
  /**
   * 客房数量
   */
  roomAmount?: number
  roomMoney?: number
  roomMoneyDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomSelfDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomStatus?: boolean
  sign?: boolean
}

/* **返回类型** */
export type PostBasicPayAccountPreSignResponse = string

/* **请求函数** */
export async function postBasicPayAccountPreSign(body: PostBasicPayAccountPreSignBody): Promise<any> {
  return request(`/basic/payAccount/preSign`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [是否开通记账本↗](http://fe.jiuworker.com:3000/project/25/interface/api/315)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/payAccount/query`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicPayAccountQueryResponse = boolean

/* **请求函数** */
export async function getBasicPayAccountQuery(): Promise<any> {
  return request(`/basic/payAccount/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取余额↗](http://fe.jiuworker.com:3000/project/25/interface/api/317)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/payAccount/queryBalance`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicPayAccountQueryBalanceResponse = string

/* **请求函数** */
export async function getBasicPayAccountQueryBalance(): Promise<any> {
  return request(`/basic/payAccount/queryBalance`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取批量账单↗](http://fe.jiuworker.com:3000/project/25/interface/api/319)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/payAccount/queryBatchBill`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicPayAccountQueryBatchBillQuery {
  accountId?: string
  /**
   * 账单状态 SUCCESS 支付成功  WAIT_PAY 等待支付 FAIL 支付失败 CLOSED 已关闭
   */
  billStatus?: string
  /**
   * 账单类型，SALARY 薪水 PAYMENT  商务付款 ACCOUNT_BOOK_CHARGE  记账本充值
   */
  billType?: string
  endAmount?: string
  endTime?: string
  pageNum?: number
  pageSize?: number
  payChannel?: string
  /**
   *  查询标识主体类型, PAYEE 收款 PAYER 付款 ALL 全部
   */
  queryIdentityObjectType?: string
  startAmount?: string
  startTime?: string
}

/* **返回类型** */
export interface GetBasicPayAccountQueryBatchBillResponse {
  data: {
    billAmount: string
    billStatus: string
    billType: string
    originFundFlowId: string
    originOrderId: string
    originOrderTitle: string
    originOutBizNo: string
    otherAccountId: string
    otherAccountName: string
    payChannel: string
    payDate: string
    remark: string
  }[]
  total: number
}

/* **请求函数** */
export async function getBasicPayAccountQueryBatchBill(query: GetBasicPayAccountQueryBatchBillQuery): Promise<any> {
  return request(`/basic/payAccount/queryBatchBill`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [确认签约↗](http://fe.jiuworker.com:3000/project/25/interface/api/321)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/payAccount/signConfirm`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export interface PostBasicPayAccountSignConfirmResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function postBasicPayAccountSignConfirm(): Promise<any> {
  return request(`/basic/payAccount/signConfirm`, {
    method: Method.POST,
  })
}

/**
 * 接口 [分页查询账号↗](http://fe.jiuworker.com:3000/project/25/interface/api/323)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/query/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicQueryAccountQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * phone
   */
  phone?: string
  /**
   * roleId
   */
  roleId?: string
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetBasicQueryAccountResponse {
  data: {
    id: number
    name: string
    phone: string
    powerIdList: number[]
    roleId: number
    roleName: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getBasicQueryAccount(query: GetBasicQueryAccountQuery): Promise<any> {
  return request(`/basic/query/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [账户详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/325)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/query/account/detail/{id}`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求params类型** */
export interface GetBasicQueryAccountDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetBasicQueryAccountDetailByIdResponse {
  id: number
  name: string
  phone: string
  powerIdList: number[]
  roleId: number
  roleName: string
  status: number
}

/* **请求函数** */
export async function getBasicQueryAccountDetailById(params: GetBasicQueryAccountDetailByIdParams): Promise<any> {
  return request(`/basic/query/account/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询所有菜单↗](http://fe.jiuworker.com:3000/project/25/interface/api/327)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/query/menu`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicQueryMenuResponse = {
  id: number
  key: string
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getBasicQueryMenu(): Promise<any> {
  return request(`/basic/query/menu`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询角色↗](http://fe.jiuworker.com:3000/project/25/interface/api/329)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/query/role`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicQueryRoleQuery {
  /**
   * name
   */
  name?: string
}

/* **返回类型** */
export type GetBasicQueryRoleResponse = {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefaultAdmin: number
  name: string
}[]

/* **请求函数** */
export async function getBasicQueryRole(query: GetBasicQueryRoleQuery): Promise<any> {
  return request(`/basic/query/role`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询角色下的菜单↗](http://fe.jiuworker.com:3000/project/25/interface/api/331)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/role/auth/menu`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicRoleAuthMenuQuery {
  /**
   * roleId
   */
  roleId: string
}

/* **返回类型** */
export type GetBasicRoleAuthMenuResponse = {
  id: number
  key: string
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getBasicRoleAuthMenu(query: GetBasicRoleAuthMenuQuery): Promise<any> {
  return request(`/basic/role/auth/menu`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [角色关联菜单↗](http://fe.jiuworker.com:3000/project/25/interface/api/333)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `POST /basic/role/menu`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostBasicRoleMenuBody {
  menuIdList?: number[]
  roleId?: number
}

/* **返回类型** */
export type PostBasicRoleMenuResponse = boolean

/* **请求函数** */
export async function postBasicRoleMenu(body: PostBasicRoleMenuBody): Promise<any> {
  return request(`/basic/role/menu`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [启用禁用账户↗](http://fe.jiuworker.com:3000/project/25/interface/api/335)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/status/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicStatusAccountQuery {
  /**
   * id
   */
  id: string
  /**
   * 10:启用；20禁用
   */
  status?: string
}

/* **返回类型** */
export type GetBasicStatusAccountResponse = boolean

/* **请求函数** */
export async function getBasicStatusAccount(query: GetBasicStatusAccountQuery): Promise<any> {
  return request(`/basic/status/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [如果加入多个企业组织，登陆后在调这个接口获取token↗](http://fe.jiuworker.com:3000/project/25/interface/api/337)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/token/account`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetBasicTokenAccountQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetBasicTokenAccountResponse = string

/* **请求函数** */
export async function getBasicTokenAccount(query: GetBasicTokenAccountQuery): Promise<any> {
  return request(`/basic/token/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取余额↗](http://fe.jiuworker.com:3000/project/25/interface/api/2321)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/out/payAccount/queryBalance`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export type GetBasicOutPayAccountQueryBalanceResponse = string

/* **请求函数** */
export async function getBasicOutPayAccountQueryBalance(): Promise<any> {
  return request(`/basic/out/payAccount/queryBalance`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询企业基本信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/2415)
 *
 * @分类 [基础信息管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_30)
 * @标签 `基础信息管理`
 * @请求头 `GET /basic/enter/agent/query`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **返回类型** */
export interface GetBasicEnterAgentQueryResponse {
  id: number
  logo: string
  name: string
  username: string
}

/* **请求函数** */
export async function getBasicEnterAgentQuery(): Promise<any> {
  return request(`/basic/enter/agent/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [找回密码↗](http://fe.jiuworker.com:3000/project/25/interface/api/167)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/find/password`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountFindPasswordQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppAccountFindPasswordResponse = boolean

/* **请求函数** */
export async function getAppAccountFindPassword(query: GetAppAccountFindPasswordQuery): Promise<any> {
  return request(`/app/account/find/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [是否是首次登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/169)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/first/login`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountFirstLoginQuery {
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppAccountFirstLoginResponse = boolean

/* **请求函数** */
export async function getAppAccountFirstLogin(query: GetAppAccountFirstLoginQuery): Promise<any> {
  return request(`/app/account/first/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [首页的统计数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/171)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/index`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **返回类型** */
export interface GetAppAccountIndexResponse {
  abnormalAmount: number
  attendanceAmount: number
  insureAmount: number
  totalHour: number
  totalPeople: number
}

/* **请求函数** */
export async function getAppAccountIndex(): Promise<any> {
  return request(`/app/account/index`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取当前登陆账户信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/173)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/info/account`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **返回类型** */
export interface GetAppAccountInfoAccountResponse {
  companyType: number
  currentTime: number
  enterId: number
  enterName: string
  isFirstLogin: boolean
  name: string
  phone: string
  roleId: number
  userId: number
}

/* **请求函数** */
export async function getAppAccountInfoAccount(): Promise<any> {
  return request(`/app/account/info/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取登陆人的菜单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/175)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/menu/account`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **返回类型** */
export type GetAppAccountMenuAccountResponse = {
  id: number
  key: string
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getAppAccountMenuAccount(): Promise<any> {
  return request(`/app/account/menu/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [修改新密码↗](http://fe.jiuworker.com:3000/project/25/interface/api/177)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/new/password`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountNewPasswordQuery {
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone?: string
}

/* **返回类型** */
export type GetAppAccountNewPasswordResponse = boolean

/* **请求函数** */
export async function getAppAccountNewPassword(query: GetAppAccountNewPasswordQuery): Promise<any> {
  return request(`/app/account/new/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [微信绑定手机号↗](http://fe.jiuworker.com:3000/project/25/interface/api/179)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/auth/bind`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutAuthBindQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * openId
   */
  openId: string
  /**
   * phone
   */
  phone: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export interface GetAppAccountOutAuthBindResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutAuthBind(query: GetAppAccountOutAuthBindQuery): Promise<any> {
  return request(`/app/account/out/auth/bind`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [微信授权登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/181)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/auth/login`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutAuthLoginQuery {
  /**
   * code
   */
  code: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export interface GetAppAccountOutAuthLoginResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutAuthLogin(query: GetAppAccountOutAuthLoginQuery): Promise<any> {
  return request(`/app/account/out/auth/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取短信验证码↗](http://fe.jiuworker.com:3000/project/25/interface/api/183)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/code`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutCodeQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppAccountOutCodeResponse = string

/* **请求函数** */
export async function getAppAccountOutCode(query: GetAppAccountOutCodeQuery): Promise<any> {
  return request(`/app/account/out/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [短信验证码登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/185)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/code/login`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutCodeLoginQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetAppAccountOutCodeLoginResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutCodeLogin(query: GetAppAccountOutCodeLoginQuery): Promise<any> {
  return request(`/app/account/out/code/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [如果加入多个企业组织，登陆后在调这个接口获取token↗](http://fe.jiuworker.com:3000/project/25/interface/api/187)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/enter/account`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutEnterAccountQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppAccountOutEnterAccountResponse = string

/* **请求函数** */
export async function getAppAccountOutEnterAccount(query: GetAppAccountOutEnterAccountQuery): Promise<any> {
  return request(`/app/account/out/enter/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取随机验证码↗](http://fe.jiuworker.com:3000/project/25/interface/api/189)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/login/code`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **返回类型** */
export type GetAppAccountOutLoginCodeResponse = string

/* **请求函数** */
export async function getAppAccountOutLoginCode(): Promise<any> {
  return request(`/app/account/out/login/code`, {
    method: Method.GET,
  })
}

/**
 * 接口 [密码登陆↗](http://fe.jiuworker.com:3000/project/25/interface/api/191)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/password/login`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutPasswordLoginQuery {
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetAppAccountOutPasswordLoginResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutPasswordLogin(query: GetAppAccountOutPasswordLoginQuery): Promise<any> {
  return request(`/app/account/out/password/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [微信授权获取手机号↗](http://fe.jiuworker.com:3000/project/25/interface/api/193)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/out/phone`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountOutPhoneQuery {
  /**
   * code
   */
  code: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export type GetAppAccountOutPhoneResponse = string

/* **请求函数** */
export async function getAppAccountOutPhone(query: GetAppAccountOutPhoneQuery): Promise<any> {
  return request(`/app/account/out/phone`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [更新新的token↗](http://fe.jiuworker.com:3000/project/25/interface/api/1381)
 *
 * @分类 [小程序端接口:登陆相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_32)
 * @标签 `小程序端接口:登陆相关接口`
 * @请求头 `GET /app/account/update/token`
 * @更新时间 `2024-12-11 15:22:03`
 */

/* **请求query类型** */
export interface GetAppAccountUpdateTokenQuery {
  /**
   * 需要更换的酒店ID
   */
  enterId?: string
}

/* **返回类型** */
export type GetAppAccountUpdateTokenResponse = string

/* **请求函数** */
export async function getAppAccountUpdateToken(query: GetAppAccountUpdateTokenQuery): Promise<any> {
  return request(`/app/account/update/token`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [发起用工审批申请↗](http://fe.jiuworker.com:3000/project/25/interface/api/239)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `POST /app/work/apply`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostAppWorkApplyBody {
  /**
   * 审批单名称
   */
  applyName?: string
  applyType?: number
  /**
   * 补卡件数
   */
  cardAmount?: number
  /**
   * 补卡时间
   */
  cardDate?: string
  dayDate?: string
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  enterId?: number
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: {
    name?: string
    path?: string
    size?: string
  }[]
  planId?: number
  refuseRemark?: string
  /**
   * 休息时长
   */
  relaxTime?: string
  /**
   * 申请理由
   */
  remark?: string
  /**
   * 房间类型数量
   */
  roomApplyDTOList?: {
    roomAmount?: number
    roomType?: string
  }[]
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  userId?: number
}

/* **返回类型** */
export type PostAppWorkApplyResponse = boolean

/* **请求函数** */
export async function postAppWorkApply(body: PostAppWorkApplyBody): Promise<any> {
  return request(`/app/work/apply`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [处理用工审批↗](http://fe.jiuworker.com:3000/project/25/interface/api/241)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `POST /app/work/approve`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostAppWorkApproveBody {
  id?: number
  remark?: string
  result?: number
}

/* **返回类型** */
export type PostAppWorkApproveResponse = boolean

/* **请求函数** */
export async function postAppWorkApprove(body: PostAppWorkApproveBody): Promise<any> {
  return request(`/app/work/approve`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [我审批的和抄送我的↗](http://fe.jiuworker.com:3000/project/25/interface/api/243)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `GET /app/work/approve/query`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAppWorkApproveQueryQuery {
  '10:我审批的；20抄送我的'?: string
  '20已处理;30:待审批'?: string
  enterId?: string
  pageNum?: number
  pageSize?: number
  userId?: string
  发起开始时间?: string
  发起结束时间?: string
  申请单号?: string
  申请类型?: string
}

/* **返回类型** */
export interface GetAppWorkApproveQueryResponse {
  data: {
    /**
     * 审批单ID
     */
    applyId: number
    /**
     * 申请类型：10用工；20考勤
     */
    applyType: number
    /**
     * 发起人
     */
    applyUserName: string
    /**
     * 审批单号
     */
    approveNo: string
    enterWorkApplyDTO: {
      /**
       * 审批单名称
       */
      applyName: string
      applyType: number
      /**
       * 补卡件数
       */
      cardAmount: number
      /**
       * 补卡时间
       */
      cardDate: string
      dayDate: string
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 结束用工时段
       */
      endHour: string
      enterId: number
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: {
        name: string
        path: string
        size: string
      }[]
      planId: number
      refuseRemark: string
      /**
       * 休息时长
       */
      relaxTime: string
      /**
       * 申请理由
       */
      remark: string
      /**
       * 房间类型数量
       */
      roomApplyDTOList: {
        roomAmount: number
        roomType: string
      }[]
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      userId: number
    }
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 发起时间
     */
    gmtCreate: string
    id: number
    /**
     * 20：已处理；30未处理
     */
    isOperated: number
    /**
     * 需求人数
     */
    needPersonAmount: string
    /**
     * 用工计划名称
     */
    planName: string
    /**
     * 状态10:审批中；20已通过；30已拒绝；40已撤销
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppWorkApproveQuery(query: GetAppWorkApproveQueryQuery): Promise<any> {
  return request(`/app/work/approve/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [审批单详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/245)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `GET /app/work/detail/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface GetAppWorkDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppWorkDetailByIdResponse {
  /**
   * 类型：10用工；20考勤
   */
  applyType: number
  /**
   * 发起人
   */
  applyUserName: string
  approveFlowVOList: {
    detailVOList: {
      isOperate: number
      number: number
      operateDate: string
      operateName: string
      operateType: number
      remark: string
      status: number
    }[]
    number: number
  }[]
  /**
   * 审批单号
   */
  approveNo: string
  enterWorkApplyDTO: {
    /**
     * 审批单名称
     */
    applyName: string
    applyType: number
    /**
     * 补卡件数
     */
    cardAmount: number
    /**
     * 补卡时间
     */
    cardDate: string
    dayDate: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 结束用工时段
     */
    endHour: string
    enterId: number
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: {
      name: string
      path: string
      size: string
    }[]
    planId: number
    refuseRemark: string
    /**
     * 休息时长
     */
    relaxTime: string
    /**
     * 申请理由
     */
    remark: string
    /**
     * 房间类型数量
     */
    roomApplyDTOList: {
      roomAmount: number
      roomType: string
    }[]
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    userId: number
  }
  /**
   * 人脸照片
   */
  faceUrl: string
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 需求人数
   */
  needPersonAmount: string
  /**
   * 状态：10:审批中；20已完成；30已拒绝；40已撤销
   */
  status: number
}

/* **请求函数** */
export async function getAppWorkDetailById(params: GetAppWorkDetailByIdParams): Promise<any> {
  return request(`/app/work/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [劳务公司下拉框↗](http://fe.jiuworker.com:3000/project/25/interface/api/247)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `GET /app/work/labor/list`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **返回类型** */
export type GetAppWorkLaborListResponse = {
  id: number
  laborName: string
}[]

/* **请求函数** */
export async function getAppWorkLaborList(): Promise<any> {
  return request(`/app/work/labor/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [我发起的审批分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/249)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `GET /app/work/query`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAppWorkQueryQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 10:审批中；20已完成；30已拒绝；40已撤销
   */
  status?: string
}

/* **返回类型** */
export interface GetAppWorkQueryResponse {
  data: {
    /**
     * 类型：10用工；20考勤
     */
    applyType: number
    /**
     * 发起人
     */
    applyUserName: string
    approveFlowVOList: {
      detailVOList: {
        isOperate: number
        number: number
        operateDate: string
        operateName: string
        operateType: number
        remark: string
        status: number
      }[]
      number: number
    }[]
    /**
     * 审批单号
     */
    approveNo: string
    enterWorkApplyDTO: {
      /**
       * 审批单名称
       */
      applyName: string
      applyType: number
      /**
       * 补卡件数
       */
      cardAmount: number
      /**
       * 补卡时间
       */
      cardDate: string
      dayDate: string
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 结束用工时段
       */
      endHour: string
      enterId: number
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: {
        name: string
        path: string
        size: string
      }[]
      planId: number
      refuseRemark: string
      /**
       * 休息时长
       */
      relaxTime: string
      /**
       * 申请理由
       */
      remark: string
      /**
       * 房间类型数量
       */
      roomApplyDTOList: {
        roomAmount: number
        roomType: string
      }[]
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      userId: number
    }
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 创建时间
     */
    gmtCreate: string
    id: number
    /**
     * 需求人数
     */
    needPersonAmount: string
    /**
     * 状态：10:审批中；20已完成；30已拒绝；40已撤销
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppWorkQuery(query: GetAppWorkQueryQuery): Promise<any> {
  return request(`/app/work/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [审批单下拉框列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/251)
 *
 * @分类 [小程序端：用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_33)
 * @标签 `小程序端：用工审批管理`
 * @请求头 `GET /app/work/query/list`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **返回类型** */
export type GetAppWorkQueryListResponse = {
  /**
   * 类型：10用工；20考勤
   */
  applyType: number
  /**
   * 发起人
   */
  applyUserName: string
  approveFlowVOList: {
    detailVOList: {
      isOperate: number
      number: number
      operateDate: string
      operateName: string
      operateType: number
      remark: string
      status: number
    }[]
    number: number
  }[]
  /**
   * 审批单号
   */
  approveNo: string
  enterWorkApplyDTO: {
    /**
     * 审批单名称
     */
    applyName: string
    applyType: number
    /**
     * 补卡件数
     */
    cardAmount: number
    /**
     * 补卡时间
     */
    cardDate: string
    dayDate: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 结束用工时段
     */
    endHour: string
    enterId: number
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: {
      name: string
      path: string
      size: string
    }[]
    planId: number
    refuseRemark: string
    /**
     * 休息时长
     */
    relaxTime: string
    /**
     * 申请理由
     */
    remark: string
    /**
     * 房间类型数量
     */
    roomApplyDTOList: {
      roomAmount: number
      roomType: string
    }[]
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    userId: number
  }
  /**
   * 人脸照片
   */
  faceUrl: string
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 需求人数
   */
  needPersonAmount: string
  /**
   * 状态：10:审批中；20已完成；30已拒绝；40已撤销
   */
  status: number
}[]

/* **请求函数** */
export async function getAppWorkQueryList(): Promise<any> {
  return request(`/app/work/query/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [添加岗位↗](http://fe.jiuworker.com:3000/project/25/interface/api/399)
 *
 * @分类 [岗位管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_36)
 * @标签 `岗位管理`
 * @请求头 `POST /job/add`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostJobAddBody {
  /**
   * 节假日工资天
   */
  holidayWagesDay?: number
  /**
   * 节假日工资小时
   */
  holidayWagesHour?: number
  /**
   * 节假日工资件
   */
  holidayWagesItem?: number
  id?: number
  /**
   * 岗位工资天
   */
  jobWagesDay?: number
  /**
   * 岗位工资小时
   */
  jobWagesHour?: number
  /**
   * 岗位工资件
   */
  jobWagesItem?: number
  /**
   * 最大年龄
   */
  maxAge?: string
  /**
   * 最小年龄
   */
  minimumAge?: string
  /**
   * 岗位姓名
   */
  name?: string
  /**
   * 其他自定义要求
   */
  otherRule?: string
  /**
   * 性别
   */
  sex?: string
}

/* **返回类型** */
export type PostJobAddResponse = boolean

/* **请求函数** */
export async function postJobAdd(body: PostJobAddBody): Promise<any> {
  return request(`/job/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除岗位↗](http://fe.jiuworker.com:3000/project/25/interface/api/401)
 *
 * @分类 [岗位管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_36)
 * @标签 `岗位管理`
 * @请求头 `DELETE /job/delete/{id}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface DeleteJobDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteJobDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteJobDeleteById(params: DeleteJobDeleteByIdParams): Promise<any> {
  return request(`/job/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [查询工作详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/403)
 *
 * @分类 [岗位管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_36)
 * @标签 `岗位管理`
 * @请求头 `GET /job/detail/{id}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求params类型** */
export interface GetJobDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetJobDetailByIdResponse {
  /**
   * 节假日工资天
   */
  holidayWagesDay: number
  /**
   * 节假日工资小时
   */
  holidayWagesHour: number
  /**
   * 节假日工资件
   */
  holidayWagesItem: number
  id: number
  /**
   * 岗位工资天
   */
  jobWagesDay: number
  /**
   * 岗位工资小时
   */
  jobWagesHour: number
  /**
   * 岗位工资件
   */
  jobWagesItem: number
  /**
   * 最大年龄
   */
  maxAge: string
  /**
   * 最小年龄
   */
  minimumAge: string
  /**
   * 岗位姓名
   */
  name: string
  /**
   * 其他自定义要求
   */
  otherRule: string
  /**
   * 性别
   */
  sex: string
}

/* **请求函数** */
export async function getJobDetailById(params: GetJobDetailByIdParams): Promise<any> {
  return request(`/job/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑岗位↗](http://fe.jiuworker.com:3000/project/25/interface/api/405)
 *
 * @分类 [岗位管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_36)
 * @标签 `岗位管理`
 * @请求头 `PUT /job/edit`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PutJobEditBody {
  /**
   * 节假日工资天
   */
  holidayWagesDay?: number
  /**
   * 节假日工资小时
   */
  holidayWagesHour?: number
  /**
   * 节假日工资件
   */
  holidayWagesItem?: number
  id?: number
  /**
   * 岗位工资天
   */
  jobWagesDay?: number
  /**
   * 岗位工资小时
   */
  jobWagesHour?: number
  /**
   * 岗位工资件
   */
  jobWagesItem?: number
  /**
   * 最大年龄
   */
  maxAge?: string
  /**
   * 最小年龄
   */
  minimumAge?: string
  /**
   * 岗位姓名
   */
  name?: string
  /**
   * 其他自定义要求
   */
  otherRule?: string
  /**
   * 性别
   */
  sex?: string
}

/* **返回类型** */
export type PutJobEditResponse = boolean

/* **请求函数** */
export async function putJobEdit(body: PutJobEditBody): Promise<any> {
  return request(`/job/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/407)
 *
 * @分类 [岗位管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_36)
 * @标签 `岗位管理`
 * @请求头 `GET /job/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetJobQueryQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetJobQueryResponse {
  data: {
    /**
     * 节假日工资天
     */
    holidayWagesDay: number
    /**
     * 节假日工资小时
     */
    holidayWagesHour: number
    /**
     * 节假日工资件
     */
    holidayWagesItem: number
    id: number
    /**
     * 岗位工资天
     */
    jobWagesDay: number
    /**
     * 岗位工资小时
     */
    jobWagesHour: number
    /**
     * 岗位工资件
     */
    jobWagesItem: number
    /**
     * 最大年龄
     */
    maxAge: string
    /**
     * 最小年龄
     */
    minimumAge: string
    /**
     * 岗位姓名
     */
    name: string
    /**
     * 其他自定义要求
     */
    otherRule: string
    /**
     * 性别
     */
    sex: string
  }[]
  total: number
}

/* **请求函数** */
export async function getJobQuery(query: GetJobQueryQuery): Promise<any> {
  return request(`/job/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [生成二维码位置↗](http://fe.jiuworker.com:3000/project/25/interface/api/427)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_37)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/out/code/image`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export interface GetLoginOutCodeImageResponse {
  imgBase64: string
  qrUrl: string
  secret: string
}

/* **请求函数** */
export async function getLoginOutCodeImage(): Promise<any> {
  return request(`/login/out/code/image`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询扫码生成的token，这个接口需要前端轮询调用↗](http://fe.jiuworker.com:3000/project/25/interface/api/429)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_37)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/out/code/research`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetLoginOutCodeResearchQuery {
  /**
   * secret
   */
  secret: string
}

/* **返回类型** */
export interface GetLoginOutCodeResearchResponse {
  enterPOList: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }[]
  inputCount: number
  inputTime: number
  isHadError: boolean
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getLoginOutCodeResearch(query: GetLoginOutCodeResearchQuery): Promise<any> {
  return request(`/login/out/code/research`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [扫码生成token↗](http://fe.jiuworker.com:3000/project/25/interface/api/431)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_37)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/token/code`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetLoginTokenCodeQuery {
  /**
   * secret
   */
  secret: string
}

/* **返回类型** */
export type GetLoginTokenCodeResponse = boolean

/* **请求函数** */
export async function getLoginTokenCode(query: GetLoginTokenCodeQuery): Promise<any> {
  return request(`/login/token/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [图片地址↗](http://fe.jiuworker.com:3000/project/25/interface/api/359)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_38)
 * @标签 `文件管理`
 * @请求头 `POST /file/oss/url`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export type PostFileOssUrlResponse = string

/* **请求函数** */
export async function postFileOssUrl(): Promise<any> {
  return request(`/file/oss/url`, {
    method: Method.POST,
  })
}

/**
 * 接口 [OSS签权接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/361)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_38)
 * @标签 `文件管理`
 * @请求头 `GET /file/signature`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetFileSignatureQuery {
  /**
   * superKey
   */
  superKey: string
}

/* **返回类型** */
export interface GetFileSignatureResponse {
  accessId: string
  dir: string
  expire: string
  host: string
  policy: string
  signature: string
}

/* **请求函数** */
export async function getFileSignature(query: GetFileSignatureQuery): Promise<any> {
  return request(`/file/signature`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [OSS签权接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/677)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_38)
 * @标签 `文件管理`
 * @请求头 `GET /file/signature/oss`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetFileSignatureOssQuery {
  /**
   * superKey
   */
  superKey: string
}

/* **返回类型** */
export interface GetFileSignatureOssResponse {
  accessId: string
  dir: string
  expire: string
  host: string
  policy: string
  signature: string
}

/* **请求函数** */
export async function getFileSignatureOss(query: GetFileSignatureOssQuery): Promise<any> {
  return request(`/file/signature/oss`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [考勤统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/389)
 *
 * @分类 [概览管理接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_39)
 * @标签 `概览管理接口`
 * @请求头 `GET /index/attendance`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetIndexAttendanceQuery {
  /**
   * planId
   */
  planId?: string
  /**
   * queryDate
   */
  queryDate: string
}

/* **返回类型** */
export interface GetIndexAttendanceResponse {
  abstained: number
  attendancePeopleAmount: number
  back: number
  delay: number
  miss: number
  normal: number
  totalHour: number
  totalItem: number
  totalPeopleAmount: number
}

/* **请求函数** */
export async function getIndexAttendance(query: GetIndexAttendanceQuery): Promise<any> {
  return request(`/index/attendance`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工费用统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/391)
 *
 * @分类 [概览管理接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_39)
 * @标签 `概览管理接口`
 * @请求头 `GET /index/money`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetIndexMoneyResponse {
  indexMoneyDetailVOList: {
    attendanceDate: string
    money: number
  }[]
  todayMoney: number
  totalMoney: number
}

/* **请求函数** */
export async function getIndexMoney(): Promise<any> {
  return request(`/index/money`, {
    method: Method.GET,
  })
}

/**
 * 接口 [结算统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/393)
 *
 * @分类 [概览管理接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_39)
 * @标签 `概览管理接口`
 * @请求头 `GET /index/over`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetIndexOverResponse {
  monthMoney: number
  totalMoney: number
  yearDetailVOList: {
    money: number
    month: number
  }[]
}

/* **请求函数** */
export async function getIndexOver(): Promise<any> {
  return request(`/index/over`, {
    method: Method.GET,
  })
}

/**
 * 接口 [用工人数↗](http://fe.jiuworker.com:3000/project/25/interface/api/395)
 *
 * @分类 [概览管理接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_39)
 * @标签 `概览管理接口`
 * @请求头 `GET /index/people`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetIndexPeopleQuery {
  /**
   * 1:按月；2按天
   */
  checkValue?: string
}

/* **返回类型** */
export interface GetIndexPeopleResponse {
  enterIndexPeopleDetailVOList: {
    amount: number
    dayDate: string
    month: number
  }[]
  monthAmount: number
  todayAmount: number
}

/* **请求函数** */
export async function getIndexPeople(query: GetIndexPeopleQuery): Promise<any> {
  return request(`/index/people`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/397)
 *
 * @分类 [概览管理接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_39)
 * @标签 `概览管理接口`
 * @请求头 `GET /index/plan`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetIndexPlanResponse {
  money: number
  ordering: number
  peopleAmount: number
  waiting: number
  working: number
}

/* **请求函数** */
export async function getIndexPlan(): Promise<any> {
  return request(`/index/plan`, {
    method: Method.GET,
  })
}

/**
 * 接口 [考勤审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/375)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `POST /flow/attendance`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostFlowAttendanceBody {
  enterSettingDTOList?: {
    /**
     * 10:审批；20抄送
     */
    flowType?: number
    /**
     * 序号
     */
    number?: number
    /**
     * 操作人
     */
    userIdList?: number[]
  }[]
}

/* **返回类型** */
export type PostFlowAttendanceResponse = boolean

/* **请求函数** */
export async function postFlowAttendance(body: PostFlowAttendanceBody): Promise<any> {
  return request(`/flow/attendance`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [考勤审批设置查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/377)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `GET /flow/attendance/query`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetFlowAttendanceQueryResponse {
  enterSettingDTOList: {
    /**
     * 10:审批；20抄送
     */
    flowType: number
    /**
     * 序号
     */
    number: number
    /**
     * 操作人
     */
    userIdList: number[]
  }[]
}

/* **请求函数** */
export async function getFlowAttendanceQuery(): Promise<any> {
  return request(`/flow/attendance/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [其他配置：按天计价↗](http://fe.jiuworker.com:3000/project/25/interface/api/379)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `POST /flow/other/day/setting`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface PostFlowOtherDaySettingQuery {
  /**
   * dayCount
   */
  dayCount: string
  /**
   * dayHour
   */
  dayHour: string
}

/* **返回类型** */
export type PostFlowOtherDaySettingResponse = boolean

/* **请求函数** */
export async function postFlowOtherDaySetting(query: PostFlowOtherDaySettingQuery): Promise<any> {
  return request(`/flow/other/day/setting`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [其他配置：按小时计价↗](http://fe.jiuworker.com:3000/project/25/interface/api/381)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `POST /flow/other/hour/setting`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface PostFlowOtherHourSettingQuery {
  /**
   * hour
   */
  hour: string
  /**
   * hourCount
   */
  hourCount: string
}

/* **返回类型** */
export type PostFlowOtherHourSettingResponse = boolean

/* **请求函数** */
export async function postFlowOtherHourSetting(query: PostFlowOtherHourSettingQuery): Promise<any> {
  return request(`/flow/other/hour/setting`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [其他配置查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/383)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `GET /flow/other/query`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetFlowOtherQueryResponse {
  /**
   * 按天：扣减的天数
   */
  dayCount: number
  /**
   * 按天：迟到小时
   */
  dayHour: number
  /**
   * 按小时：迟到小时
   */
  hour: number
  /**
   * 按小时：扣减的小时
   */
  hourCount: number
}

/* **请求函数** */
export async function getFlowOtherQuery(): Promise<any> {
  return request(`/flow/other/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [用工审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/385)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `POST /flow/work`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostFlowWorkBody {
  enterSettingDTOList?: {
    /**
     * 10:审批；20抄送
     */
    flowType?: number
    /**
     * 序号
     */
    number?: number
    /**
     * 操作人
     */
    userIdList?: number[]
  }[]
}

/* **返回类型** */
export type PostFlowWorkResponse = boolean

/* **请求函数** */
export async function postFlowWork(body: PostFlowWorkBody): Promise<any> {
  return request(`/flow/work`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [用工审批设置查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/387)
 *
 * @分类 [流程审批设置↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_40)
 * @标签 `流程审批设置`
 * @请求头 `GET /flow/work/query`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **返回类型** */
export interface GetFlowWorkQueryResponse {
  enterSettingDTOList: {
    /**
     * 10:审批；20抄送
     */
    flowType: number
    /**
     * 序号
     */
    number: number
    /**
     * 操作人
     */
    userIdList: number[]
  }[]
}

/* **请求函数** */
export async function getFlowWorkQuery(): Promise<any> {
  return request(`/flow/work/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [发起用工审批申请↗](http://fe.jiuworker.com:3000/project/25/interface/api/515)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `POST /work/apply`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求body类型** */
export interface PostWorkApplyBody {
  /**
   * 审批单名称
   */
  applyName?: string
  applyType?: number
  /**
   * 补卡件数
   */
  cardAmount?: number
  /**
   * 补卡时间
   */
  cardDate?: string
  dayDate?: string
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  enterId?: number
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: {
    name?: string
    path?: string
    size?: string
  }[]
  planId?: number
  refuseRemark?: string
  /**
   * 休息时长
   */
  relaxTime?: string
  /**
   * 申请理由
   */
  remark?: string
  /**
   * 房间类型数量
   */
  roomApplyDTOList?: {
    roomAmount?: number
    roomType?: string
  }[]
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  userId?: number
}

/* **返回类型** */
export type PostWorkApplyResponse = boolean

/* **请求函数** */
export async function postWorkApply(body: PostWorkApplyBody): Promise<any> {
  return request(`/work/apply`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [处理用工审批↗](http://fe.jiuworker.com:3000/project/25/interface/api/517)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `POST /work/approve`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求body类型** */
export interface PostWorkApproveBody {
  id?: number
  remark?: string
  result?: number
}

/* **返回类型** */
export type PostWorkApproveResponse = boolean

/* **请求函数** */
export async function postWorkApprove(body: PostWorkApproveBody): Promise<any> {
  return request(`/work/approve`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [我审批的和抄送我的↗](http://fe.jiuworker.com:3000/project/25/interface/api/519)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `GET /work/approve/query`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetWorkApproveQueryQuery {
  '10:我审批的；20抄送我的'?: string
  '20已处理;30:待审批'?: string
  enterId?: string
  pageNum?: number
  pageSize?: number
  userId?: string
  发起开始时间?: string
  发起结束时间?: string
  申请单号?: string
  申请类型?: string
}

/* **返回类型** */
export interface GetWorkApproveQueryResponse {
  data: {
    /**
     * 审批单ID
     */
    applyId: number
    /**
     * 申请类型：10用工；20考勤
     */
    applyType: number
    /**
     * 发起人
     */
    applyUserName: string
    /**
     * 审批单号
     */
    approveNo: string
    enterWorkApplyDTO: {
      /**
       * 审批单名称
       */
      applyName: string
      applyType: number
      /**
       * 补卡件数
       */
      cardAmount: number
      /**
       * 补卡时间
       */
      cardDate: string
      dayDate: string
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 结束用工时段
       */
      endHour: string
      enterId: number
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: {
        name: string
        path: string
        size: string
      }[]
      planId: number
      refuseRemark: string
      /**
       * 休息时长
       */
      relaxTime: string
      /**
       * 申请理由
       */
      remark: string
      /**
       * 房间类型数量
       */
      roomApplyDTOList: {
        roomAmount: number
        roomType: string
      }[]
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      userId: number
    }
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 发起时间
     */
    gmtCreate: string
    id: number
    /**
     * 20：已处理；30未处理
     */
    isOperated: number
    /**
     * 需求人数
     */
    needPersonAmount: string
    /**
     * 用工计划名称
     */
    planName: string
    /**
     * 状态10:审批中；20已通过；30已拒绝；40已撤销
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getWorkApproveQuery(query: GetWorkApproveQueryQuery): Promise<any> {
  return request(`/work/approve/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [撤回↗](http://fe.jiuworker.com:3000/project/25/interface/api/521)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `GET /work/back/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface GetWorkBackByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetWorkBackByIdResponse = boolean

/* **请求函数** */
export async function getWorkBackById(params: GetWorkBackByIdParams): Promise<any> {
  return request(`/work/back/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [审批单详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/523)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `GET /work/detail/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface GetWorkDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetWorkDetailByIdResponse {
  /**
   * 类型：10用工；20考勤
   */
  applyType: number
  /**
   * 发起人
   */
  applyUserName: string
  approveFlowVOList: {
    detailVOList: {
      isOperate: number
      number: number
      operateDate: string
      operateName: string
      operateType: number
      remark: string
      status: number
    }[]
    number: number
  }[]
  /**
   * 审批单号
   */
  approveNo: string
  enterWorkApplyDTO: {
    /**
     * 审批单名称
     */
    applyName: string
    applyType: number
    /**
     * 补卡件数
     */
    cardAmount: number
    /**
     * 补卡时间
     */
    cardDate: string
    dayDate: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 结束用工时段
     */
    endHour: string
    enterId: number
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: {
      name: string
      path: string
      size: string
    }[]
    planId: number
    refuseRemark: string
    /**
     * 休息时长
     */
    relaxTime: string
    /**
     * 申请理由
     */
    remark: string
    /**
     * 房间类型数量
     */
    roomApplyDTOList: {
      roomAmount: number
      roomType: string
    }[]
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    userId: number
  }
  /**
   * 人脸照片
   */
  faceUrl: string
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 需求人数
   */
  needPersonAmount: string
  /**
   * 状态：10:审批中；20已完成；30已拒绝；40已撤销
   */
  status: number
}

/* **请求函数** */
export async function getWorkDetailById(params: GetWorkDetailByIdParams): Promise<any> {
  return request(`/work/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [催审批↗](http://fe.jiuworker.com:3000/project/25/interface/api/525)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `GET /work/push/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface GetWorkPushByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetWorkPushByIdResponse = boolean

/* **请求函数** */
export async function getWorkPushById(params: GetWorkPushByIdParams): Promise<any> {
  return request(`/work/push/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [我发起的审批分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/527)
 *
 * @分类 [用工审批管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_42)
 * @标签 `用工审批管理`
 * @请求头 `GET /work/query`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetWorkQueryQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 10:审批中；20已完成；30已拒绝；40已撤销
   */
  status?: string
}

/* **返回类型** */
export interface GetWorkQueryResponse {
  data: {
    /**
     * 类型：10用工；20考勤
     */
    applyType: number
    /**
     * 发起人
     */
    applyUserName: string
    approveFlowVOList: {
      detailVOList: {
        isOperate: number
        number: number
        operateDate: string
        operateName: string
        operateType: number
        remark: string
        status: number
      }[]
      number: number
    }[]
    /**
     * 审批单号
     */
    approveNo: string
    enterWorkApplyDTO: {
      /**
       * 审批单名称
       */
      applyName: string
      applyType: number
      /**
       * 补卡件数
       */
      cardAmount: number
      /**
       * 补卡时间
       */
      cardDate: string
      dayDate: string
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 结束用工时段
       */
      endHour: string
      enterId: number
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: {
        name: string
        path: string
        size: string
      }[]
      planId: number
      refuseRemark: string
      /**
       * 休息时长
       */
      relaxTime: string
      /**
       * 申请理由
       */
      remark: string
      /**
       * 房间类型数量
       */
      roomApplyDTOList: {
        roomAmount: number
        roomType: string
      }[]
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      userId: number
    }
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 创建时间
     */
    gmtCreate: string
    id: number
    /**
     * 需求人数
     */
    needPersonAmount: string
    /**
     * 状态：10:审批中；20已完成；30已拒绝；40已撤销
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getWorkQuery(query: GetWorkQueryQuery): Promise<any> {
  return request(`/work/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [提现↗](http://fe.jiuworker.com:3000/project/25/interface/api/363)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `POST /finance/cashOut`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostFinanceCashOutBody {
  outBizNo?: string
  /**
   * 密码
   */
  passWord?: string
  payerAccountId?: number
  /**
   * 提现数量
   */
  transAmount?: string
}

/* **返回类型** */
export type PostFinanceCashOutResponse = boolean

/* **请求函数** */
export async function postFinanceCashOut(body: PostFinanceCashOutBody): Promise<any> {
  return request(`/finance/cashOut`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [线下结算↗](http://fe.jiuworker.com:3000/project/25/interface/api/365)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `POST /finance/off/settlement`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostFinanceOffSettlementBody {
  enterId?: number
  /**
   * 附件列表
   */
  fileList?: {
    name?: string
    path?: string
    size?: string
  }[]
  /**
   * 是否结清
   */
  isOver?: boolean
  /**
   * 结算金额
   */
  money?: number
  /**
   * 密码
   */
  passWord?: string
  /**
   * 用工计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
}

/* **返回类型** */
export type PostFinanceOffSettlementResponse = boolean

/* **请求函数** */
export async function postFinanceOffSettlement(body: PostFinanceOffSettlementBody): Promise<any> {
  return request(`/finance/off/settlement`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [线上结算↗](http://fe.jiuworker.com:3000/project/25/interface/api/367)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `POST /finance/on/settlement`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostFinanceOnSettlementBody {
  enterId?: number
  /**
   * 附件列表
   */
  fileList?: {
    name?: string
    path?: string
    size?: string
  }[]
  /**
   * 是否结清
   */
  isOver?: boolean
  /**
   * 结算金额
   */
  money?: number
  /**
   * 密码
   */
  passWord?: string
  /**
   * 用工计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
}

/* **返回类型** */
export type PostFinanceOnSettlementResponse = boolean

/* **请求函数** */
export async function postFinanceOnSettlement(body: PostFinanceOnSettlementBody): Promise<any> {
  return request(`/finance/on/settlement`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/369)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `GET /finance/query`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetFinanceQueryQuery {
  /**
   * laborId
   */
  laborId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetFinanceQueryResponse {
  data: {
    isOver: boolean
    laborName: string
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    orderNo: string
    payableWages: number
    personCount: number
    planId: number
    planName: string
    realWages: number
    status: number
    totalDay: number
    totalHour: number
    totalItem: number
    voList: {
      fileList: {
        name: string
        path: string
        size: string
      }[]
      gmtCreate: string
      money: number
      orderNumber: string
      payType: number
      remark: string
      status: number
    }[]
    waitMoney: number
    workDate: string
  }[]
  total: number
}

/* **请求函数** */
export async function getFinanceQuery(query: GetFinanceQueryQuery): Promise<any> {
  return request(`/finance/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/371)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `GET /finance/query/detail/{planId}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface GetFinanceQueryDetailByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetFinanceQueryDetailByPlanIdResponse {
  isOver: boolean
  laborName: string
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType: number
  orderNo: string
  payableWages: number
  personCount: number
  planId: number
  planName: string
  realWages: number
  status: number
  totalDay: number
  totalHour: number
  totalItem: number
  voList: {
    fileList: {
      name: string
      path: string
      size: string
    }[]
    gmtCreate: string
    money: number
    orderNumber: string
    payType: number
    remark: string
    status: number
  }[]
  waitMoney: number
  workDate: string
}

/* **请求函数** */
export async function getFinanceQueryDetailByPlanId(params: GetFinanceQueryDetailByPlanIdParams): Promise<any> {
  return request(`/finance/query/detail/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [设置支付密码↗](http://fe.jiuworker.com:3000/project/25/interface/api/373)
 *
 * @分类 [结算管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_44)
 * @标签 `结算管理`
 * @请求头 `GET /finance/set/password`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetFinanceSetPasswordQuery {
  /**
   * code
   */
  code: string
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetFinanceSetPasswordResponse = boolean

/* **请求函数** */
export async function getFinanceSetPassword(query: GetFinanceSetPasswordQuery): Promise<any> {
  return request(`/finance/set/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [发起补卡补件申请↗](http://fe.jiuworker.com:3000/project/25/interface/api/205)
 *
 * @分类 [蓝色荣耀端：工人考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_46)
 * @标签 `蓝色荣耀端：工人考勤`
 * @请求头 `POST /app/out/apply`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppOutApplyBody {
  applyName?: string
  /**
   * 补卡件数
   */
  cardAmount?: number
  /**
   * 补卡时间
   */
  cardDate?: string
  cardNo?: string
  dayDate?: string
  enterId?: number
  planId?: number
  remark?: string
  /**
   * 房间类型数量
   */
  roomApplyDTOList?: {
    roomAmount?: number
    roomType?: string
  }[]
}

/* **返回类型** */
export type PostAppOutApplyResponse = boolean

/* **请求函数** */
export async function postAppOutApply(body: PostAppOutApplyBody): Promise<any> {
  return request(`/app/out/apply`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [撤回↗](http://fe.jiuworker.com:3000/project/25/interface/api/207)
 *
 * @分类 [蓝色荣耀端：工人考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_46)
 * @标签 `蓝色荣耀端：工人考勤`
 * @请求头 `GET /app/out/back/{id}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求params类型** */
export interface GetAppOutBackByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetAppOutBackByIdResponse = boolean

/* **请求函数** */
export async function getAppOutBackById(params: GetAppOutBackByIdParams): Promise<any> {
  return request(`/app/out/back/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [审批单详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/209)
 *
 * @分类 [蓝色荣耀端：工人考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_46)
 * @标签 `蓝色荣耀端：工人考勤`
 * @请求头 `GET /app/out/detail/{applyId}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求params类型** */
export interface GetAppOutDetailByApplyIdParams {
  /**
   * applyId
   */
  applyId: string
}

/* **返回类型** */
export interface GetAppOutDetailByApplyIdResponse {
  /**
   * 类型：10用工；20考勤
   */
  applyType: number
  /**
   * 发起人
   */
  applyUserName: string
  approveFlowVOList: {
    detailVOList: {
      isOperate: number
      number: number
      operateDate: string
      operateName: string
      operateType: number
      remark: string
      status: number
    }[]
    number: number
  }[]
  /**
   * 审批单号
   */
  approveNo: string
  enterWorkApplyDTO: {
    /**
     * 审批单名称
     */
    applyName: string
    applyType: number
    /**
     * 补卡件数
     */
    cardAmount: number
    /**
     * 补卡时间
     */
    cardDate: string
    dayDate: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 结束用工时段
     */
    endHour: string
    enterId: number
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: {
      name: string
      path: string
      size: string
    }[]
    planId: number
    refuseRemark: string
    /**
     * 休息时长
     */
    relaxTime: string
    /**
     * 申请理由
     */
    remark: string
    /**
     * 房间类型数量
     */
    roomApplyDTOList: {
      roomAmount: number
      roomType: string
    }[]
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    userId: number
  }
  /**
   * 人脸照片
   */
  faceUrl: string
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 需求人数
   */
  needPersonAmount: string
  /**
   * 状态：10:审批中；20已完成；30已拒绝；40已撤销
   */
  status: number
}

/* **请求函数** */
export async function getAppOutDetailByApplyId(params: GetAppOutDetailByApplyIdParams): Promise<any> {
  return request(`/app/out/detail/${params.applyId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [我发起的审批列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/211)
 *
 * @分类 [蓝色荣耀端：工人考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_46)
 * @标签 `蓝色荣耀端：工人考勤`
 * @请求头 `GET /app/out/query/list`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutQueryListQuery {
  /**
   * applyName
   */
  applyName?: string
  /**
   * cardNo
   */
  cardNo: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppOutQueryListResponse {
  data: {
    applyId: number
    applyName: string
    approveRemark: string
    cardAmount: number
    cardDate: string
    enterId: number
    faceUrl: string
    gtmCreate: string
    remark: string
    status: number
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppOutQueryList(query: GetAppOutQueryListQuery): Promise<any> {
  return request(`/app/out/query/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [编辑部门↗](http://fe.jiuworker.com:3000/project/25/interface/api/1285)
 *
 * @分类 [职工部门模块↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_91)
 * @标签 `职工部门模块`
 * @请求头 `PUT /dept/edit`
 * @更新时间 `2023-11-08 14:03:21`
 */

/* **请求body类型** */
export interface PutDeptEditBody {
  id?: number
  leaderPersonId?: number
  name?: string
  parentId?: number
  phone?: string
}

/* **返回类型** */
export type PutDeptEditResponse = boolean

/* **请求函数** */
export async function putDeptEdit(body: PutDeptEditBody): Promise<any> {
  return request(`/dept/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [新增班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/1307)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_93)
 * @标签 `职工部门模块/班次`
 * @请求头 `POST /dept/team/add`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PostDeptTeamAddBody {
  arrangeId?: number
  /**
   * 考勤下班时间
   */
  endHour?: string
  enterId?: number
  id?: number
  /**
   * 是否为默认班次
   */
  isDefault?: boolean
  name?: string
  /**
   * 休息结束时间
   */
  relaxEndHour?: string
  /**
   * 休息开始时间
   */
  relaxStartHour?: string
  /**
   * 是否有休息时间
   */
  relaxTime?: boolean
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 考勤上班时间
   */
  startHour?: string
}

/* **返回类型** */
export type PostDeptTeamAddResponse = boolean

/* **请求函数** */
export async function postDeptTeamAdd(body: PostDeptTeamAddBody): Promise<any> {
  return request(`/dept/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/1309)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_93)
 * @标签 `职工部门模块/班次`
 * @请求头 `DELETE /dept/team/delete/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface DeleteDeptTeamDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptTeamDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptTeamDeleteById(params: DeleteDeptTeamDeleteByIdParams): Promise<any> {
  return request(`/dept/team/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/1311)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_93)
 * @标签 `职工部门模块/班次`
 * @请求头 `PUT /dept/team/edit`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PutDeptTeamEditBody {
  arrangeId?: number
  /**
   * 考勤下班时间
   */
  endHour?: string
  enterId?: number
  id?: number
  /**
   * 是否为默认班次
   */
  isDefault?: boolean
  name?: string
  /**
   * 休息结束时间
   */
  relaxEndHour?: string
  /**
   * 休息开始时间
   */
  relaxStartHour?: string
  /**
   * 是否有休息时间
   */
  relaxTime?: boolean
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 考勤上班时间
   */
  startHour?: string
}

/* **返回类型** */
export type PutDeptTeamEditResponse = boolean

/* **请求函数** */
export async function putDeptTeamEdit(body: PutDeptTeamEditBody): Promise<any> {
  return request(`/dept/team/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询班次列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/1313)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_93)
 * @标签 `职工部门模块/班次`
 * @请求头 `GET /dept/team/query`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptTeamQueryResponse = {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}[]

/* **请求函数** */
export async function getDeptTeamQuery(): Promise<any> {
  return request(`/dept/team/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询班次详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1317)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_93)
 * @标签 `职工部门模块/班次`
 * @请求头 `GET /dept/team/query/detail/{id}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface GetDeptTeamQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptTeamQueryDetailByIdResponse {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}

/* **请求函数** */
export async function getDeptTeamQueryDetailById(params: GetDeptTeamQueryDetailByIdParams): Promise<any> {
  return request(`/dept/team/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [新增部门↗](http://fe.jiuworker.com:3000/project/25/interface/api/1259)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/add`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostDeptAddBody {
  enterId?: number
  id?: number
  leaderPersonId?: number
  name?: string
  parentId?: number
  phone?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptAddResponse = boolean

/* **请求函数** */
export async function postDeptAdd(body: PostDeptAddBody): Promise<any> {
  return request(`/dept/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [新增人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/1261)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/add/person`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostDeptAddPersonBody {
  deptIdList?: number[]
  email?: string
  enterId?: number
  faceUrl?: string
  id?: number
  job?: string
  mainDeptId?: number
  name?: string
  phone?: string
  sex?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  workNo?: string
}

/* **返回类型** */
export type PostDeptAddPersonResponse = boolean

/* **请求函数** */
export async function postDeptAddPerson(body: PostDeptAddPersonBody): Promise<any> {
  return request(`/dept/add/person`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [批量调整部门↗](http://fe.jiuworker.com:3000/project/25/interface/api/1263)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `PUT /dept/adjust/person`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PutDeptAdjustPersonBody {
  deptIdList?: number[]
  enterId?: number
  mainDeptId?: number
  personIdList?: number[]
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PutDeptAdjustPersonResponse = boolean

/* **请求函数** */
export async function putDeptAdjustPerson(body: PutDeptAdjustPersonBody): Promise<any> {
  return request(`/dept/adjust/person`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [删除人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/1265)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `DELETE /dept/delete/person`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface DeleteDeptDeletePersonQuery {
  /**
   * 多个逗号隔开
   */
  ids?: string
}

/* **返回类型** */
export type DeleteDeptDeletePersonResponse = boolean

/* **请求函数** */
export async function deleteDeptDeletePerson(query: DeleteDeptDeletePersonQuery): Promise<any> {
  return request(`/dept/delete/person`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [删除部门↗](http://fe.jiuworker.com:3000/project/25/interface/api/1267)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `DELETE /dept/delete/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface DeleteDeptDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptDeleteById(params: DeleteDeptDeleteByIdParams): Promise<any> {
  return request(`/dept/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [部门列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/1269)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/dept/list`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptDeptListResponse = {
  bizData: string
  enterId: number
  fullName: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getDeptDeptList(): Promise<any> {
  return request(`/dept/dept/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑部门↗](http://fe.jiuworker.com:3000/project/25/interface/api/1271)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/edit`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PostDeptEditBody {
  enterId?: number
  id?: number
  leaderPersonId?: number
  name?: string
  parentId?: number
  phone?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptEditResponse = boolean

/* **请求函数** */
export async function postDeptEdit(body: PostDeptEditBody): Promise<any> {
  return request(`/dept/edit`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [编辑人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/1273)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `PUT /dept/edit/person`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PutDeptEditPersonBody {
  deptIdList?: number[]
  email?: string
  enterId?: number
  faceUrl?: string
  id?: number
  job?: string
  mainDeptId?: number
  name?: string
  phone?: string
  sex?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  workNo?: string
}

/* **返回类型** */
export type PutDeptEditPersonResponse = boolean

/* **请求函数** */
export async function putDeptEditPerson(body: PutDeptEditPersonBody): Promise<any> {
  return request(`/dept/edit/person`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询人员详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1275)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/detail/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface GetDeptPersonDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptPersonDetailByIdResponse {
  deptIdList: number[]
  deptName: string
  email: string
  faceUrl: string
  id: number
  job: string
  mainDeptId: number
  mainDeptName: string
  name: string
  phone: string
  sex: string
  workNo: string
}

/* **请求函数** */
export async function getDeptPersonDetailById(params: GetDeptPersonDetailByIdParams): Promise<any> {
  return request(`/dept/person/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [人员列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/1277)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/list`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptPersonListResponse = {
  aiotId: string
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}[]

/* **请求函数** */
export async function getDeptPersonList(): Promise<any> {
  return request(`/dept/person/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/1279)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/page`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptPersonPageQuery {
  /**
   * deptId
   */
  deptId?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptPersonPageResponse {
  data: {
    /**
     * 考勤
     */
    attendanceName: string
    /**
     * 所属部门
     */
    deptName: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 人脸照
     */
    faceUrl: string
    id: number
    /**
     * 岗位
     */
    job: string
    name: string
    /**
     * 手机号
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    /**
     * 工号
     */
    workNo: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptPersonPage(query: GetDeptPersonPageQuery): Promise<any> {
  return request(`/dept/person/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询部门列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/1281)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/query`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptQueryResponse = {
  id: number
  key: string
  menuVOList: {}[]
  name: string
  parentId: number
  personCount: number
  personPOList: {
    aiotId: string
    bizData: string
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
  }[]
}[]

/* **请求函数** */
export async function getDeptQuery(): Promise<any> {
  return request(`/dept/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询部门详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1283)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_94)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/query/detail/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface GetDeptQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptQueryDetailByIdResponse {
  id: number
  /**
   * 部门负责人
   */
  leaderPersonId: number
  /**
   * 部门负责人名称
   */
  leaderPersonIdName: string
  name: string
  /**
   * 上级部门ID,没有则默认为-1
   */
  parentId: number
  /**
   * 父级菜单名称
   */
  parentName: string
  personCount: number
  /**
   * 联系电话
   */
  phone: string
}

/* **请求函数** */
export async function getDeptQueryDetailById(params: GetDeptQueryDetailByIdParams): Promise<any> {
  return request(`/dept/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [添加考勤记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/1329)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `POST /dept/attendance/add`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PostDeptAttendanceAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
}

/* **返回类型** */
export type PostDeptAttendanceAddResponse = boolean

/* **请求函数** */
export async function postDeptAttendanceAdd(body: PostDeptAttendanceAddBody): Promise<any> {
  return request(`/dept/attendance/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [每日考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/1331)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/day`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendanceDayQuery {
  /**
   * dayDate
   */
  dayDate: string
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptAttendanceDayResponse {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  enterAttendanceDayDetailVOList: {
    afterStatus: number
    afternoonDate: string
    dayDate: string
    deptName: string
    id: number
    jobName: string
    moonDate: string
    moonStatus: number
    name: string
    roomAmountRemark: string
    teamName: string
    totalHour: number
    totalTimeAmount: number
    userId: number
  }[]
  missAmount: number
  repairAmount: number
}

/* **请求函数** */
export async function getDeptAttendanceDay(query: GetDeptAttendanceDayQuery): Promise<any> {
  return request(`/dept/attendance/day`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [修改日志↗](http://fe.jiuworker.com:3000/project/25/interface/api/1333)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/day/log/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface GetDeptAttendanceDayLogByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetDeptAttendanceDayLogByIdResponse = {
  deptName: string
  id: number
  jobName: string
  name: string
  oldData: string
  remark: string
  repairData: string
}[]

/* **请求函数** */
export async function getDeptAttendanceDayLogById(params: GetDeptAttendanceDayLogByIdParams): Promise<any> {
  return request(`/dept/attendance/day/log/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/1335)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/month`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendanceMonthQuery {
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptAttendanceMonthResponse {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  enterAttendancePlanMonthDetailVOList: {
    abstainedAmount: number
    backAmount: number
    delayAmount: number
    deptName: string
    jobName: string
    missAmount: number
    name: string
    personId: number
    shouldDay: number
    shouldHour: number
    totalDay: number
    totalHour: number
  }[]
  missAmount: number
}

/* **请求函数** */
export async function getDeptAttendanceMonth(query: GetDeptAttendanceMonthQuery): Promise<any> {
  return request(`/dept/attendance/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工考勤详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1337)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/person/detail`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendancePersonDetailQuery {
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetDeptAttendancePersonDetailResponse {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  deptName: string
  enterAttendanceDayDetailVOList: {
    afterStatus: number
    afternoonDate: string
    dayDate: string
    deptName: string
    id: number
    jobName: string
    moonDate: string
    moonStatus: number
    name: string
    roomAmountRemark: string
    teamName: string
    totalHour: number
    totalTimeAmount: number
    userId: number
  }[]
  faceUrl: string
  jobName: string
  missAmount: number
  name: string
  repairAmount: number
  shouldDay: number
  shouldHour: number
  totalDay: number
  totalHour: number
}

/* **请求函数** */
export async function getDeptAttendancePersonDetail(query: GetDeptAttendancePersonDetailQuery): Promise<any> {
  return request(`/dept/attendance/person/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询补卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/1339)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/repair`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendanceRepairQuery {
  /**
   * dayDate
   */
  dayDate?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * personId
   */
  personId?: string
}

/* **返回类型** */
export interface GetDeptAttendanceRepairResponse {
  data: {
    deptName: string
    id: number
    jobName: string
    name: string
    oldData: string
    remark: string
    repairData: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptAttendanceRepair(query: GetDeptAttendanceRepairQuery): Promise<any> {
  return request(`/dept/attendance/repair`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [主动新增考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/1343)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `PUT /dept/attendance/person/add`
 * @更新时间 `2023-12-04 17:00:04`
 */

/* **请求query类型** */
export interface PutDeptAttendancePersonAddQuery {
  /**
   * attendanceDate
   */
  attendanceDate: string
  /**
   * deviceId
   */
  deviceId: string
  /**
   * personId
   */
  personId: string
}

/* **返回类型** */
export type PutDeptAttendancePersonAddResponse = boolean

/* **请求函数** */
export async function putDeptAttendancePersonAdd(query: PutDeptAttendancePersonAddQuery): Promise<any> {
  return request(`/dept/attendance/person/add`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [调用定时任务↗](http://fe.jiuworker.com:3000/project/25/interface/api/1345)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_95)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/hand`
 * @更新时间 `2023-12-04 17:00:04`
 */

/* **返回类型** */
export type GetDeptAttendanceHandResponse = boolean

/* **请求函数** */
export async function getDeptAttendanceHand(): Promise<any> {
  return request(`/dept/attendance/hand`, {
    method: Method.GET,
  })
}

/**
 * 接口 [职工考勤统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/1347)
 *
 * @分类 [职工部门模块/app端↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_96)
 * @标签 `职工部门模块/app端`
 * @请求头 `GET /dept/attendance/app/query`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendanceAppQueryQuery {
  /**
   * deptId
   */
  deptId?: string
  /**
   * queryDate
   */
  queryDate: string
}

/* **返回类型** */
export interface GetDeptAttendanceAppQueryResponse {
  abnormalAmount: number
  abnormalAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  attendanceAmount: number
  hadAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  notAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  /**
   * 用工计划名称
   */
  planName: string
  totalHour: number
  totalPeople: number
}

/* **请求函数** */
export async function getDeptAttendanceAppQuery(query: GetDeptAttendanceAppQueryQuery): Promise<any> {
  return request(`/dept/attendance/app/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取个人考勤详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1349)
 *
 * @分类 [职工部门模块/app端↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_96)
 * @标签 `职工部门模块/app端`
 * @请求头 `GET /dept/attendance/app/user`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptAttendanceAppUserQuery {
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetDeptAttendanceAppUserResponse {
  /**
   * 人员月度考勤详情
   */
  attendanceUserMonthVOList: {
    /**
     * 是否异常：true 是；false 否
     */
    abnormal: boolean
    /**
     * 下班打卡时间
     */
    afterDate: string
    /**
     * 下班打卡状态
     */
    afterStatus: number
    /**
     * 打卡日期：yyyy-MM-dd
     */
    dayDate: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 上班打卡时间
     */
    moonDate: string
    /**
     * 上班打卡状态
     */
    moonStatus: number
    /**
     * 工作工时
     */
    workHour: number
  }[]
  /**
   * 部门
   */
  deptName: string
  /**
   * 人脸
   */
  faceUrl: string
  name: string
  /**
   * 异常
   */
  totalAbnormal: number
  /**
   * 累计天数
   */
  totalDays: number
  /**
   * 累计工时
   */
  totalHour: number
  /**
   * 计件
   */
  totalItem: number
}

/* **请求函数** */
export async function getDeptAttendanceAppUser(query: GetDeptAttendanceAppUserQuery): Promise<any> {
  return request(`/dept/attendance/app/user`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询日志↗](http://fe.jiuworker.com:3000/project/25/interface/api/1595)
 *
 * @分类 [操作日志管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_110)
 * @标签 `操作日志管理`
 * @请求头 `GET /log/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetLogQueryQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * logType
   */
  logType?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
}

/* **返回类型** */
export interface GetLogQueryResponse {
  data: {
    enterName: string
    ip: string
    logContent: string
    logTime: string
    logType: string
    requestContent: string
    systemName: string
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getLogQuery(query: GetLogQueryQuery): Promise<any> {
  return request(`/log/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询日志类型↗](http://fe.jiuworker.com:3000/project/25/interface/api/1597)
 *
 * @分类 [操作日志管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_110)
 * @标签 `操作日志管理`
 * @请求头 `GET /log/type`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export type GetLogTypeResponse = {
  key: string
  value: string
}[]

/* **请求函数** */
export async function getLogType(): Promise<any> {
  return request(`/log/type`, {
    method: Method.GET,
  })
}

/**
 * 接口 [同步数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/1975)
 *
 * @分类 [城市↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_132)
 * @标签 `城市`
 * @请求头 `GET /out/city`
 * @更新时间 `2024-01-30 10:12:06`
 */

/* **请求query类型** */
export interface GetOutCityQuery {
  /**
   * pageNum
   */
  pageNum: number
  /**
   * provinceId
   */
  provinceId: string
  /**
   * token
   */
  token: string
}

/* **返回类型** */
export type GetOutCityResponse = boolean

/* **请求函数** */
export async function getOutCity(query: GetOutCityQuery): Promise<any> {
  return request(`/out/city`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [新增↗](http://fe.jiuworker.com:3000/project/25/interface/api/1977)
 *
 * @分类 [城市↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_132)
 * @标签 `城市`
 * @请求头 `POST /out/city`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostOutCityBody {
  enterAddCityDetailDTOList?: {
    children?: {}[]
    code?: string
    name?: string
  }[]
}

/* **返回类型** */
export type PostOutCityResponse = boolean

/* **请求函数** */
export async function postOutCity(body: PostOutCityBody): Promise<any> {
  return request(`/out/city`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [同步数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/1979)
 *
 * @分类 [城市↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_132)
 * @标签 `城市`
 * @请求头 `GET /out/city/test`
 * @更新时间 `2024-01-30 10:12:06`
 */

/* **返回类型** */
export type GetOutCityTestResponse = boolean

/* **请求函数** */
export async function getOutCityTest(): Promise<any> {
  return request(`/out/city/test`, {
    method: Method.GET,
  })
}

/**
 * 接口 [task1↗](http://fe.jiuworker.com:3000/project/25/interface/api/461)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/task1`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutTask1Response = boolean

/* **请求函数** */
export async function getOutTask1(): Promise<any> {
  return request(`/out/task1`, {
    method: Method.GET,
  })
}

/**
 * 接口 [task2↗](http://fe.jiuworker.com:3000/project/25/interface/api/463)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/task2`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutTask2Response = boolean

/* **请求函数** */
export async function getOutTask2(): Promise<any> {
  return request(`/out/task2`, {
    method: Method.GET,
  })
}

/**
 * 接口 [task3↗](http://fe.jiuworker.com:3000/project/25/interface/api/465)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/task3`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutTask3Response = boolean

/* **请求函数** */
export async function getOutTask3(): Promise<any> {
  return request(`/out/task3`, {
    method: Method.GET,
  })
}

/**
 * 接口 [task4↗](http://fe.jiuworker.com:3000/project/25/interface/api/2349)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/task4`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutTask4Response = boolean

/* **请求函数** */
export async function getOutTask4(): Promise<any> {
  return request(`/out/task4`, {
    method: Method.GET,
  })
}

/**
 * 接口 [addHoliday↗](http://fe.jiuworker.com:3000/project/25/interface/api/2361)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/add/holiday`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetOutAddHolidayQuery {
  /**
   * date
   */
  date: string
}

/* **返回类型** */
export type GetOutAddHolidayResponse = boolean

/* **请求函数** */
export async function getOutAddHoliday(query: GetOutAddHolidayQuery): Promise<any> {
  return request(`/out/add/holiday`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [deleteHoliday↗](http://fe.jiuworker.com:3000/project/25/interface/api/2363)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/delete/holiday`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutDeleteHolidayQuery {
  /**
   * date
   */
  date: string
}

/* **返回类型** */
export type GetOutDeleteHolidayResponse = boolean

/* **请求函数** */
export async function getOutDeleteHoliday(query: GetOutDeleteHolidayQuery): Promise<any> {
  return request(`/out/delete/holiday`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [holiday↗](http://fe.jiuworker.com:3000/project/25/interface/api/2365)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/holiday`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **返回类型** */
export type GetOutHolidayResponse = string[]

/* **请求函数** */
export async function getOutHoliday(): Promise<any> {
  return request(`/out/holiday`, {
    method: Method.GET,
  })
}

/**
 * 接口 [修改权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/2469)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/edit/cache`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutEditCacheQuery {
  /**
   * id
   */
  id: string
  /**
   * parentId
   */
  parentId?: string
}

/* **返回类型** */
export type GetOutEditCacheResponse = boolean

/* **请求函数** */
export async function getOutEditCache(query: GetOutEditCacheQuery): Promise<any> {
  return request(`/out/edit/cache`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/2471)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/save/cache`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetOutSaveCacheQuery {
  /**
   * id
   */
  id: string
  /**
   * parentId
   */
  parentId?: string
  /**
   * powerName
   */
  powerName: string
}

/* **返回类型** */
export type GetOutSaveCacheResponse = boolean

/* **请求函数** */
export async function getOutSaveCache(query: GetOutSaveCacheQuery): Promise<any> {
  return request(`/out/save/cache`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [task5↗](http://fe.jiuworker.com:3000/project/25/interface/api/2647)
 *
 * @分类 [enter-task-controller↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_156)
 * @标签 `enter-task-controller`
 * @请求头 `GET /out/task5`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutTask5Response = boolean

/* **请求函数** */
export async function getOutTask5(): Promise<any> {
  return request(`/out/task5`, {
    method: Method.GET,
  })
}

/**
 * 接口 [新增保洁↗](http://fe.jiuworker.com:3000/project/25/interface/api/2445)
 *
 * @分类 [保洁管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_161)
 * @标签 `保洁管理`
 * @请求头 `POST /clean/add`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostCleanAddBody {
  /**
   * 身份证号
   */
  cardNo?: string
  faceUrl?: string
  /**
   * 底薪
   */
  leastMoney?: number
  /**
   * 每日保底
   */
  leastRoomAmount?: number
  /**
   * 超额
   */
  moreRoomMoney?: number
  /**
   * 姓名
   */
  personName?: string
  /**
   * 电话
   */
  phone?: string
  /**
   * 休息
   */
  relaxDay?: number
}

/* **返回类型** */
export type PostCleanAddResponse = boolean

/* **请求函数** */
export async function postCleanAdd(body: PostCleanAddBody): Promise<any> {
  return request(`/clean/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [权限菜单列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/2465)
 *
 * @分类 [客房派单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_162)
 * @标签 `客房派单权限`
 * @请求头 `GET /room/power/list`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export type GetRoomPowerListResponse = {
  childRoomList: {}[]
  id: number
  parentId: number
  powerName: string
}[]

/* **请求函数** */
export async function getRoomPowerList(): Promise<any> {
  return request(`/room/power/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [添加权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/2473)
 *
 * @分类 [客房派单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_162)
 * @标签 `客房派单权限`
 * @请求头 `POST /room/power/add`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomPowerAddBody {
  powerIdList?: number[]
  userId?: number
}

/* **返回类型** */
export type PostRoomPowerAddResponse = boolean

/* **请求函数** */
export async function postRoomPowerAdd(body: PostRoomPowerAddBody): Promise<any> {
  return request(`/room/power/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [查询人员权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/2475)
 *
 * @分类 [客房派单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_162)
 * @标签 `客房派单权限`
 * @请求头 `GET /room/power/info`
 * @更新时间 `2024-05-31 15:58:09`
 */

/* **请求query类型** */
export interface GetRoomPowerInfoQuery {
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetRoomPowerInfoResponse {
  enterRoomPowerVOList: {
    childRoomList: {}[]
    id: number
    parentId: number
    powerName: string
  }[]
  id: number
  phone: string
  userName: string
}

/* **请求函数** */
export async function getRoomPowerInfo(query: GetRoomPowerInfoQuery): Promise<any> {
  return request(`/room/power/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取登陆人权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/2505)
 *
 * @分类 [客房派单权限↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_162)
 * @标签 `客房派单权限`
 * @请求头 `GET /room/power/my`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export type GetRoomPowerMyResponse = number[]

/* **请求函数** */
export async function getRoomPowerMy(): Promise<any> {
  return request(`/room/power/my`, {
    method: Method.GET,
  })
}

/**
 * 接口 [创建区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2735)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `POST /room/region/add`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomRegionAddBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
}

/* **返回类型** */
export type PostRoomRegionAddResponse = boolean

/* **请求函数** */
export async function postRoomRegionAdd(body: PostRoomRegionAddBody): Promise<any> {
  return request(`/room/region/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [创建楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2737)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `POST /room/region/add/floor`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomRegionAddFloorBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
  /**
   * 区域Id
   */
  regionId?: number
}

/* **返回类型** */
export type PostRoomRegionAddFloorResponse = boolean

/* **请求函数** */
export async function postRoomRegionAddFloor(body: PostRoomRegionAddFloorBody): Promise<any> {
  return request(`/room/region/add/floor`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2739)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `DELETE /room/region/delete/floor/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface DeleteRoomRegionDeleteFloorByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteRoomRegionDeleteFloorByIdResponse = boolean

/* **请求函数** */
export async function deleteRoomRegionDeleteFloorById(params: DeleteRoomRegionDeleteFloorByIdParams): Promise<any> {
  return request(`/room/region/delete/floor/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [删除区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2741)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `DELETE /room/region/delete/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface DeleteRoomRegionDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteRoomRegionDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteRoomRegionDeleteById(params: DeleteRoomRegionDeleteByIdParams): Promise<any> {
  return request(`/room/region/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2743)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `PUT /room/region/edit`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PutRoomRegionEditBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
}

/* **返回类型** */
export type PutRoomRegionEditResponse = boolean

/* **请求函数** */
export async function putRoomRegionEdit(body: PutRoomRegionEditBody): Promise<any> {
  return request(`/room/region/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [编辑楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2745)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `PUT /room/region/edit/floor`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PutRoomRegionEditFloorBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
  /**
   * 区域Id
   */
  regionId?: number
}

/* **返回类型** */
export type PutRoomRegionEditFloorResponse = boolean

/* **请求函数** */
export async function putRoomRegionEditFloor(body: PutRoomRegionEditFloorBody): Promise<any> {
  return request(`/room/region/edit/floor`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2747)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `GET /room/region/query`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export type GetRoomRegionQueryResponse = {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefault: number
  name: string
}[]

/* **请求函数** */
export async function getRoomRegionQuery(): Promise<any> {
  return request(`/room/region/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询区域和楼层(结合一起)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2749)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `GET /room/region/query/all`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export type GetRoomRegionQueryAllResponse = {
  id: number
  isDefault: number
  name: string
  regionId: number
  roomFloorPOList: {
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    isDefault: number
    name: string
    regionId: number
  }[]
}[]

/* **请求函数** */
export async function getRoomRegionQueryAll(): Promise<any> {
  return request(`/room/region/query/all`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2751)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `GET /room/region/query/floor`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomRegionQueryFloorQuery {
  /**
   * regionId
   */
  regionId: string
}

/* **返回类型** */
export type GetRoomRegionQueryFloorResponse = {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefault: number
  name: string
  regionId: number
}[]

/* **请求函数** */
export async function getRoomRegionQueryFloor(query: GetRoomRegionQueryFloorQuery): Promise<any> {
  return request(`/room/region/query/floor`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房间查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2753)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `GET /room/region/query/room`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomRegionQueryRoomQuery {
  /**
   * floorId
   */
  floorId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export interface GetRoomRegionQueryRoomResponse {
  data: {
    enterId: number
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getRoomRegionQueryRoom(query: GetRoomRegionQueryRoomQuery): Promise<any> {
  return request(`/room/region/query/room`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2755)
 *
 * @分类 [房间区域和楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_171)
 * @标签 `房间区域和楼层`
 * @请求头 `GET /room/region/query/status`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomRegionQueryStatusQuery {
  /**
   * floorId
   */
  floorId?: string
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export type GetRoomRegionQueryStatusResponse = {
  enterId: number
  enterRoomPersonVOList: {
    attendanceId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    planId: number
    roomId: number
    roomType: string
    userId: number
    userName: string
  }[]
  floorId: number
  gmtCreate: string
  gmtModified: string
  id: number
  regionId: number
  roomName: string
  roomType: string
  status: number
}[]

/* **请求函数** */
export async function getRoomRegionQueryStatus(query: GetRoomRegionQueryStatusQuery): Promise<any> {
  return request(`/room/region/query/status`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5167)
 *
 * @分类 [金融服务到账通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_274)
 * @标签 `金融服务到账通知`
 * @请求头 `GET /out/visa/exempt/appHeart`
 * @更新时间 `2024-12-03 18:40:09`
 */

/* **请求query类型** */
export interface GetOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface GetOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getOutVisaExemptAppHeart(query: GetOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5181)
 *
 * @分类 [金融服务到账通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_274)
 * @标签 `金融服务到账通知`
 * @请求头 `GET /out/visa/exempt/appPush`
 * @更新时间 `2024-12-03 18:40:09`
 */

/* **请求query类型** */
export interface GetOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface GetOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getOutVisaExemptAppPush(query: GetOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [账号推送↗](http://fe.jiuworker.com:3000/project/25/interface/api/433)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/account`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostOutAccountBody {
  defaultPwd?: string
  enterName?: string
  experienceAccount?: boolean
  id?: number
  name?: string
  otherInfo?: string
  password?: string
  phone?: string
  powerIdList?: number[]
  roleId?: number
}

/* **返回类型** */
export interface PostOutAccountResponse {
  /**
   * 公司id
   */
  companyId: number
  /**
   * 管理员默认密码
   */
  defaultPassword: string
}

/* **请求函数** */
export async function postOutAccount(body: PostOutAccountBody): Promise<any> {
  return request(`/out/account`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [劳务确认考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/435)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/attendance/confirm/{planId}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetOutAttendanceConfirmByPlanIdQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **请求params类型** */
export interface GetOutAttendanceConfirmByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetOutAttendanceConfirmByPlanIdResponse = boolean

/* **请求函数** */
export async function getOutAttendanceConfirmByPlanId(
  query: GetOutAttendanceConfirmByPlanIdQuery,
  params: GetOutAttendanceConfirmByPlanIdParams,
): Promise<any> {
  return request(`/out/attendance/confirm/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取黑名单列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/437)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/black/list`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export type GetOutBlackListResponse = {
  aiotGuid: string
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  idCard: string
  name: string
  personType: number
  phone: string
  status: number
}[]

/* **请求函数** */
export async function getOutBlackList(): Promise<any> {
  return request(`/out/black/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [设备数据回掉↗](http://fe.jiuworker.com:3000/project/25/interface/api/439)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/callback`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostOutCallbackQuery {
  /**
   * eventCode
   */
  eventCode?: string
  /**
   * eventGuid
   */
  eventGuid?: string
  /**
   * eventMsg
   */
  eventMsg?: string
}

/* **返回类型** */
export type PostOutCallbackResponse = boolean

/* **请求函数** */
export async function postOutCallback(query: PostOutCallbackQuery): Promise<any> {
  return request(`/out/callback`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [酒店取消用工计划公众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/441)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/cancel/message`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetOutCancelMessageQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId?: string
  /**
   * planId
   */
  planId: string
  /**
   * 多个逗号隔开
   */
  unionIds?: string
}

/* **返回类型** */
export type GetOutCancelMessageResponse = boolean

/* **请求函数** */
export async function getOutCancelMessage(query: GetOutCancelMessageQuery): Promise<any> {
  return request(`/out/cancel/message`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [酒店新订单公众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/443)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/confirm/message`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutConfirmMessageQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId?: string
  /**
   * planId
   */
  planId: string
  /**
   * 多个逗号隔开
   */
  unionIds?: string
}

/* **返回类型** */
export type GetOutConfirmMessageResponse = boolean

/* **请求函数** */
export async function getOutConfirmMessage(query: GetOutConfirmMessageQuery): Promise<any> {
  return request(`/out/confirm/message`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [主动删除缓存↗](http://fe.jiuworker.com:3000/project/25/interface/api/445)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/delete/cache`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface PostOutDeleteCacheQuery {
  /**
   * code
   */
  code: string
}

/* **返回类型** */
export type PostOutDeleteCacheResponse = boolean

/* **请求函数** */
export async function postOutDeleteCache(query: PostOutDeleteCacheQuery): Promise<any> {
  return request(`/out/delete/cache`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [企业新增↗](http://fe.jiuworker.com:3000/project/25/interface/api/447)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/enter`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutEnterBody {
  address?: string
  businessLicense?: string
  city?: string
  /**
   * 10：标准版;20：客房派单
   */
  companyType?: number
  contractPerson?: string
  contractPhone?: string
  country?: string
  /**
   * 当前余额
   */
  currentCommission?: number
  email?: string
  /**
   * 消费记录
   */
  enterCommissionDTOList?: {
    commission?: string
    createDate?: string
    remark?: string
  }[]
  externalLogonId?: string
  financeAccountId?: number
  financeAccountName?: string
  /**
   * 已使用余额
   */
  hadUsedCommission?: number
  isCanSendToLabor?: boolean
  latitude?: string
  longitude?: string
  name?: string
  password?: string
  /**
   * 0:平台定价；1自己定价
   */
  priceType?: number
  province?: string
  /**
   * 客房数量
   */
  roomAmount?: number
  roomMoney?: number
  roomMoneyDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomSelfDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  roomStatus?: boolean
  sign?: boolean
}

/* **返回类型** */
export interface PostOutEnterResponse {
  /**
   * 公司id
   */
  companyId: number
  /**
   * 管理员默认密码
   */
  defaultPassword: string
}

/* **请求函数** */
export async function postOutEnter(body: PostOutEnterBody): Promise<any> {
  return request(`/out/enter`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [获取企业基本信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/449)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/enter/info/{enterId}`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求params类型** */
export interface GetOutEnterInfoByEnterIdParams {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export interface GetOutEnterInfoByEnterIdResponse {
  bizData: string
  city: string
  country: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  /**
   * 0:平台定价；1自己定价
   */
  priceType: number
  province: string
  status: number
}

/* **请求函数** */
export async function getOutEnterInfoByEnterId(params: GetOutEnterInfoByEnterIdParams): Promise<any> {
  return request(`/out/enter/info/${params.enterId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [接受或拒绝酒店端的劳务添加邀请↗](http://fe.jiuworker.com:3000/project/25/interface/api/451)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/labor`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutLaborQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId: string
  /**
   * remark
   */
  remark: string
  /**
   * 10:通过；20拒绝
   */
  status?: string
}

/* **返回类型** */
export type GetOutLaborResponse = boolean

/* **请求函数** */
export async function getOutLabor(query: GetOutLaborQuery): Promise<any> {
  return request(`/out/labor`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [更新劳务公司签约账户↗](http://fe.jiuworker.com:3000/project/25/interface/api/453)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/labor/info/{laborId}`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutLaborInfoByLaborIdQuery {
  /**
   * financeAccountId
   */
  financeAccountId?: string
  /**
   * operationType
   */
  operationType?: string
}

/* **请求params类型** */
export interface GetOutLaborInfoByLaborIdParams {
  /**
   * laborId
   */
  laborId: string
}

/* **返回类型** */
export type GetOutLaborInfoByLaborIdResponse = boolean

/* **请求函数** */
export async function getOutLaborInfoByLaborId(
  query: GetOutLaborInfoByLaborIdQuery,
  params: GetOutLaborInfoByLaborIdParams,
): Promise<any> {
  return request(`/out/labor/info/${params.laborId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [工人录用上报↗](http://fe.jiuworker.com:3000/project/25/interface/api/455)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/labor/user`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutLaborUserBody {
  blackRemark?: string
  /**
   * 所属酒店ID
   */
  enterId?: number
  /**
   * 人脸照片
   */
  faceUrl?: string
  /**
   * 是否激活
   */
  hadActivity?: boolean
  /**
   * 身份证
   */
  idCard?: string
  /**
   * 岗位名称
   */
  jobName?: string
  /**
   * 劳务工地ID
   */
  laborId?: number
  lastStatus?: number
  leastMoney?: number
  /**
   * 每日保底
   */
  leastRoomAmount?: number
  /**
   * 超额
   */
  moreRoomMoney?: number
  /**
   * 姓名
   */
  name?: string
  /**
   * 电话
   */
  phone?: string
  /**
   * 用工计划ID
   */
  planId?: number
  /**
   * 休息
   */
  relaxDay?: number
  /**
   * 性别
   */
  sex?: string
}

/* **返回类型** */
export type PostOutLaborUserResponse = boolean

/* **请求函数** */
export async function postOutLaborUser(body: PostOutLaborUserBody): Promise<any> {
  return request(`/out/labor/user`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [人员离职↗](http://fe.jiuworker.com:3000/project/25/interface/api/457)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/leave/job`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutLeaveJobQuery {
  /**
   * cardNo
   */
  cardNo: string
  /**
   * enterId
   */
  enterId: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetOutLeaveJobResponse = boolean

/* **请求函数** */
export async function getOutLeaveJob(query: GetOutLeaveJobQuery): Promise<any> {
  return request(`/out/leave/job`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [remove↗](http://fe.jiuworker.com:3000/project/25/interface/api/459)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/remove`
 * @更新时间 `2023-11-23 10:14:06`
 */

/* **请求query类型** */
export interface GetOutRemoveQuery {
  /**
   * guid
   */
  guid: string
}

/* **返回类型** */
export type GetOutRemoveResponse = boolean

/* **请求函数** */
export async function getOutRemove(query: GetOutRemoveQuery): Promise<any> {
  return request(`/out/remove`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [we↗](http://fe.jiuworker.com:3000/project/25/interface/api/467)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/we`
 * @更新时间 `2023-11-23 10:14:06`
 */

/* **返回类型** */
export type GetOutWeResponse = boolean

/* **请求函数** */
export async function getOutWe(): Promise<any> {
  return request(`/out/we`, {
    method: Method.GET,
  })
}

/**
 * 接口 [we1↗](http://fe.jiuworker.com:3000/project/25/interface/api/469)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/we1`
 * @更新时间 `2023-11-23 10:14:06`
 */

/* **返回类型** */
export type GetOutWe1Response = boolean

/* **请求函数** */
export async function getOutWe1(): Promise<any> {
  return request(`/out/we1`, {
    method: Method.GET,
  })
}

/**
 * 接口 [接单中审批用工计划取消↗](http://fe.jiuworker.com:3000/project/25/interface/api/471)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/work/cancel/plan`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkCancelPlanQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId: string
  /**
   * planId
   */
  planId: string
  /**
   * 10:确认取消；20拒绝取消
   */
  status?: string
}

/* **返回类型** */
export type GetOutWorkCancelPlanResponse = boolean

/* **请求函数** */
export async function getOutWorkCancelPlan(query: GetOutWorkCancelPlanQuery): Promise<any> {
  return request(`/out/work/cancel/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [劳务端主动申请取消用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/473)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/work/out/cancel/plan`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkOutCancelPlanQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId: string
  /**
   * planId
   */
  planId: string
  /**
   * remark
   */
  remark?: string
}

/* **返回类型** */
export type GetOutWorkOutCancelPlanResponse = boolean

/* **请求函数** */
export async function getOutWorkOutCancelPlan(query: GetOutWorkOutCancelPlanQuery): Promise<any> {
  return request(`/out/work/out/cancel/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划确认↗](http://fe.jiuworker.com:3000/project/25/interface/api/475)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/work/plan`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkPlanQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId: string
  /**
   * planId
   */
  planId: string
  /**
   * 10:确认；20拒绝
   */
  status?: string
}

/* **返回类型** */
export type GetOutWorkPlanResponse = boolean

/* **请求函数** */
export async function getOutWorkPlan(query: GetOutWorkPlanQuery): Promise<any> {
  return request(`/out/work/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [微信消息推送↗](http://fe.jiuworker.com:3000/project/25/interface/api/1377)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/we/account`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type PostOutWeAccountResponse = string

/* **请求函数** */
export async function postOutWeAccount(): Promise<any> {
  return request(`/out/we/account`, {
    method: Method.POST,
  })
}

/**
 * 接口 [微信消息推送↗](http://fe.jiuworker.com:3000/project/25/interface/api/1379)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/we/account`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **返回类型** */
export type GetOutWeAccountResponse = string

/* **请求函数** */
export async function getOutWeAccount(): Promise<any> {
  return request(`/out/we/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [报名人脸确认↗](http://fe.jiuworker.com:3000/project/25/interface/api/1383)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/labor/user/check`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutLaborUserCheckBody {
  blackRemark?: string
  /**
   * 所属酒店ID
   */
  enterId?: number
  /**
   * 人脸照片
   */
  faceUrl?: string
  /**
   * 是否激活
   */
  hadActivity?: boolean
  /**
   * 身份证
   */
  idCard?: string
  /**
   * 岗位名称
   */
  jobName?: string
  /**
   * 劳务工地ID
   */
  laborId?: number
  lastStatus?: number
  leastMoney?: number
  /**
   * 每日保底
   */
  leastRoomAmount?: number
  /**
   * 超额
   */
  moreRoomMoney?: number
  /**
   * 姓名
   */
  name?: string
  /**
   * 电话
   */
  phone?: string
  /**
   * 用工计划ID
   */
  planId?: number
  /**
   * 休息
   */
  relaxDay?: number
  /**
   * 性别
   */
  sex?: string
}

/* **返回类型** */
export type PostOutLaborUserCheckResponse = boolean

/* **请求函数** */
export async function postOutLaborUserCheck(body: PostOutLaborUserCheckBody): Promise<any> {
  return request(`/out/labor/user/check`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [劳务确认考勤\/结算众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/1385)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/confirm/attendance`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetOutConfirmAttendanceQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId?: string
  /**
   * 10:考勤确认 20已结算；30劳务申请
   */
  messageType?: string
  /**
   * 如果是结算的：结算金额
   */
  money?: string
  /**
   * planId
   */
  planId: string
  /**
   * 多个逗号隔开
   */
  unionIds?: string
}

/* **返回类型** */
export type GetOutConfirmAttendanceResponse = boolean

/* **请求函数** */
export async function getOutConfirmAttendance(query: GetOutConfirmAttendanceQuery): Promise<any> {
  return request(`/out/confirm/attendance`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [劳务合作众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/1409)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/labor/message`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutLaborMessageQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * laborId
   */
  laborId?: string
  /**
   * 多个逗号隔开
   */
  unionIds?: string
}

/* **返回类型** */
export type GetOutLaborMessageResponse = boolean

/* **请求函数** */
export async function getOutLaborMessage(query: GetOutLaborMessageQuery): Promise<any> {
  return request(`/out/labor/message`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [处理数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/1431)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/hand/data`
 * @更新时间 `2023-12-04 17:00:06`
 */

/* **返回类型** */
export type GetOutHandDataResponse = boolean

/* **请求函数** */
export async function getOutHandData(): Promise<any> {
  return request(`/out/hand/data`, {
    method: Method.GET,
  })
}

/**
 * 接口 [新增体验账号↗](http://fe.jiuworker.com:3000/project/25/interface/api/2091)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/enter/experience`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutEnterExperienceBody {
  name?: string
  phone?: string
}

/* **返回类型** */
export interface PostOutEnterExperienceResponse {
  accessToken: string
  passWork: string
}

/* **请求函数** */
export async function postOutEnterExperience(body: PostOutEnterExperienceBody): Promise<any> {
  return request(`/out/enter/experience`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [酒店房间改变公众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/2163)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/room/change/message`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomChangeMessageQuery {
  /**
   * laborId
   */
  laborId: string
  /**
   * planId
   */
  planId: string
  /**
   * 多个逗号隔开
   */
  unionIds?: string
}

/* **返回类型** */
export type GetOutRoomChangeMessageResponse = boolean

/* **请求函数** */
export async function getOutRoomChangeMessage(query: GetOutRoomChangeMessageQuery): Promise<any> {
  return request(`/out/room/change/message`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用户打卡公众号消息通知↗](http://fe.jiuworker.com:3000/project/25/interface/api/2519)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `GET /out/user/attendance`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetOutUserAttendanceQuery {
  /**
   * attendanceTime
   */
  attendanceTime: string
  /**
   * deviceName
   */
  deviceName: string
  /**
   * unionId
   */
  unionId: string
  /**
   * userName
   */
  userName: string
}

/* **返回类型** */
export type GetOutUserAttendanceResponse = boolean

/* **请求函数** */
export async function getOutUserAttendance(query: GetOutUserAttendanceQuery): Promise<any> {
  return request(`/out/user/attendance`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [支付宝网站支付回调↗](http://fe.jiuworker.com:3000/project/25/interface/api/5161)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/ali/web/callback`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostOutAliWebCallbackQuery {
  /**
   * app_id
   */
  app_id?: string
  /**
   * auth_app_id
   */
  auth_app_id?: string
  /**
   * body
   */
  body?: string
  /**
   * buyer_id
   */
  buyer_id?: string
  /**
   * buyer_pay_amount
   */
  buyer_pay_amount?: string
  /**
   * charset
   */
  charset?: string
  /**
   * fund_bill_list
   */
  fund_bill_list?: string
  /**
   * gmt_create
   */
  gmt_create?: string
  /**
   * gmt_payment
   */
  gmt_payment?: string
  /**
   * invoice_amount
   */
  invoice_amount?: string
  /**
   * notify_id
   */
  notify_id?: string
  /**
   * notify_time
   */
  notify_time?: string
  /**
   * notify_type
   */
  notify_type?: string
  /**
   * out_biz_no
   */
  out_biz_no?: string
  /**
   * out_trade_no
   */
  out_trade_no?: string
  /**
   * point_amount
   */
  point_amount?: string
  /**
   * receipt_amount
   */
  receipt_amount?: string
  /**
   * seller_id
   */
  seller_id?: string
  /**
   * sign
   */
  sign?: string
  /**
   * sign_type
   */
  sign_type?: string
  /**
   * subject
   */
  subject?: string
  /**
   * total_amount
   */
  total_amount?: string
  /**
   * trade_no
   */
  trade_no?: string
  /**
   * trade_status
   */
  trade_status?: string
  /**
   * version
   */
  version?: string
}

/* **返回类型** */
export type PostOutAliWebCallbackResponse = string

/* **请求函数** */
export async function postOutAliWebCallback(query: PostOutAliWebCallbackQuery): Promise<any> {
  return request(`/out/ali/web/callback`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5169)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `HEAD /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface HeadOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface HeadOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function headOutVisaExemptAppHeart(query: HeadOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.HEAD,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5171)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface PostOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface PostOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function postOutVisaExemptAppHeart(query: PostOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5173)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `PUT /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface PutOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface PutOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function putOutVisaExemptAppHeart(query: PutOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5175)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `DELETE /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface DeleteOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface DeleteOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function deleteOutVisaExemptAppHeart(query: DeleteOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5177)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `OPTIONS /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface OptionsOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface OptionsOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function optionsOutVisaExemptAppHeart(query: OptionsOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.OPTIONS,
    params: query,
  })
}

/**
 * 接口 [appHeart↗](http://fe.jiuworker.com:3000/project/25/interface/api/5179)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `PATCH /out/visa/exempt/appHeart`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface PatchOutVisaExemptAppHeartQuery {
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
}

/* **返回类型** */
export interface PatchOutVisaExemptAppHeartResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function patchOutVisaExemptAppHeart(query: PatchOutVisaExemptAppHeartQuery): Promise<any> {
  return request(`/out/visa/exempt/appHeart`, {
    method: Method.PATCH,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5183)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `HEAD /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface HeadOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface HeadOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function headOutVisaExemptAppPush(query: HeadOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.HEAD,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5185)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `POST /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface PostOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface PostOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function postOutVisaExemptAppPush(query: PostOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5187)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `PUT /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface PutOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface PutOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function putOutVisaExemptAppPush(query: PutOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5189)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `DELETE /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface DeleteOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface DeleteOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function deleteOutVisaExemptAppPush(query: DeleteOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5191)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `OPTIONS /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:12`
 */

/* **请求query类型** */
export interface OptionsOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface OptionsOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function optionsOutVisaExemptAppPush(query: OptionsOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.OPTIONS,
    params: query,
  })
}

/**
 * 接口 [appPush↗](http://fe.jiuworker.com:3000/project/25/interface/api/5193)
 *
 * @分类 [其他服务需要调用的接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_28)
 * @标签 `其他服务需要调用的接口`
 * @请求头 `PATCH /out/visa/exempt/appPush`
 * @更新时间 `2024-11-27 16:22:13`
 */

/* **请求query类型** */
export interface PatchOutVisaExemptAppPushQuery {
  /**
   * price
   */
  price?: string
  /**
   * sign
   */
  sign?: string
  /**
   * t
   */
  t?: string
  /**
   * type
   */
  type?: string
}

/* **返回类型** */
export interface PatchOutVisaExemptAppPushResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function patchOutVisaExemptAppPush(query: PatchOutVisaExemptAppPushQuery): Promise<any> {
  return request(`/out/visa/exempt/appPush`, {
    method: Method.PATCH,
    params: query,
  })
}

/**
 * 接口 [添加劳务公司↗](http://fe.jiuworker.com:3000/project/25/interface/api/409)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `POST /labor/add`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostLaborAddBody {
  address?: string
  contractPerson?: string
  contractPhone?: string
  email?: string
  enterId?: number
  financeAccountId?: number
  id?: number
  laborId?: number
  laborName?: string
  operationType?: number
  remark?: string
}

/* **返回类型** */
export type PostLaborAddResponse = boolean

/* **请求函数** */
export async function postLaborAdd(body: PostLaborAddBody): Promise<any> {
  return request(`/labor/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [劳务邀请二维码↗](http://fe.jiuworker.com:3000/project/25/interface/api/411)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `GET /labor/code`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export type GetLaborCodeResponse = string

/* **请求函数** */
export async function getLaborCode(): Promise<any> {
  return request(`/labor/code`, {
    method: Method.GET,
  })
}

/**
 * 接口 [申请中劳务操作↗](http://fe.jiuworker.com:3000/project/25/interface/api/413)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `PUT /labor/code/status/{id}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PutLaborCodeStatusByIdQuery {
  /**
   * 拒绝原因
   */
  remark?: string
  /**
   * 10:申请通过；20申请拒绝
   */
  status?: string
}

/* **请求params类型** */
export interface PutLaborCodeStatusByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutLaborCodeStatusByIdResponse = boolean

/* **请求函数** */
export async function putLaborCodeStatusById(
  query: PutLaborCodeStatusByIdQuery,
  params: PutLaborCodeStatusByIdParams,
): Promise<any> {
  return request(`/labor/code/status/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [删除劳务公司↗](http://fe.jiuworker.com:3000/project/25/interface/api/415)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `DELETE /labor/delete/{id}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求params类型** */
export interface DeleteLaborDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteLaborDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteLaborDeleteById(params: DeleteLaborDeleteByIdParams): Promise<any> {
  return request(`/labor/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑劳务公司↗](http://fe.jiuworker.com:3000/project/25/interface/api/417)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `PUT /labor/edit`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PutLaborEditBody {
  address?: string
  contractPerson?: string
  contractPhone?: string
  email?: string
  enterId?: number
  financeAccountId?: number
  id?: number
  laborId?: number
  laborName?: string
  operationType?: number
  remark?: string
}

/* **返回类型** */
export type PutLaborEditResponse = boolean

/* **请求函数** */
export async function putLaborEdit(body: PutLaborEditBody): Promise<any> {
  return request(`/labor/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [获取劳务公司下拉框↗](http://fe.jiuworker.com:3000/project/25/interface/api/419)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `GET /labor/labor/list`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetLaborLaborListQuery {
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export interface GetLaborLaborListResponse {}

/* **请求函数** */
export async function getLaborLaborList(query: GetLaborLaborListQuery): Promise<any> {
  return request(`/labor/labor/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [操作↗](http://fe.jiuworker.com:3000/project/25/interface/api/421)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `PUT /labor/operate/{id}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PutLaborOperateByIdQuery {
  /**
   * 10:撤回；20启用；30停用
   */
  status?: string
}

/* **请求params类型** */
export interface PutLaborOperateByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutLaborOperateByIdResponse = boolean

/* **请求函数** */
export async function putLaborOperateById(
  query: PutLaborOperateByIdQuery,
  params: PutLaborOperateByIdParams,
): Promise<any> {
  return request(`/labor/operate/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [劳务公司扫码申请加入↗](http://fe.jiuworker.com:3000/project/25/interface/api/423)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `POST /labor/out/code/add`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostLaborOutCodeAddBody {
  address?: string
  contractPerson?: string
  contractPhone?: string
  email?: string
  enterId?: number
  financeAccountId?: number
  id?: number
  laborId?: number
  laborName?: string
  operationType?: number
  remark?: string
}

/* **返回类型** */
export type PostLaborOutCodeAddResponse = boolean

/* **请求函数** */
export async function postLaborOutCodeAdd(body: PostLaborOutCodeAddBody): Promise<any> {
  return request(`/labor/out/code/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/425)
 *
 * @分类 [劳务公司管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_29)
 * @标签 `劳务公司管理`
 * @请求头 `GET /labor/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetLaborQueryQuery {
  contractPerson?: string
  contractPhone?: string
  laborName?: string
  pageNum?: number
  pageSize?: number
  status?: string
}

/* **返回类型** */
export interface GetLaborQueryResponse {
  data: {
    address: string
    contractPerson: string
    contractPhone: string
    email: string
    financeAccountId: number
    gmtCreate: string
    id: number
    laborId: number
    laborName: string
    remark: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getLaborQuery(query: GetLaborQueryQuery): Promise<any> {
  return request(`/labor/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/227)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `PUT /app/user/black/add`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PutAppUserBlackAddBody {
  /**
   * 备注
   */
  blackRemark?: string
  /**
   * 身份证
   */
  idCard?: string
  /**
   * 姓名
   */
  name?: string
}

/* **返回类型** */
export type PutAppUserBlackAddResponse = boolean

/* **请求函数** */
export async function putAppUserBlackAdd(body: PutAppUserBlackAddBody): Promise<any> {
  return request(`/app/user/black/add`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [移除黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/229)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `PUT /app/user/black/remove/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface PutAppUserBlackRemoveByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutAppUserBlackRemoveByIdResponse = boolean

/* **请求函数** */
export async function putAppUserBlackRemoveById(params: PutAppUserBlackRemoveByIdParams): Promise<any> {
  return request(`/app/user/black/remove/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [离职人员转入黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/231)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `PUT /app/user/black/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface PutAppUserBlackByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface PutAppUserBlackByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutAppUserBlackByIdResponse = boolean

/* **请求函数** */
export async function putAppUserBlackById(
  query: PutAppUserBlackByIdQuery,
  params: PutAppUserBlackByIdParams,
): Promise<any> {
  return request(`/app/user/black/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [人员详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/233)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `GET /app/user/detail/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface GetAppUserDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppUserDetailByIdResponse {
  age: string
  blackRemark: string
  cardNo: string
  enterUserDetailOtherVO: {
    jobName: string
    joinTime: string
    laborName: string
    planName: string
    workDay: number
    workHour: number
  }
  enterUserWorkRecordVOList: {
    day: number
    endDate: string
    hour: number
    id: number
    item: number
    jobName: string
    moneyType: number
    planName: string
    startDate: string
    totalMoney: number
  }[]
  faceUrl: string
  name: string
  phone: string
  sex: string
  status: number
  totalDay: number
  totalHour: number
  totalMoney: number
  totalTime: number
  totalWorkTime: number
}

/* **请求函数** */
export async function getAppUserDetailById(params: GetAppUserDetailByIdParams): Promise<any> {
  return request(`/app/user/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [离职↗](http://fe.jiuworker.com:3000/project/25/interface/api/235)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `PUT /app/user/left/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface PutAppUserLeftByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutAppUserLeftByIdResponse = boolean

/* **请求函数** */
export async function putAppUserLeftById(params: PutAppUserLeftByIdParams): Promise<any> {
  return request(`/app/user/left/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/237)
 *
 * @分类 [小程序端接口:灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_31)
 * @标签 `小程序端接口:灵工管理`
 * @请求头 `GET /app/user/query`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAppUserQueryQuery {
  cardNo?: string
  enterId?: string
  laborId?: string
  name?: string
  pageNum?: number
  pageSize?: number
  phone?: string
  userIdList?: string
  关键字?: string
  '状态：20在职；30离职；40黑名单'?: string
  用工计划?: string
}

/* **返回类型** */
export interface GetAppUserQueryResponse {
  data: {
    age: string
    cardNo: string
    faceUrl: string
    id: number
    jobName: string
    laborName: string
    name: string
    phone: string
    sex: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppUserQuery(query: GetAppUserQueryQuery): Promise<any> {
  return request(`/app/user/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [发起用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/213)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `POST /app/plan/add`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppPlanAddBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: string[]
  /**
   * 是否为节假日标准计价:true是；false否
   */
  holidayType?: boolean
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  isOver?: boolean
  isOverNight?: boolean
  /**
   * 是否发送至抢单大厅
   */
  isSendOrderHall?: boolean
  /**
   * 劳务公司
   */
  laborId?: number
  /**
   * 劳务状态：10计划申请取消中；20申请取消已被拒绝
   */
  laborStatus?: number
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType?: number
  oldRoomAccount?: number
  /**
   * 计划名称
   */
  planName?: string
  relateWorkId?: number
  /**
   * 休息时长
   */
  relaxTime?: number
  /**
   * 取消原因
   */
  remark?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  /**
   * 用工负责人
   */
  workPlanLeader?: number
}

/* **返回类型** */
export type PostAppPlanAddResponse = boolean

/* **请求函数** */
export async function postAppPlanAdd(body: PostAppPlanAddBody): Promise<any> {
  return request(`/app/plan/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [一键授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/215)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `POST /app/plan/auth/auth`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppPlanAuthAuthBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  id?: number
  /**
   * 岗位列表
   */
  jobIdList?: number[]
}

/* **返回类型** */
export type PostAppPlanAuthAuthResponse = boolean

/* **请求函数** */
export async function postAppPlanAuthAuth(body: PostAppPlanAuthAuthBody): Promise<any> {
  return request(`/app/plan/auth/auth`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [取消用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/217)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `GET /app/plan/cancel/{id}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppPlanCancelByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface GetAppPlanCancelByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetAppPlanCancelByIdResponse = boolean

/* **请求函数** */
export async function getAppPlanCancelById(
  query: GetAppPlanCancelByIdQuery,
  params: GetAppPlanCancelByIdParams,
): Promise<any> {
  return request(`/app/plan/cancel/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/219)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `GET /app/plan/detail/{id}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求params类型** */
export interface GetAppPlanDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppPlanDetailByIdResponse {
  enterId: number
  enterPO: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }
  enterWorkPlanDTO: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: string[]
    /**
     * 是否为节假日标准计价:true是；false否
     */
    holidayType: boolean
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    isOver: boolean
    isOverNight: boolean
    /**
     * 是否发送至抢单大厅
     */
    isSendOrderHall: boolean
    /**
     * 劳务公司
     */
    laborId: number
    /**
     * 劳务状态：10计划申请取消中；20申请取消已被拒绝
     */
    laborStatus: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    oldRoomAccount: number
    /**
     * 计划名称
     */
    planName: string
    relateWorkId: number
    /**
     * 休息时长
     */
    relaxTime: number
    /**
     * 取消原因
     */
    remark: string
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    /**
     * 用工负责人
     */
    workPlanLeader: number
  }
  /**
   * 人员信息
   */
  enterWorkPlanUserVOList: {
    /**
     * 年龄
     */
    age: string
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 身份证
     */
    idCard: string
    /**
     * 岗位
     */
    jobName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 电话
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    status: number
    /**
     * 状态名称
     */
    statusName: number
    userId: number
  }[]
  /**
   * 人员进度信息
   */
  enterWorkScheduleVOList: {
    /**
     * 已经招的人数
     */
    hadJoinAmount: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 总人数
     */
    totalAmount: number
  }[]
  /**
   * 已经招收的人数
   */
  hadJoinAmount: number
  /**
   * 主键ID
   */
  id: number
  /**
   * 劳务公司名称
   */
  laborName: string
  /**
   * 工单号
   */
  planNo: string
  /**
   * 关联的审批单名称
   */
  relateWorkIdName: string
  /**
   * 单日费用
   */
  singleMoney: number
  /**
   * 状态
   */
  status: number
  /**
   * 总人数
   */
  totalAmount: number
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 工时汇总
   */
  totalHour: number
  /**
   * 总费用
   */
  totalMoney: number
  /**
   * 用工负责人名称
   */
  workPlanLeaderName: string
  /**
   * 10用工计划 20客房派单
   */
  workPlanType: number
}

/* **请求函数** */
export async function getAppPlanDetailById(params: GetAppPlanDetailByIdParams): Promise<any> {
  return request(`/app/plan/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/221)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `PUT /app/plan/edit`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PutAppPlanEditBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: string[]
  /**
   * 是否为节假日标准计价:true是；false否
   */
  holidayType?: boolean
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  isOver?: boolean
  isOverNight?: boolean
  /**
   * 是否发送至抢单大厅
   */
  isSendOrderHall?: boolean
  /**
   * 劳务公司
   */
  laborId?: number
  /**
   * 劳务状态：10计划申请取消中；20申请取消已被拒绝
   */
  laborStatus?: number
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType?: number
  oldRoomAccount?: number
  /**
   * 计划名称
   */
  planName?: string
  relateWorkId?: number
  /**
   * 休息时长
   */
  relaxTime?: number
  /**
   * 取消原因
   */
  remark?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  /**
   * 用工负责人
   */
  workPlanLeader?: number
}

/* **返回类型** */
export type PutAppPlanEditResponse = boolean

/* **请求函数** */
export async function putAppPlanEdit(body: PutAppPlanEditBody): Promise<any> {
  return request(`/app/plan/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [用工计划列表查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/223)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `GET /app/plan/query`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppPlanQueryQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
   */
  status?: string
}

/* **返回类型** */
export interface GetAppPlanQueryResponse {
  data: {
    /**
     * 用工时间(天)
     */
    dateDay: number
    /**
     * 用工结束时间
     */
    dateEndDay: string
    /**
     * 用工结束时段
     */
    dateEndHour: string
    /**
     * 用工时段(小时)
     */
    dateHour: number
    /**
     * 用工开始时间
     */
    dateStartDay: string
    /**
     * 用工开始时段
     */
    dateStartHour: string
    /**
     * 主键ID
     */
    id: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    /**
     * 用工计划名称
     */
    planName: string
    /**
     * 工单号
     */
    planNo: string
    /**
     * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
     */
    status: number
    /**
     * 总天数
     */
    totalDay: number
    /**
     * 总工时
     */
    totalHour: number
    /**
     * 预计总费用
     */
    totalMoney: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppPlanQuery(query: GetAppPlanQueryQuery): Promise<any> {
  return request(`/app/plan/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划数量查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/225)
 *
 * @分类 [小程序端：用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_34)
 * @标签 `小程序端：用工计划管理`
 * @请求头 `GET /app/plan/query/count`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAppPlanQueryCountQuery {
  /**
   * checkValue
   */
  checkValue?: string
}

/* **返回类型** */
export interface GetAppPlanQueryCountResponse {
  /**
   * 待考勤确认
   */
  attendanceAmount: number
  /**
   * 已取消
   */
  cancelAmount: number
  /**
   * 已完成
   */
  finishAmount: number
  /**
   * 待劳务确认
   */
  laboringAmount: number
  /**
   * 待财务结算
   */
  moneyAmount: number
  /**
   * 接单中数量
   */
  orderingAmount: number
  /**
   * 已拒绝
   */
  refuseAmount: number
  /**
   * 待接单数量
   */
  waitingAmount: number
  /**
   * 进行中数量
   */
  workingAmount: number
}

/* **请求函数** */
export async function getAppPlanQueryCount(query: GetAppPlanQueryCountQuery): Promise<any> {
  return request(`/app/plan/query/count`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [列表查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/195)
 *
 * @分类 [小程序端：考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_35)
 * @标签 `小程序端：考勤管理`
 * @请求头 `GET /app/attendance/list`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppAttendanceListQuery {
  /**
   * 30：进行中；40代考勤结算；80已结束
   */
  status?: string
  /**
   * workType
   */
  workType?: string
}

/* **返回类型** */
export type GetAppAttendanceListResponse = {
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType: number
  /**
   * 用工计划Id
   */
  planId: number
  /**
   * 用工计划名称
   */
  planName: string
  /**
   * 考勤异常
   */
  todayAbnormalAmount: number
  /**
   * 今日出勤
   */
  todayAttendanceAmount: number
  /**
   * 今日工时
   */
  todayHour: number
  /**
   * 今日排班
   */
  todayPeopleAmount: number
  /**
   * 总天数
   */
  totalDay: number
  /**
   * 总工时
   */
  totalHour: number
  /**
   * 总计件
   */
  totalItem: number
  /**
   * 出勤人次
   */
  totalPeopleAmount: number
  workType: number
}[]

/* **请求函数** */
export async function getAppAttendanceList(query: GetAppAttendanceListQuery): Promise<any> {
  return request(`/app/attendance/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取完工用工计划详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/197)
 *
 * @分类 [小程序端：考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_35)
 * @标签 `小程序端：考勤管理`
 * @请求头 `GET /app/attendance/plan/detail/{planId}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求params类型** */
export interface GetAppAttendancePlanDetailByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAppAttendancePlanDetailByPlanIdResponse {
  /**
   * 旷工
   */
  abstainedAmount: number
  /**
   * 人员信息详情
   */
  attendancePlanUserDetailVOList: {
    /**
     * 旷工
     */
    abstainedAmount: number
    /**
     * 早退
     */
    backAmount: number
    /**
     * 迟到
     */
    delayAmount: number
    /**
     * 人脸照
     */
    faceUrl: string
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 缺卡
     */
    missAmount: number
    /**
     * 人员名称
     */
    personName: string
    /**
     * 电话
     */
    phone: string
    /**
     * 总天数
     */
    totalDay: number
    /**
     * 总工时
     */
    totalHour: number
    /**
     * 总计件
     */
    totalItem: number
    /**
     * 用户ID
     */
    userId: number
  }[]
  /**
   * 早退
   */
  backAmount: number
  /**
   * 迟到
   */
  delayAmount: number
  /**
   * 缺卡
   */
  missAmount: number
  /**
   * 人员数量
   */
  peopleAmount: number
  /**
   * 总天数
   */
  totalDay: number
  /**
   * 总工时
   */
  totalHour: number
  /**
   * 总计件
   */
  totalItem: number
  /**
   * 出勤人次
   */
  totalPeopleAmount: number
}

/* **请求函数** */
export async function getAppAttendancePlanDetailByPlanId(
  params: GetAppAttendancePlanDetailByPlanIdParams,
): Promise<any> {
  return request(`/app/attendance/plan/detail/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [进行中计划详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/199)
 *
 * @分类 [小程序端：考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_35)
 * @标签 `小程序端：考勤管理`
 * @请求头 `GET /app/attendance/plan/{planId}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppAttendancePlanByPlanIdQuery {
  /**
   * 格式yyyy-MM-dd
   */
  queryDate?: string
}

/* **请求params类型** */
export interface GetAppAttendancePlanByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAppAttendancePlanByPlanIdResponse {
  abnormalAmount: number
  abnormalAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  attendanceAmount: number
  hadAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  notAttendancePeopleList: {
    afterDate: string
    afterStatus: number
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    moonDate: string
    moonStatus: number
    phone: string
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  /**
   * 用工计划名称
   */
  planName: string
  totalHour: number
  totalPeople: number
}

/* **请求函数** */
export async function getAppAttendancePlanByPlanId(
  query: GetAppAttendancePlanByPlanIdQuery,
  params: GetAppAttendancePlanByPlanIdParams,
): Promise<any> {
  return request(`/app/attendance/plan/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取个人考勤详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/201)
 *
 * @分类 [小程序端：考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_35)
 * @标签 `小程序端：考勤管理`
 * @请求头 `GET /app/attendance/user/detail/{planId}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppAttendanceUserDetailByPlanIdQuery {
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface GetAppAttendanceUserDetailByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAppAttendanceUserDetailByPlanIdResponse {
  /**
   * 人员月度考勤详情
   */
  attendanceUserMonthVOList: {
    /**
     * 是否异常：true 是；false 否
     */
    abnormal: boolean
    /**
     * 下班打卡时间
     */
    afterDate: string
    /**
     * 下班打卡状态
     */
    afterStatus: number
    /**
     * 打卡日期：yyyy-MM-dd
     */
    dayDate: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 上班打卡时间
     */
    moonDate: string
    /**
     * 上班打卡状态
     */
    moonStatus: number
    /**
     * 工作工时
     */
    workHour: number
  }[]
  /**
   * 部门
   */
  deptName: string
  /**
   * 人脸
   */
  faceUrl: string
  name: string
  /**
   * 异常
   */
  totalAbnormal: number
  /**
   * 累计天数
   */
  totalDays: number
  /**
   * 累计工时
   */
  totalHour: number
  /**
   * 计件
   */
  totalItem: number
}

/* **请求函数** */
export async function getAppAttendanceUserDetailByPlanId(
  query: GetAppAttendanceUserDetailByPlanIdQuery,
  params: GetAppAttendanceUserDetailByPlanIdParams,
): Promise<any> {
  return request(`/app/attendance/user/detail/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [工人计件↗](http://fe.jiuworker.com:3000/project/25/interface/api/203)
 *
 * @分类 [小程序端：考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_35)
 * @标签 `小程序端：考勤管理`
 * @请求头 `POST /app/attendance/user/item`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppAttendanceUserItemBody {
  amount?: number
  planId?: number
  queryDate?: string
  remark?: string
  userId?: number
}

/* **返回类型** */
export type PostAppAttendanceUserItemResponse = boolean

/* **请求函数** */
export async function postAppAttendanceUserItem(body: PostAppAttendanceUserItemBody): Promise<any> {
  return request(`/app/attendance/user/item`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [添加黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/503)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `PUT /user/black/add`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求body类型** */
export interface PutUserBlackAddBody {
  /**
   * 备注
   */
  blackRemark?: string
  /**
   * 身份证
   */
  idCard?: string
  /**
   * 姓名
   */
  name?: string
}

/* **返回类型** */
export type PutUserBlackAddResponse = boolean

/* **请求函数** */
export async function putUserBlackAdd(body: PutUserBlackAddBody): Promise<any> {
  return request(`/user/black/add`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [移除黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/505)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `PUT /user/black/remove/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface PutUserBlackRemoveByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutUserBlackRemoveByIdResponse = boolean

/* **请求函数** */
export async function putUserBlackRemoveById(params: PutUserBlackRemoveByIdParams): Promise<any> {
  return request(`/user/black/remove/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [离职人员转入黑名单↗](http://fe.jiuworker.com:3000/project/25/interface/api/507)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `PUT /user/black/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface PutUserBlackByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface PutUserBlackByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutUserBlackByIdResponse = boolean

/* **请求函数** */
export async function putUserBlackById(query: PutUserBlackByIdQuery, params: PutUserBlackByIdParams): Promise<any> {
  return request(`/user/black/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [人员详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/509)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `GET /user/detail/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface GetUserDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetUserDetailByIdResponse {
  age: string
  blackRemark: string
  cardNo: string
  enterUserDetailOtherVO: {
    jobName: string
    joinTime: string
    laborName: string
    planName: string
    workDay: number
    workHour: number
  }
  enterUserWorkRecordVOList: {
    day: number
    endDate: string
    hour: number
    id: number
    item: number
    jobName: string
    moneyType: number
    planName: string
    startDate: string
    totalMoney: number
  }[]
  faceUrl: string
  name: string
  phone: string
  sex: string
  status: number
  totalDay: number
  totalHour: number
  totalMoney: number
  totalTime: number
  totalWorkTime: number
}

/* **请求函数** */
export async function getUserDetailById(params: GetUserDetailByIdParams): Promise<any> {
  return request(`/user/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [离职↗](http://fe.jiuworker.com:3000/project/25/interface/api/511)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `PUT /user/left/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求params类型** */
export interface PutUserLeftByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutUserLeftByIdResponse = boolean

/* **请求函数** */
export async function putUserLeftById(params: PutUserLeftByIdParams): Promise<any> {
  return request(`/user/left/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/513)
 *
 * @分类 [灵工管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_41)
 * @标签 `灵工管理`
 * @请求头 `GET /user/query`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetUserQueryQuery {
  cardNo?: string
  enterId?: string
  laborId?: string
  name?: string
  pageNum?: number
  pageSize?: number
  phone?: string
  userIdList?: string
  关键字?: string
  '状态：20在职；30离职；40黑名单'?: string
  用工计划?: string
}

/* **返回类型** */
export interface GetUserQueryResponse {
  data: {
    age: string
    cardNo: string
    faceUrl: string
    id: number
    jobName: string
    laborName: string
    name: string
    phone: string
    sex: string
  }[]
  total: number
}

/* **请求函数** */
export async function getUserQuery(query: GetUserQueryQuery): Promise<any> {
  return request(`/user/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [发起用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/477)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `POST /plan/add`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface PostPlanAddBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: string[]
  /**
   * 是否为节假日标准计价:true是；false否
   */
  holidayType?: boolean
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  isOver?: boolean
  isOverNight?: boolean
  /**
   * 是否发送至抢单大厅
   */
  isSendOrderHall?: boolean
  /**
   * 劳务公司
   */
  laborId?: number
  /**
   * 劳务状态：10计划申请取消中；20申请取消已被拒绝
   */
  laborStatus?: number
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType?: number
  oldRoomAccount?: number
  /**
   * 计划名称
   */
  planName?: string
  relateWorkId?: number
  /**
   * 休息时长
   */
  relaxTime?: number
  /**
   * 取消原因
   */
  remark?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  /**
   * 用工负责人
   */
  workPlanLeader?: number
}

/* **返回类型** */
export type PostPlanAddResponse = boolean

/* **请求函数** */
export async function postPlanAdd(body: PostPlanAddBody): Promise<any> {
  return request(`/plan/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [一键授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/479)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/auth/auto`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface GetPlanAuthAutoBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  id?: number
  /**
   * 岗位列表
   */
  jobIdList?: number[]
}

/* **返回类型** */
export type GetPlanAuthAutoResponse = boolean

/* **请求函数** */
export async function getPlanAuthAuto(body: GetPlanAuthAutoBody): Promise<any> {
  return request(`/plan/auth/auto`, {
    method: Method.GET,

    data: body,
  })
}

/**
 * 接口 [接单中取消计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/481)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/cancel/labor/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanCancelLaborByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface GetPlanCancelLaborByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetPlanCancelLaborByIdResponse = boolean

/* **请求函数** */
export async function getPlanCancelLaborById(
  query: GetPlanCancelLaborByIdQuery,
  params: GetPlanCancelLaborByIdParams,
): Promise<any> {
  return request(`/plan/cancel/labor/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [劳务申请取消计划查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/483)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/cancel/query`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanCancelQueryQuery {
  /**
   * 10:申请中；20已拒绝
   */
  status?: string
}

/* **返回类型** */
export type GetPlanCancelQueryResponse = {
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList: {
    /**
     * 数量
     */
    amount: number
    /**
     * 其他业务字段
     */
    bizData: string
    /**
     * 假期岗位单价
     */
    holidayMoney: number
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 男数量
     */
    manNumber: string
    maxAge: string
    minAge: string
    /**
     * 岗位单价
     */
    money: number
    /**
     * 客房量
     */
    roomInfoList: {
      currentMoney: number
      holidayMoney: number
      money: number
      roomAmount: number
      roomTypeName: string
      unitMoney: string
      weekendMoney: number
    }[]
    /**
     * 单位
     */
    unit: string
    /**
     * 女数量
     */
    womanNumber: string
  }[]
  id: number
  /**
   * 劳务公司名称
   */
  laborName: string
  /**
   * 计划名称
   */
  planName: string
  /**
   * 计划工单号
   */
  planNo: string
  /**
   * 拒绝原因
   */
  refuseRemark: string
  /**
   * 取消原因
   */
  remark: string
}[]

/* **请求函数** */
export async function getPlanCancelQuery(query: GetPlanCancelQueryQuery): Promise<any> {
  return request(`/plan/cancel/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [取消用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/485)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/cancel/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanCancelByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface GetPlanCancelByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetPlanCancelByIdResponse = boolean

/* **请求函数** */
export async function getPlanCancelById(query: GetPlanCancelByIdQuery, params: GetPlanCancelByIdParams): Promise<any> {
  return request(`/plan/cancel/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/487)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/detail/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求params类型** */
export interface GetPlanDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetPlanDetailByIdResponse {
  enterId: number
  enterPO: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }
  enterWorkPlanDTO: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: string[]
    /**
     * 是否为节假日标准计价:true是；false否
     */
    holidayType: boolean
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    isOver: boolean
    isOverNight: boolean
    /**
     * 是否发送至抢单大厅
     */
    isSendOrderHall: boolean
    /**
     * 劳务公司
     */
    laborId: number
    /**
     * 劳务状态：10计划申请取消中；20申请取消已被拒绝
     */
    laborStatus: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    oldRoomAccount: number
    /**
     * 计划名称
     */
    planName: string
    relateWorkId: number
    /**
     * 休息时长
     */
    relaxTime: number
    /**
     * 取消原因
     */
    remark: string
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    /**
     * 用工负责人
     */
    workPlanLeader: number
  }
  /**
   * 人员信息
   */
  enterWorkPlanUserVOList: {
    /**
     * 年龄
     */
    age: string
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 身份证
     */
    idCard: string
    /**
     * 岗位
     */
    jobName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 电话
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    status: number
    /**
     * 状态名称
     */
    statusName: number
    userId: number
  }[]
  /**
   * 人员进度信息
   */
  enterWorkScheduleVOList: {
    /**
     * 已经招的人数
     */
    hadJoinAmount: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 总人数
     */
    totalAmount: number
  }[]
  /**
   * 已经招收的人数
   */
  hadJoinAmount: number
  /**
   * 主键ID
   */
  id: number
  /**
   * 劳务公司名称
   */
  laborName: string
  /**
   * 工单号
   */
  planNo: string
  /**
   * 关联的审批单名称
   */
  relateWorkIdName: string
  /**
   * 单日费用
   */
  singleMoney: number
  /**
   * 状态
   */
  status: number
  /**
   * 总人数
   */
  totalAmount: number
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 工时汇总
   */
  totalHour: number
  /**
   * 总费用
   */
  totalMoney: number
  /**
   * 用工负责人名称
   */
  workPlanLeaderName: string
  /**
   * 10用工计划 20客房派单
   */
  workPlanType: number
}

/* **请求函数** */
export async function getPlanDetailById(params: GetPlanDetailByIdParams): Promise<any> {
  return request(`/plan/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑用工计划↗](http://fe.jiuworker.com:3000/project/25/interface/api/489)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `PUT /plan/edit`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface PutPlanEditBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  /**
   * 岗位需求
   */
  enterWorkApplyJobDTOList?: {
    /**
     * 数量
     */
    amount?: number
    /**
     * 其他业务字段
     */
    bizData?: string
    /**
     * 假期岗位单价
     */
    holidayMoney?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 岗位名称
     */
    jobName?: string
    /**
     * 男数量
     */
    manNumber?: string
    maxAge?: string
    minAge?: string
    /**
     * 岗位单价
     */
    money?: number
    /**
     * 客房量
     */
    roomInfoList?: {
      currentMoney?: number
      holidayMoney?: number
      money?: number
      roomAmount?: number
      roomTypeName?: string
      unitMoney?: string
      weekendMoney?: number
    }[]
    /**
     * 单位
     */
    unit?: string
    /**
     * 女数量
     */
    womanNumber?: string
  }[]
  /**
   * 附件
   */
  fileUrlList?: string[]
  /**
   * 是否为节假日标准计价:true是；false否
   */
  holidayType?: boolean
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  isOver?: boolean
  isOverNight?: boolean
  /**
   * 是否发送至抢单大厅
   */
  isSendOrderHall?: boolean
  /**
   * 劳务公司
   */
  laborId?: number
  /**
   * 劳务状态：10计划申请取消中；20申请取消已被拒绝
   */
  laborStatus?: number
  /**
   * 计价方式：10小时；20按天；30按计件
   */
  moneyType?: number
  oldRoomAccount?: number
  /**
   * 计划名称
   */
  planName?: string
  relateWorkId?: number
  /**
   * 休息时长
   */
  relaxTime?: number
  /**
   * 取消原因
   */
  remark?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 开始用工时段
   */
  startHour?: string
  /**
   * 用工负责人
   */
  workPlanLeader?: number
}

/* **返回类型** */
export type PutPlanEditResponse = boolean

/* **请求函数** */
export async function putPlanEdit(body: PutPlanEditBody): Promise<any> {
  return request(`/plan/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [编辑设备↗](http://fe.jiuworker.com:3000/project/25/interface/api/491)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `PUT /plan/edit/device`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface PutPlanEditDeviceQuery {
  /**
   * 多个设备逗号隔开
   */
  deviceIds?: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type PutPlanEditDeviceResponse = boolean

/* **请求函数** */
export async function putPlanEditDevice(query: PutPlanEditDeviceQuery): Promise<any> {
  return request(`/plan/edit/device`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [劳务申请取消计划操作↗](http://fe.jiuworker.com:3000/project/25/interface/api/493)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/labor/cancel/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanLaborCancelByIdQuery {
  /**
   * remark
   */
  remark?: string
  /**
   * 30:同意；20拒绝
   */
  status?: string
}

/* **请求params类型** */
export interface GetPlanLaborCancelByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetPlanLaborCancelByIdResponse = boolean

/* **请求函数** */
export async function getPlanLaborCancelById(
  query: GetPlanLaborCancelByIdQuery,
  params: GetPlanLaborCancelByIdParams,
): Promise<any> {
  return request(`/plan/labor/cancel/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [人员离职↗](http://fe.jiuworker.com:3000/project/25/interface/api/495)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `DELETE /plan/labor/left/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface DeletePlanLaborLeftByIdQuery {
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface DeletePlanLaborLeftByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeletePlanLaborLeftByIdResponse = boolean

/* **请求函数** */
export async function deletePlanLaborLeftById(
  query: DeletePlanLaborLeftByIdQuery,
  params: DeletePlanLaborLeftByIdParams,
): Promise<any> {
  return request(`/plan/labor/left/${params.id}`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [移除人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/497)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `DELETE /plan/labor/remove/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface DeletePlanLaborRemoveByIdQuery {
  /**
   * remark
   */
  remark?: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface DeletePlanLaborRemoveByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeletePlanLaborRemoveByIdResponse = boolean

/* **请求函数** */
export async function deletePlanLaborRemoveById(
  query: DeletePlanLaborRemoveByIdQuery,
  params: DeletePlanLaborRemoveByIdParams,
): Promise<any> {
  return request(`/plan/labor/remove/${params.id}`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [用工计划pdf信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/499)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/pdf/info/{planId}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求params类型** */
export interface GetPlanPdfInfoByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetPlanPdfInfoByPlanIdResponse = any

/* **请求函数** */
export async function getPlanPdfInfoByPlanId(params: GetPlanPdfInfoByPlanIdParams): Promise<any> {
  return request(`/plan/pdf/info/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/501)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/query`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanQueryQuery {
  enterId?: string
  pageNum?: number
  pageSize?: number
  userId?: string
  workPlanType?: string
  状态?: string
  用工开始时间?: string
  用工结束时间?: string
  计划单号?: string
  计划单名称?: string
}

/* **返回类型** */
export interface GetPlanQueryResponse {
  data: {
    /**
     * 用工时间(天)
     */
    dateDay: number
    /**
     * 用工时段(小时)
     */
    dateHour: number
    /**
     * 酒店名称
     */
    enterName: string
    enterWorkPlanDTO: {
      /**
       * 联系人
       */
      contractPerson: string
      /**
       * 联系人电话
       */
      contractPhone: string
      /**
       * 自动授权的设备列表
       */
      deviceIdList: {
        deviceId: number
        deviceName: string
      }[]
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 抢单截止时间
       */
      endDateOrderHall: string
      /**
       * 结束用工时段
       */
      endHour: string
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: string[]
      /**
       * 是否为节假日标准计价:true是；false否
       */
      holidayType: boolean
      id: number
      /**
       * 是否自动授权:true是；false否
       */
      isAutoAuth: boolean
      isOver: boolean
      isOverNight: boolean
      /**
       * 是否发送至抢单大厅
       */
      isSendOrderHall: boolean
      /**
       * 劳务公司
       */
      laborId: number
      /**
       * 劳务状态：10计划申请取消中；20申请取消已被拒绝
       */
      laborStatus: number
      /**
       * 计价方式：10小时；20按天；30按计件
       */
      moneyType: number
      oldRoomAccount: number
      /**
       * 计划名称
       */
      planName: string
      relateWorkId: number
      /**
       * 休息时长
       */
      relaxTime: number
      /**
       * 取消原因
       */
      remark: string
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      /**
       * 用工负责人
       */
      workPlanLeader: number
    }
    gmtCreate: string
    /**
     * 主键ID
     */
    id: number
    laborId: number
    /**
     * 劳务公司
     */
    laborName: string
    /**
     * 招工进度
     */
    percent: string
    /**
     * 工单号
     */
    planNo: string
    /**
     * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
     */
    status: number
    /**
     * 总工时
     */
    totalHour: number
    /**
     * 总费用
     */
    totalMoney: number
    workPlanType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getPlanQuery(query: GetPlanQueryQuery): Promise<any> {
  return request(`/plan/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [addPlan↗](http://fe.jiuworker.com:3000/project/25/interface/api/1993)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `POST /plan/fade/out/add`
 * @更新时间 `2024-02-01 10:00:07`
 */

/* **请求query类型** */
export interface PostPlanFadeOutAddQuery {
  /**
   * number
   */
  number: string
}

/* **返回类型** */
export type PostPlanFadeOutAddResponse = boolean

/* **请求函数** */
export async function postPlanFadeOutAdd(query: PostPlanFadeOutAddQuery): Promise<any> {
  return request(`/plan/fade/out/add`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [addPlan↗](http://fe.jiuworker.com:3000/project/25/interface/api/2049)
 *
 * @分类 [用工计划管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_43)
 * @标签 `用工计划管理`
 * @请求头 `GET /plan/fade/add`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanFadeAddQuery {
  /**
   * cityCode
   */
  cityCode?: string
  /**
   * number
   */
  number: string
}

/* **返回类型** */
export type GetPlanFadeAddResponse = boolean

/* **请求函数** */
export async function getPlanFadeAdd(query: GetPlanFadeAddQuery): Promise<any> {
  return request(`/plan/fade/add`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加考勤记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/253)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/add`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostAttendanceAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  isFromApp?: boolean
  /**
   * 计件数量
   */
  itemCount?: number
  /**
   * 工作计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 房间类型数量
   */
  roomApplyDTOList?: {
    roomAmount?: number
    roomType?: string
  }[]
  roomId?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
}

/* **返回类型** */
export type PostAttendanceAddResponse = boolean

/* **请求函数** */
export async function postAttendanceAdd(body: PostAttendanceAddBody): Promise<any> {
  return request(`/attendance/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [修改日志↗](http://fe.jiuworker.com:3000/project/25/interface/api/255)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/day/log/{id}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceDayLogByIdQuery {
  /**
   * 10:考勤；20计价
   */
  logType?: string
}

/* **请求params类型** */
export interface GetAttendanceDayLogByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetAttendanceDayLogByIdResponse = {
  deptName: string
  id: number
  jobName: string
  name: string
  oldData: string
  remark: string
  repairData: string
}[]

/* **请求函数** */
export async function getAttendanceDayLogById(
  query: GetAttendanceDayLogByIdQuery,
  params: GetAttendanceDayLogByIdParams,
): Promise<any> {
  return request(`/attendance/day/log/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [每日考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/257)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/day/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceDayByPlanIdQuery {
  /**
   * dayDate
   */
  dayDate: string
}

/* **请求params类型** */
export interface GetAttendanceDayByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAttendanceDayByPlanIdResponse {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  enterAttendanceDayDetailVOList: {
    afterStatus: number
    afternoonDate: string
    dayDate: string
    deptName: string
    id: number
    jobName: string
    moonDate: string
    moonStatus: number
    name: string
    roomAmountRemark: string
    teamName: string
    totalHour: number
    totalTimeAmount: number
    userId: number
  }[]
  missAmount: number
  repairAmount: number
  totalAmount: number
  totalAttendanceAmount: number
  totalHour: number
  totalPeopleAmount: number
}

/* **请求函数** */
export async function getAttendanceDayByPlanId(
  query: GetAttendanceDayByPlanIdQuery,
  params: GetAttendanceDayByPlanIdParams,
): Promise<any> {
  return request(`/attendance/day/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加计件数量↗](http://fe.jiuworker.com:3000/project/25/interface/api/259)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/item/add`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostAttendanceItemAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  isFromApp?: boolean
  /**
   * 计件数量
   */
  itemCount?: number
  /**
   * 工作计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 房间类型数量
   */
  roomApplyDTOList?: {
    roomAmount?: number
    roomType?: string
  }[]
  roomId?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
}

/* **返回类型** */
export type PostAttendanceItemAddResponse = boolean

/* **请求函数** */
export async function postAttendanceItemAdd(body: PostAttendanceItemAddBody): Promise<any> {
  return request(`/attendance/item/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [月度汇总↗](http://fe.jiuworker.com:3000/project/25/interface/api/261)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/month/{month}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceMonthByMonthQuery {
  /**
   * planId
   */
  planId: string
}

/* **请求params类型** */
export interface GetAttendanceMonthByMonthParams {
  /**
   * 月份yyyy-MM-dd
   */
  month: string
}

/* **返回类型** */
export interface GetAttendanceMonthByMonthResponse {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  enterAttendancePlanMonthDetailVOList: {
    abstainedAmount: number
    attendanceDate: string
    backAmount: number
    delayAmount: number
    missAmount: number
    totalAmount: number
    totalAttendanceAmount: number
    totalHour: number
  }[]
  missAmount: number
  totalAmount: number
  totalAttendanceAmount: number
  totalHour: number
}

/* **请求函数** */
export async function getAttendanceMonthByMonth(
  query: GetAttendanceMonthByMonthQuery,
  params: GetAttendanceMonthByMonthParams,
): Promise<any> {
  return request(`/attendance/month/${params.month}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工计划下的人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/263)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/person/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface GetAttendancePersonByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAttendancePersonByPlanIdResponse = {
  age: string
  cardNo: string
  faceUrl: string
  id: number
  jobName: string
  laborName: string
  name: string
  phone: string
  sex: string
}[]

/* **请求函数** */
export async function getAttendancePersonByPlanId(params: GetAttendancePersonByPlanIdParams): Promise<any> {
  return request(`/attendance/person/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [用工考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/265)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/plan`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendancePlanQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * planId
   */
  planId?: string
  /**
   * planName
   */
  planName?: string
  /**
   * startDate
   */
  startDate?: string
  /**
   * 30 进行中，40:待确认；50已确认，60待结算，80已完成
   */
  status?: string
}

/* **返回类型** */
export type GetAttendancePlanResponse = {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  missAmount: number
  planId: number
  planName: string
  status: number
  totalAttendanceAmount: number
  totalDay: number
  totalHour: number
  totalItemAmount: number
}[]

/* **请求函数** */
export async function getAttendancePlan(query: GetAttendancePlanQuery): Promise<any> {
  return request(`/attendance/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [确认考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/267)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/plan/confirm/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface GetAttendancePlanConfirmByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAttendancePlanConfirmByPlanIdResponse = boolean

/* **请求函数** */
export async function getAttendancePlanConfirmByPlanId(params: GetAttendancePlanConfirmByPlanIdParams): Promise<any> {
  return request(`/attendance/plan/confirm/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [用工考勤详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/269)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/plan/detail/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求params类型** */
export interface GetAttendancePlanDetailByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAttendancePlanDetailByPlanIdResponse {
  abstainedAmount: number
  attendanceAmount: number
  backAmount: number
  delayAmount: number
  enterAttendanceUserVOList: {
    abstainedAmount: number
    backAmount: number
    delayAmount: number
    jobName: string
    missAmount: number
    repairAmount: number
    totalDay: number
    totalHour: number
    totalItemAmount: number
    userId: number
    userName: string
  }[]
  missAmount: number
  planName: string
  totalDay: number
  totalHour: number
  totalItemAmount: number
  totalPersonCount: number
}

/* **请求函数** */
export async function getAttendancePlanDetailByPlanId(params: GetAttendancePlanDetailByPlanIdParams): Promise<any> {
  return request(`/attendance/plan/detail/${params.planId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/271)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/record/page`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceRecordPageQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * planId
   */
  planId?: string
  /**
   * startDate
   */
  startDate?: string
}

/* **返回类型** */
export interface GetAttendanceRecordPageResponse {
  data: {
    gmtCreate: string
    id: number
    name: string
    placeName: string
    planName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceRecordPage(query: GetAttendanceRecordPageQuery): Promise<any> {
  return request(`/attendance/record/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [补卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/273)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/repair/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceRepairByPlanIdQuery {
  /**
   * dayDate
   */
  dayDate?: string
  /**
   * name
   */
  name?: string
}

/* **请求params类型** */
export interface GetAttendanceRepairByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAttendanceRepairByPlanIdResponse = {
  deptName: string
  id: number
  jobName: string
  name: string
  oldData: string
  remark: string
  repairData: string
}[]

/* **请求函数** */
export async function getAttendanceRepairByPlanId(
  query: GetAttendanceRepairByPlanIdQuery,
  params: GetAttendanceRepairByPlanIdParams,
): Promise<any> {
  return request(`/attendance/repair/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [员工考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/275)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/user/day/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceUserDayByPlanIdQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * jobId
   */
  jobId?: string
  /**
   * startDate
   */
  startDate?: string
}

/* **请求params类型** */
export interface GetAttendanceUserDayByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAttendanceUserDayByPlanIdResponse = {
  abstainedAmount: number
  backAmount: number
  delayAmount: number
  jobName: string
  missAmount: number
  repairAmount: number
  totalDay: number
  totalHour: number
  totalItemAmount: number
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function getAttendanceUserDayByPlanId(
  query: GetAttendanceUserDayByPlanIdQuery,
  params: GetAttendanceUserDayByPlanIdParams,
): Promise<any> {
  return request(`/attendance/user/day/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [员工考勤详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/277)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/user/detail/{planId}`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceUserDetailByPlanIdQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * startDate
   */
  startDate?: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface GetAttendanceUserDetailByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAttendanceUserDetailByPlanIdResponse {
  enterAttendanceDayDetailVOList: {
    afterStatus: number
    afternoonDate: string
    dayDate: string
    deptName: string
    id: number
    jobName: string
    moonDate: string
    moonStatus: number
    name: string
    roomAmountRemark: string
    teamName: string
    totalHour: number
    totalTimeAmount: number
    userId: number
  }[]
  faceUrl: string
  jobName: string
  name: string
  planName: string
}

/* **请求函数** */
export async function getAttendanceUserDetailByPlanId(
  query: GetAttendanceUserDetailByPlanIdQuery,
  params: GetAttendanceUserDetailByPlanIdParams,
): Promise<any> {
  return request(`/attendance/user/detail/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [考勤调整↗](http://fe.jiuworker.com:3000/project/25/interface/api/673)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/adjust`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求body类型** */
export interface PostAttendanceAdjustBody {
  /**
   * 调整类型:10增加；20减少
   */
  adjustType?: number
  /**
   * 调整工时量/计件
   */
  amount?: number
  jobName?: string
  /**
   * 调整日前
   */
  modifyDate?: string
  /**
   * 工作计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 调整人员ID
   */
  userId?: number
}

/* **返回类型** */
export type PostAttendanceAdjustResponse = boolean

/* **请求函数** */
export async function postAttendanceAdjust(body: PostAttendanceAdjustBody): Promise<any> {
  return request(`/attendance/adjust`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [调整记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/675)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_45)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/adjust/page`
 * @更新时间 `2024-12-11 15:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceAdjustPageQuery {
  /**
   * dayDate
   */
  dayDate?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * planId
   */
  planId: string
  /**
   * startDate
   */
  startDate?: string
}

/* **返回类型** */
export interface GetAttendanceAdjustPageResponse {
  data: {
    createDate: string
    id: number
    jobName: string
    modifyContent: string
    modifyDate: string
    name: string
    remark: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceAdjustPage(query: GetAttendanceAdjustPageQuery): Promise<any> {
  return request(`/attendance/adjust/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加设备↗](http://fe.jiuworker.com:3000/project/25/interface/api/339)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `POST /device/add`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostDeviceAddBody {
  deviceName?: string
  deviceNo?: string
  enterId?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeviceAddResponse = boolean

/* **请求函数** */
export async function postDeviceAdd(body: PostDeviceAddBody): Promise<any> {
  return request(`/device/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [新增授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/341)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `POST /device/add/auth`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求body类型** */
export interface PostDeviceAddAuthBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  /**
   * 授权数据列表
   */
  enterDeviceAuthDetailDTOList?: {
    /**
     * 数据类型：10:人员；20用工计划
     */
    dataType?: number
    /**
     * 数据id
     */
    id?: number
  }[]
}

/* **返回类型** */
export type PostDeviceAddAuthResponse = boolean

/* **请求函数** */
export async function postDeviceAddAuth(body: PostDeviceAddAuthBody): Promise<any> {
  return request(`/device/add/auth`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [重新授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/343)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `PUT /device/again/auth/{id}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface PutDeviceAgainAuthByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeviceAgainAuthByIdResponse = boolean

/* **请求函数** */
export async function putDeviceAgainAuthById(params: PutDeviceAgainAuthByIdParams): Promise<any> {
  return request(`/device/again/auth/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [用工计划列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/345)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `GET /device/auth/plan`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetDeviceAuthPlanQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * planName
   */
  planName?: string
}

/* **返回类型** */
export interface GetDeviceAuthPlanResponse {
  data: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: string[]
    /**
     * 是否为节假日标准计价:true是；false否
     */
    holidayType: boolean
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    isOver: boolean
    isOverNight: boolean
    /**
     * 是否发送至抢单大厅
     */
    isSendOrderHall: boolean
    /**
     * 劳务公司
     */
    laborId: number
    /**
     * 劳务状态：10计划申请取消中；20申请取消已被拒绝
     */
    laborStatus: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    oldRoomAccount: number
    /**
     * 计划名称
     */
    planName: string
    relateWorkId: number
    /**
     * 休息时长
     */
    relaxTime: number
    /**
     * 取消原因
     */
    remark: string
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    /**
     * 用工负责人
     */
    workPlanLeader: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceAuthPlan(query: GetDeviceAuthPlanQuery): Promise<any> {
  return request(`/device/auth/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [授权人员列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/347)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `GET /device/auth/user`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetDeviceAuthUserQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetDeviceAuthUserResponse {
  data: {
    age: string
    cardNo: string
    faceUrl: string
    id: number
    jobName: string
    laborName: string
    name: string
    phone: string
    sex: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceAuthUser(query: GetDeviceAuthUserQuery): Promise<any> {
  return request(`/device/auth/user`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [取消授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/349)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `PUT /device/cancel/auth`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface PutDeviceCancelAuthQuery {
  /**
   * idList
   */
  idList: string
}

/* **返回类型** */
export type PutDeviceCancelAuthResponse = boolean

/* **请求函数** */
export async function putDeviceCancelAuth(query: PutDeviceCancelAuthQuery): Promise<any> {
  return request(`/device/cancel/auth`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [删除设备↗](http://fe.jiuworker.com:3000/project/25/interface/api/351)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `DELETE /device/delete/{id}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface DeleteDeviceDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeviceDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeviceDeleteById(params: DeleteDeviceDeleteByIdParams): Promise<any> {
  return request(`/device/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑设备↗](http://fe.jiuworker.com:3000/project/25/interface/api/353)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `GET /device/edit/{id}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetDeviceEditByIdQuery {
  /**
   * deviceName
   */
  deviceName: string
}

/* **请求params类型** */
export interface GetDeviceEditByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetDeviceEditByIdResponse = boolean

/* **请求函数** */
export async function getDeviceEditById(query: GetDeviceEditByIdQuery, params: GetDeviceEditByIdParams): Promise<any> {
  return request(`/device/edit/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询设备↗](http://fe.jiuworker.com:3000/project/25/interface/api/355)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `GET /device/query`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetDeviceQueryQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeviceQueryResponse {
  data: {
    deviceName: string
    deviceNo: string
    id: number
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceQuery(query: GetDeviceQueryQuery): Promise<any> {
  return request(`/device/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [设备授权列表查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/357)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_47)
 * @标签 `设备管理`
 * @请求头 `GET /device/query/auth`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetDeviceQueryAuthQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeviceQueryAuthResponse {
  data: {
    deviceName: string
    deviceNo: string
    faceUrl: string
    gmtModified: string
    id: number
    name: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceQueryAuth(query: GetDeviceQueryAuthQuery): Promise<any> {
  return request(`/device/query/auth`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [新增排班↗](http://fe.jiuworker.com:3000/project/25/interface/api/1287)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/add`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PostDeptArrangeAddBody {
  /**
   * 打卡设备
   */
  deviceIdList?: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType?: number
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList?: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId?: number
    name?: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo?: number
  }[]
  enterId?: number
  id?: number
  /**
   * 排班名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type?: number
}

/* **返回类型** */
export type PostDeptArrangeAddResponse = boolean

/* **请求函数** */
export async function postDeptArrangeAdd(body: PostDeptArrangeAddBody): Promise<any> {
  return request(`/dept/arrange/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [排班删除↗](http://fe.jiuworker.com:3000/project/25/interface/api/1289)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `DELETE /dept/arrange/delete/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface DeleteDeptArrangeDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptArrangeDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptArrangeDeleteById(params: DeleteDeptArrangeDeleteByIdParams): Promise<any> {
  return request(`/dept/arrange/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑排班↗](http://fe.jiuworker.com:3000/project/25/interface/api/1291)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/edit`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PutDeptArrangeEditBody {
  /**
   * 打卡设备
   */
  deviceIdList?: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType?: number
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList?: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId?: number
    name?: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo?: number
  }[]
  enterId?: number
  id?: number
  /**
   * 排班名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type?: number
}

/* **返回类型** */
export type PutDeptArrangeEditResponse = boolean

/* **请求函数** */
export async function putDeptArrangeEdit(body: PutDeptArrangeEditBody): Promise<any> {
  return request(`/dept/arrange/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [新增假日↗](http://fe.jiuworker.com:3000/project/25/interface/api/1293)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/holiday/add`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PostDeptArrangeHolidayAddBody {
  /**
   * 结束时间
   */
  endDate?: string
  enterId?: number
  id?: number
  /**
   * 假期名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
}

/* **返回类型** */
export type PostDeptArrangeHolidayAddResponse = boolean

/* **请求函数** */
export async function postDeptArrangeHolidayAdd(body: PostDeptArrangeHolidayAddBody): Promise<any> {
  return request(`/dept/arrange/holiday/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [假期删除↗](http://fe.jiuworker.com:3000/project/25/interface/api/1295)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `DELETE /dept/arrange/holiday/delete/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface DeleteDeptArrangeHolidayDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptArrangeHolidayDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptArrangeHolidayDeleteById(
  params: DeleteDeptArrangeHolidayDeleteByIdParams,
): Promise<any> {
  return request(`/dept/arrange/holiday/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑假期↗](http://fe.jiuworker.com:3000/project/25/interface/api/1297)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/holiday/edit`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PutDeptArrangeHolidayEditBody {
  /**
   * 结束时间
   */
  endDate?: string
  enterId?: number
  id?: number
  /**
   * 假期名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
}

/* **返回类型** */
export type PutDeptArrangeHolidayEditResponse = boolean

/* **请求函数** */
export async function putDeptArrangeHolidayEdit(body: PutDeptArrangeHolidayEditBody): Promise<any> {
  return request(`/dept/arrange/holiday/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [分页查询记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/1299)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/page`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface GetDeptArrangeHolidayPageQuery {
  /**
   * 部门
   */
  deptId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * 姓名
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptArrangeHolidayPageResponse {
  data: {
    /**
     * 部门
     */
    deptName: string
    /**
     * 打卡时间
     */
    gmtCreate: string
    id: number
    /**
     * 岗位
     */
    job: string
    /**
     * 姓名
     */
    name: string
    /**
     * 打卡地点
     */
    placeName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeHolidayPage(query: GetDeptArrangeHolidayPageQuery): Promise<any> {
  return request(`/dept/arrange/holiday/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [假期查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1301)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/query`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptArrangeHolidayQueryResponse = {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  status: number
}[]

/* **请求函数** */
export async function getDeptArrangeHolidayQuery(): Promise<any> {
  return request(`/dept/arrange/holiday/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [假期启用禁用↗](http://fe.jiuworker.com:3000/project/25/interface/api/1303)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/holiday/status/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求query类型** */
export interface PutDeptArrangeHolidayStatusByIdQuery {
  /**
   * 10:启用；20禁用
   */
  status?: string
}

/* **请求params类型** */
export interface PutDeptArrangeHolidayStatusByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeptArrangeHolidayStatusByIdResponse = boolean

/* **请求函数** */
export async function putDeptArrangeHolidayStatusById(
  query: PutDeptArrangeHolidayStatusByIdQuery,
  params: PutDeptArrangeHolidayStatusByIdParams,
): Promise<any> {
  return request(`/dept/arrange/holiday/status/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [排班查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1305)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptArrangeQueryResponse = {
  /**
   * 考情规则
   */
  enterDeptArrangeTimeDTOList: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId: number
    name: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo: number
  }[]
  id: number
  /**
   * 排班名称
   */
  name: string
  /**
   * 人数
   */
  personCount: number
}[]

/* **请求函数** */
export async function getDeptArrangeQuery(): Promise<any> {
  return request(`/dept/arrange/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [部门列表(包含人员)↗](http://fe.jiuworker.com:3000/project/25/interface/api/1315)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query/person`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **返回类型** */
export type GetDeptArrangeQueryPersonResponse = {
  id: number
  key: string
  menuVOList: {}[]
  name: string
  parentId: number
  personCount: number
  personPOList: {
    aiotId: string
    bizData: string
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
  }[]
}[]

/* **请求函数** */
export async function getDeptArrangeQueryPerson(): Promise<any> {
  return request(`/dept/arrange/query/person`, {
    method: Method.GET,
  })
}

/**
 * 接口 [校验勾选的部门或人员是否有其他考勤组↗](http://fe.jiuworker.com:3000/project/25/interface/api/1319)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/check`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求body类型** */
export interface PostDeptArrangeCheckBody {
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  enterId?: number
  id?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptArrangeCheckResponse = {
  arrangeType: number
  /**
   * 当前考勤排班
   */
  attendanceName: string
  /**
   * 所属部门
   */
  deptName: string
  id: number
  /**
   * 人员或部门
   */
  name: string
  orderNo: number
}[]

/* **请求函数** */
export async function postDeptArrangeCheck(body: PostDeptArrangeCheckBody): Promise<any> {
  return request(`/dept/arrange/check`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [排班详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1321)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query/detail/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface GetDeptArrangeQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeQueryDetailByIdResponse {
  /**
   * 打卡设备
   */
  deviceIdList: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType: number
  /**
   * 结束时间
   */
  endDate: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList: number[]
    /**
     * 考勤对象
     */
    id: number
    /**
     * 是否需要覆盖
     */
    isNeedOver: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId: number
    name: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo: number
  }[]
  id: number
  /**
   * 排班名称
   */
  name: string
  /**
   * 开始时间
   */
  startDate: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type: number
}

/* **请求函数** */
export async function getDeptArrangeQueryDetailById(params: GetDeptArrangeQueryDetailByIdParams): Promise<any> {
  return request(`/dept/arrange/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [主动新增考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/1323)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/again/auth`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求body类型** */
export interface PutDeptArrangeAgainAuthBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  /**
   * 授权对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  enterId?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PutDeptArrangeAgainAuthResponse = boolean

/* **请求函数** */
export async function putDeptArrangeAgainAuth(body: PutDeptArrangeAgainAuthBody): Promise<any> {
  return request(`/dept/arrange/again/auth`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [职工授权列表↗](http://fe.jiuworker.com:3000/project/25/interface/api/1325)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/auth/person`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetDeptArrangeAuthPersonQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeptArrangeAuthPersonResponse {
  data: {
    deviceName: string
    deviceNo: string
    faceUrl: string
    gmtModified: string
    id: number
    name: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeAuthPerson(query: GetDeptArrangeAuthPersonQuery): Promise<any> {
  return request(`/dept/arrange/auth/person`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [取消授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/1327)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/cancel/auth`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface PutDeptArrangeCancelAuthQuery {
  /**
   * idList
   */
  idList: string
}

/* **返回类型** */
export type PutDeptArrangeCancelAuthResponse = boolean

/* **请求函数** */
export async function putDeptArrangeCancelAuth(query: PutDeptArrangeCancelAuthQuery): Promise<any> {
  return request(`/dept/arrange/cancel/auth`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [假期查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1341)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/detail/{id}`
 * @更新时间 `2024-12-11 15:22:07`
 */

/* **请求params类型** */
export interface GetDeptArrangeHolidayDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeHolidayDetailByIdResponse {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  status: number
}

/* **请求函数** */
export async function getDeptArrangeHolidayDetailById(params: GetDeptArrangeHolidayDetailByIdParams): Promise<any> {
  return request(`/dept/arrange/holiday/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [重新授权↗](http://fe.jiuworker.com:3000/project/25/interface/api/1351)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/again/auth/{id}`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求params类型** */
export interface PutDeptArrangeAgainAuthByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeptArrangeAgainAuthByIdResponse = boolean

/* **请求函数** */
export async function putDeptArrangeAgainAuthById(params: PutDeptArrangeAgainAuthByIdParams): Promise<any> {
  return request(`/dept/arrange/again/auth/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [排班下的人员↗](http://fe.jiuworker.com:3000/project/25/interface/api/1375)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_92)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/attendance/person/{id}`
 * @更新时间 `2024-12-11 15:22:06`
 */

/* **请求query类型** */
export interface GetDeptArrangeAttendancePersonByIdQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **请求params类型** */
export interface GetDeptArrangeAttendancePersonByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeAttendancePersonByIdResponse {
  data: {
    /**
     * 考勤
     */
    attendanceName: string
    /**
     * 所属部门
     */
    deptName: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 人脸照
     */
    faceUrl: string
    id: number
    /**
     * 岗位
     */
    job: string
    name: string
    /**
     * 手机号
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    /**
     * 工号
     */
    workNo: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeAttendancePersonById(
  query: GetDeptArrangeAttendancePersonByIdQuery,
  params: GetDeptArrangeAttendancePersonByIdParams,
): Promise<any> {
  return request(`/dept/arrange/attendance/person/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [我的邀请分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1945)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/invite/labor/query`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanInviteLaborQueryQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 10:申请中；20已同意；30已拒绝
   */
  status?: string
}

/* **返回类型** */
export interface GetPlanInviteLaborQueryResponse {
  data: {
    enterLaborInviteJobDTOList: {
      amount: number
      jobId: number
      jobName: string
    }[]
    everyDayPeople: number
    /**
     * 主键ID
     */
    id: number
    /**
     * 劳务公司名称
     */
    laborName: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getPlanInviteLaborQuery(query: GetPlanInviteLaborQueryQuery): Promise<any> {
  return request(`/plan/invite/labor/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [劳务公司详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1947)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/labor/detail/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求params类型** */
export interface GetPlanLaborDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetPlanLaborDetailByIdResponse {
  /**
   * 地址
   */
  address: string
  /**
   * 信用代码
   */
  certNo: string
  /**
   * 法定代表人
   */
  contactPerson: string
  /**
   * 成立日期
   */
  createDate: string
  /**
   * 邮箱
   */
  email: string
  /**
   * 主键ID
   */
  id: number
  /**
   * 是否认证
   */
  isHadAuthentication: boolean
  /**
   * 名称
   */
  laborName: string
  /**
   * 近一个月单
   */
  monthOrder: number
  /**
   * 电话
   */
  phone: string
  photoLog: string
  /**
   * 简介
   */
  remark: string
  /**
   * 规模
   */
  scale: string
  /**
   * 评分
   */
  score: number
  /**
   * 简称
   */
  shortName: string
  /**
   * 注册资本
   */
  totalMoney: string
  /**
   * 总成单
   */
  totalOrder: number
  /**
   * 员工数
   */
  totalPeople: string
  /**
   * 官网
   */
  webUrl: string
}

/* **请求函数** */
export async function getPlanLaborDetailById(params: GetPlanLaborDetailByIdParams): Promise<any> {
  return request(`/plan/labor/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [邀请劳务公司↗](http://fe.jiuworker.com:3000/project/25/interface/api/1949)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `POST /plan/labor/invite`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface PostPlanLaborInviteBody {
  enterLaborInviteJobDTOList?: {
    amount?: number
    jobId?: number
    jobName?: string
  }[]
  everyDayPeople?: number
  id?: number
}

/* **返回类型** */
export type PostPlanLaborInviteResponse = boolean

/* **请求函数** */
export async function postPlanLaborInvite(body: PostPlanLaborInviteBody): Promise<any> {
  return request(`/plan/labor/invite`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [劳务公司库分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1951)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/labor/query`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanLaborQueryQuery {
  city?: string
  isHadAuthentication?: string
  laborIdList?: string
  maxEnterNum?: string
  maxPeopleNum?: string
  minEnterNum?: string
  minPeopleNum?: string
  orderType?: string
  pageNum?: number
  pageSize?: number
  province?: string
}

/* **返回类型** */
export interface GetPlanLaborQueryResponse {
  data: {
    /**
     * 主键ID
     */
    id: number
    /**
     * 是否认证
     */
    isHadAuthentication: boolean
    /**
     * 名称
     */
    laborName: string
    photoLog: string
    /**
     * 简介
     */
    remark: string
    /**
     * 评分
     */
    score: string
    /**
     * 简称
     */
    shortName: string
    /**
     * 企业合作数
     */
    totalEnter: number
    /**
     * 劳务人员数
     */
    totalPeople: string
  }[]
  total: number
}

/* **请求函数** */
export async function getPlanLaborQuery(query: GetPlanLaborQueryQuery): Promise<any> {
  return request(`/plan/labor/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [接单↗](http://fe.jiuworker.com:3000/project/25/interface/api/1953)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `POST /plan/out/accept`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface PostPlanOutAcceptBody {
  address?: string
  contractPerson?: string
  contractPhone?: string
  email?: string
  enterId?: number
  financeAccountId?: number
  laborId?: number
  laborName?: string
  operationType?: number
  planId?: number
  remark?: string
}

/* **返回类型** */
export type PostPlanOutAcceptResponse = boolean

/* **请求函数** */
export async function postPlanOutAccept(body: PostPlanOutAcceptBody): Promise<any> {
  return request(`/plan/out/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [用工计划详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1955)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/out/detail/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanOutDetailByIdQuery {
  /**
   * isFade
   */
  isFade?: string
}

/* **请求params类型** */
export interface GetPlanOutDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetPlanOutDetailByIdResponse {
  enterId: number
  enterPO: {
    bizData: string
    city: string
    country: string
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
    /**
     * 0:平台定价；1自己定价
     */
    priceType: number
    province: string
    status: number
  }
  enterWorkPlanDTO: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: string
      maxAge: string
      minAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: string
    }[]
    /**
     * 附件
     */
    fileUrlList: string[]
    /**
     * 是否为节假日标准计价:true是；false否
     */
    holidayType: boolean
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    isOver: boolean
    isOverNight: boolean
    /**
     * 是否发送至抢单大厅
     */
    isSendOrderHall: boolean
    /**
     * 劳务公司
     */
    laborId: number
    /**
     * 劳务状态：10计划申请取消中；20申请取消已被拒绝
     */
    laborStatus: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    oldRoomAccount: number
    /**
     * 计划名称
     */
    planName: string
    relateWorkId: number
    /**
     * 休息时长
     */
    relaxTime: number
    /**
     * 取消原因
     */
    remark: string
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    /**
     * 用工负责人
     */
    workPlanLeader: number
  }
  /**
   * 人员信息
   */
  enterWorkPlanUserVOList: {
    /**
     * 年龄
     */
    age: string
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 身份证
     */
    idCard: string
    /**
     * 岗位
     */
    jobName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 电话
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    status: number
    /**
     * 状态名称
     */
    statusName: number
    userId: number
  }[]
  /**
   * 人员进度信息
   */
  enterWorkScheduleVOList: {
    /**
     * 已经招的人数
     */
    hadJoinAmount: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 总人数
     */
    totalAmount: number
  }[]
  /**
   * 已经招收的人数
   */
  hadJoinAmount: number
  /**
   * 主键ID
   */
  id: number
  /**
   * 劳务公司名称
   */
  laborName: string
  /**
   * 工单号
   */
  planNo: string
  /**
   * 关联的审批单名称
   */
  relateWorkIdName: string
  /**
   * 单日费用
   */
  singleMoney: number
  /**
   * 状态
   */
  status: number
  /**
   * 总人数
   */
  totalAmount: number
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 工时汇总
   */
  totalHour: number
  /**
   * 总费用
   */
  totalMoney: number
  /**
   * 用工负责人名称
   */
  workPlanLeaderName: string
  /**
   * 10用工计划 20客房派单
   */
  workPlanType: number
}

/* **请求函数** */
export async function getPlanOutDetailById(
  query: GetPlanOutDetailByIdQuery,
  params: GetPlanOutDetailByIdParams,
): Promise<any> {
  return request(`/plan/out/detail/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [计划单分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/1957)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/out/query`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface GetPlanOutQueryQuery {
  enterIdList?: string
  isFade?: string
  laborEnterIdList?: string
  pageNum?: number
  pageSize?: number
  市?: string
  '排序类型：10：近期发布; 20 费用优先；30 工时优先'?: string
  最多人数?: string
  最多工时?: string
  最多费用?: string
  最少人数?: string
  最少工时?: string
  最少费用?: string
  用工开始时间?: string
  用工结束时间?: string
  省份?: string
}

/* **返回类型** */
export interface GetPlanOutQueryResponse {
  data: {
    /**
     * 用工时间(天)
     */
    dateDay: number
    /**
     * 用工时段(小时)
     */
    dateHour: number
    /**
     * 酒店名称
     */
    enterName: string
    enterWorkPlanDTO: {
      /**
       * 联系人
       */
      contractPerson: string
      /**
       * 联系人电话
       */
      contractPhone: string
      /**
       * 自动授权的设备列表
       */
      deviceIdList: {
        deviceId: number
        deviceName: string
      }[]
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 抢单截止时间
       */
      endDateOrderHall: string
      /**
       * 结束用工时段
       */
      endHour: string
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: string[]
      /**
       * 是否为节假日标准计价:true是；false否
       */
      holidayType: boolean
      id: number
      /**
       * 是否自动授权:true是；false否
       */
      isAutoAuth: boolean
      isOver: boolean
      isOverNight: boolean
      /**
       * 是否发送至抢单大厅
       */
      isSendOrderHall: boolean
      /**
       * 劳务公司
       */
      laborId: number
      /**
       * 劳务状态：10计划申请取消中；20申请取消已被拒绝
       */
      laborStatus: number
      /**
       * 计价方式：10小时；20按天；30按计件
       */
      moneyType: number
      oldRoomAccount: number
      /**
       * 计划名称
       */
      planName: string
      relateWorkId: number
      /**
       * 休息时长
       */
      relaxTime: number
      /**
       * 取消原因
       */
      remark: string
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      /**
       * 用工负责人
       */
      workPlanLeader: number
    }
    gmtCreate: string
    /**
     * 主键ID
     */
    id: number
    laborId: number
    /**
     * 劳务公司
     */
    laborName: string
    /**
     * 招工进度
     */
    percent: string
    /**
     * 工单号
     */
    planNo: string
    /**
     * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
     */
    status: number
    /**
     * 总工时
     */
    totalHour: number
    /**
     * 总费用
     */
    totalMoney: number
    workPlanType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getPlanOutQuery(query: GetPlanOutQueryQuery): Promise<any> {
  return request(`/plan/out/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [更新劳务公司信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/1967)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `POST /plan/out/update/labor`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求body类型** */
export interface PostPlanOutUpdateLaborBody {
  /**
   * 地址
   */
  address?: string
  /**
   * 信用代码
   */
  certNo?: string
  city?: string
  /**
   * 法定代表人
   */
  contactPerson?: string
  /**
   * 成立日期
   */
  createDate?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 是否认证
   */
  isHadAuthentication?: boolean
  laborId?: number
  /**
   * 名称
   */
  laborName?: string
  pageNum?: number
  /**
   * 电话
   */
  phone?: string
  photoLog?: string
  province?: string
  /**
   * 简介
   */
  remark?: string
  /**
   * 规模
   */
  scale?: string
  /**
   * 简称
   */
  shortName?: string
  /**
   * 注册资本
   */
  totalMoney?: string
  /**
   * 员工数
   */
  totalPeople?: string
  /**
   * 官网
   */
  webUrl?: string
}

/* **返回类型** */
export type PostPlanOutUpdateLaborResponse = boolean

/* **请求函数** */
export async function postPlanOutUpdateLabor(body: PostPlanOutUpdateLaborBody): Promise<any> {
  return request(`/plan/out/update/labor`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [用工计划列表：劳务公司详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/1969)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/work/detail/{laborId}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求params类型** */
export interface GetPlanWorkDetailByLaborIdParams {
  /**
   * laborId
   */
  laborId: string
}

/* **返回类型** */
export interface GetPlanWorkDetailByLaborIdResponse {
  /**
   * 地址
   */
  address: string
  /**
   * 信用代码
   */
  certNo: string
  /**
   * 法定代表人
   */
  contactPerson: string
  /**
   * 成立日期
   */
  createDate: string
  /**
   * 邮箱
   */
  email: string
  /**
   * 主键ID
   */
  id: number
  /**
   * 是否认证
   */
  isHadAuthentication: boolean
  /**
   * 名称
   */
  laborName: string
  /**
   * 近一个月单
   */
  monthOrder: number
  /**
   * 电话
   */
  phone: string
  photoLog: string
  /**
   * 简介
   */
  remark: string
  /**
   * 规模
   */
  scale: string
  /**
   * 评分
   */
  score: number
  /**
   * 简称
   */
  shortName: string
  /**
   * 注册资本
   */
  totalMoney: string
  /**
   * 总成单
   */
  totalOrder: number
  /**
   * 员工数
   */
  totalPeople: string
  /**
   * 官网
   */
  webUrl: string
}

/* **请求函数** */
export async function getPlanWorkDetailByLaborId(params: GetPlanWorkDetailByLaborIdParams): Promise<any> {
  return request(`/plan/work/detail/${params.laborId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取订单进度↗](http://fe.jiuworker.com:3000/project/25/interface/api/2243)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/out/schedule/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求params类型** */
export interface GetPlanOutScheduleByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetPlanOutScheduleByIdResponse = {
  /**
   * 房间信息
   */
  enterRoomDetailInfoVO: {
    /**
     * 反馈信息
     */
    enterRoomDetailInfoRemarkVOList: {
      /**
       * 内容
       */
      infoContent: string
      /**
       * 时间
       */
      infoDate: string
      /**
       * 10:反馈；20回复
       */
      infoType: number
      pictureList: string[]
    }[]
    enterRoomOrderPersonVO: {
      attendanceDate: string
      faceUrl: string
      personId: number
      personName: string
    }
    floorId: number
    id: string
    /**
     * 图片列表
     */
    pictureList: string[]
    regionId: number
    /**
     * 房间备注
     */
    remark: string
    /**
     * 房间名称/房间号
     */
    room: string
    /**
     * 打扫完成时间
     */
    roomDate: string
    roomType: string
    /**
     * 10:未确认；20已确认
     */
    status: number
    unionId: string
  }[]
  /**
   * 人员信息
   */
  enterRoomOrderPersonVOList: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }[]
  /**
   * 时间
   */
  itemDate: string
  /**
   * 名称
   */
  itemName: string
}[]

/* **请求函数** */
export async function getPlanOutScheduleById(params: GetPlanOutScheduleByIdParams): Promise<any> {
  return request(`/plan/out/schedule/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [test↗](http://fe.jiuworker.com:3000/project/25/interface/api/2285)
 *
 * @分类 [众包接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_131)
 * @标签 `众包接口`
 * @请求头 `GET /plan/out/test`
 * @更新时间 `2024-03-26 15:12:09`
 */

/* **请求query类型** */
export interface GetPlanOutTestQuery {
  /**
   * enterName
   */
  enterName: string
  /**
   * room
   */
  room: string
  /**
   * unionId
   */
  unionId: string
  /**
   * userName
   */
  userName: string
}

/* **返回类型** */
export interface GetPlanOutTestResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getPlanOutTest(query: GetPlanOutTestQuery): Promise<any> {
  return request(`/plan/out/test`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [灵工考勤：每日考勤导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2045)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/attendance/day/{planId}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetExcelAttendanceDayByPlanIdQuery {
  /**
   * dayDate
   */
  dayDate: string
}

/* **请求params类型** */
export interface GetExcelAttendanceDayByPlanIdParams {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetExcelAttendanceDayByPlanIdResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelAttendanceDayByPlanId(
  query: GetExcelAttendanceDayByPlanIdQuery,
  params: GetExcelAttendanceDayByPlanIdParams,
): Promise<any> {
  return request(`/excel/attendance/day/${params.planId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工考勤：每日考勤↗](http://fe.jiuworker.com:3000/project/25/interface/api/2047)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/dept/day`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetExcelDeptDayQuery {
  /**
   * dayDate
   */
  dayDate: string
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetExcelDeptDayResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelDeptDay(query: GetExcelDeptDayQuery): Promise<any> {
  return request(`/excel/dept/day`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工考勤：月度汇总导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2079)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/month`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetExcelMonthQuery {
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetExcelMonthResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelMonth(query: GetExcelMonthQuery): Promise<any> {
  return request(`/excel/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [灵工考勤：月度汇总导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2081)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/month/{month}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求query类型** */
export interface GetExcelMonthByMonthQuery {
  /**
   * planId
   */
  planId: string
}

/* **请求params类型** */
export interface GetExcelMonthByMonthParams {
  /**
   * 月份yyyy-MM-dd
   */
  month: string
}

/* **返回类型** */
export interface GetExcelMonthByMonthResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelMonthByMonth(
  query: GetExcelMonthByMonthQuery,
  params: GetExcelMonthByMonthParams,
): Promise<any> {
  return request(`/excel/month/${params.month}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [报表统计：月统计导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2277)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/static/export/month/{year}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface GetExcelStaticExportMonthByYearParams {
  /**
   * year
   */
  year: string
}

/* **返回类型** */
export interface GetExcelStaticExportMonthByYearResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelStaticExportMonthByYear(params: GetExcelStaticExportMonthByYearParams): Promise<any> {
  return request(`/excel/static/export/month/${params.year}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [报表统计：日统计导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2279)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/static/export/{month}`
 * @更新时间 `2024-03-25 11:32:07`
 */

/* **请求params类型** */
export interface GetExcelStaticExportByMonthParams {
  /**
   * 月份yyyy-MM-dd
   */
  month: string
}

/* **返回类型** */
export interface GetExcelStaticExportByMonthResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getExcelStaticExportByMonth(params: GetExcelStaticExportByMonthParams): Promise<any> {
  return request(`/excel/static/export/${params.month}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [报表统计：月统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/2281)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/static/month/{year}`
 * @更新时间 `2024-12-11 15:22:08`
 */

/* **请求params类型** */
export interface GetExcelStaticMonthByYearParams {
  /**
   * year
   */
  year: string
}

/* **返回类型** */
export interface GetExcelStaticMonthByYearResponse {
  dayExcelVOList: {
    dateName: string
    hadPayMoney: string
    totalMoney: string
  }[]
  hadPayMoney: string
  totalMoney: string
}

/* **请求函数** */
export async function getExcelStaticMonthByYear(params: GetExcelStaticMonthByYearParams): Promise<any> {
  return request(`/excel/static/month/${params.year}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [报表统计：日统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/2283)
 *
 * @分类 [文件导出管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_135)
 * @标签 `文件导出管理`
 * @请求头 `GET /excel/static/{month}`
 * @更新时间 `2024-03-25 11:32:07`
 */

/* **请求params类型** */
export interface GetExcelStaticByMonthParams {
  /**
   * 月份yyyy-MM-dd
   */
  month: string
}

/* **返回类型** */
export interface GetExcelStaticByMonthResponse {
  dayExcelVOList: {
    date: string
    hadPayMoney: string
    totalMoney: string
  }[]
  hadPayMoney: string
  totalMoney: string
}

/* **请求函数** */
export async function getExcelStaticByMonth(params: GetExcelStaticByMonthParams): Promise<any> {
  return request(`/excel/static/${params.month}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [创建房客订单↗](http://fe.jiuworker.com:3000/project/25/interface/api/2105)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `POST /room/plan/add`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomPlanAddBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  roomAmount?: number
  /**
   * 客房量
   */
  roomInfoDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomAmount?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  /**
   * 开始用工时段
   */
  startHour?: string
  totalMoney?: number
  /**
   * 用工时间
   */
  workDate?: string
}

/* **返回类型** */
export type PostRoomPlanAddResponse = boolean

/* **请求函数** */
export async function postRoomPlanAdd(body: PostRoomPlanAddBody): Promise<any> {
  return request(`/room/plan/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [取消订单↗](http://fe.jiuworker.com:3000/project/25/interface/api/2107)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `PUT /room/plan/cancel/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface PutRoomPlanCancelByIdQuery {
  /**
   * remark
   */
  remark?: string
}

/* **请求params类型** */
export interface PutRoomPlanCancelByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutRoomPlanCancelByIdResponse = boolean

/* **请求函数** */
export async function putRoomPlanCancelById(
  query: PutRoomPlanCancelByIdQuery,
  params: PutRoomPlanCancelByIdParams,
): Promise<any> {
  return request(`/room/plan/cancel/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [编辑房客订单↗](http://fe.jiuworker.com:3000/project/25/interface/api/2109)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `PUT /room/plan/edit`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PutRoomPlanEditBody {
  /**
   * 联系人
   */
  contractPerson?: string
  /**
   * 联系人电话
   */
  contractPhone?: string
  /**
   * 自动授权的设备列表
   */
  deviceIdList?: {
    deviceId?: number
    deviceName?: string
  }[]
  /**
   * 抢单截止时间
   */
  endDateOrderHall?: string
  /**
   * 结束用工时段
   */
  endHour?: string
  id?: number
  /**
   * 是否自动授权:true是；false否
   */
  isAutoAuth?: boolean
  roomAmount?: number
  /**
   * 客房量
   */
  roomInfoDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomAmount?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
  /**
   * 开始用工时段
   */
  startHour?: string
  totalMoney?: number
  /**
   * 用工时间
   */
  workDate?: string
}

/* **返回类型** */
export type PutRoomPlanEditResponse = boolean

/* **请求函数** */
export async function putRoomPlanEdit(body: PutRoomPlanEditBody): Promise<any> {
  return request(`/room/plan/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [获取订单详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/2111)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/info/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface GetRoomPlanInfoByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetRoomPlanInfoByIdResponse {
  /**
   * 人员信息
   */
  enterWorkPlanUserVOList: {
    /**
     * 年龄
     */
    age: string
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 身份证
     */
    idCard: string
    /**
     * 岗位
     */
    jobName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 电话
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    status: number
    /**
     * 状态名称
     */
    statusName: number
    userId: number
  }[]
  enterWorkRoomPlanDTO: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    roomAmount: number
    /**
     * 客房量
     */
    roomInfoDTOList: {
      currentMoney: number
      holidayMoney: number
      money: number
      roomAmount: number
      roomTypeName: string
      unitMoney: string
      weekendMoney: number
    }[]
    /**
     * 开始用工时段
     */
    startHour: string
    totalMoney: number
    /**
     * 用工时间
     */
    workDate: string
  }
  gmtCreate: string
  /**
   * 主键ID
   */
  id: number
  laborId: number
  /**
   * 劳务公司
   */
  laborName: string
  planName: string
  /**
   * 工单号
   */
  planNo: string
  roomMoney: number
  /**
   * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
   */
  status: number
  /**
   * 总费用
   */
  totalMoney: number
}

/* **请求函数** */
export async function getRoomPlanInfoById(params: GetRoomPlanInfoByIdParams): Promise<any> {
  return request(`/room/plan/info/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2113)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/query`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomPlanQueryQuery {
  enterId?: string
  pageNum?: number
  pageSize?: number
  userId?: string
  workPlanType?: string
  状态?: string
  用工开始时间?: string
  用工结束时间?: string
  计划单号?: string
  计划单名称?: string
}

/* **返回类型** */
export interface GetRoomPlanQueryResponse {
  data: {
    /**
     * 用工时段(小时)
     */
    dateHour: number
    /**
     * 酒店名称
     */
    enterName: string
    enterWorkPlanDTO: {
      /**
       * 联系人
       */
      contractPerson: string
      /**
       * 联系人电话
       */
      contractPhone: string
      /**
       * 自动授权的设备列表
       */
      deviceIdList: {
        deviceId: number
        deviceName: string
      }[]
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 抢单截止时间
       */
      endDateOrderHall: string
      /**
       * 结束用工时段
       */
      endHour: string
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: string
        maxAge: string
        minAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: string
      }[]
      /**
       * 附件
       */
      fileUrlList: string[]
      /**
       * 是否为节假日标准计价:true是；false否
       */
      holidayType: boolean
      id: number
      /**
       * 是否自动授权:true是；false否
       */
      isAutoAuth: boolean
      isOver: boolean
      isOverNight: boolean
      /**
       * 是否发送至抢单大厅
       */
      isSendOrderHall: boolean
      /**
       * 劳务公司
       */
      laborId: number
      /**
       * 劳务状态：10计划申请取消中；20申请取消已被拒绝
       */
      laborStatus: number
      /**
       * 计价方式：10小时；20按天；30按计件
       */
      moneyType: number
      oldRoomAccount: number
      /**
       * 计划名称
       */
      planName: string
      relateWorkId: number
      /**
       * 休息时长
       */
      relaxTime: number
      /**
       * 取消原因
       */
      remark: string
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      /**
       * 用工负责人
       */
      workPlanLeader: number
    }
    /**
     * 房客信息
     */
    faceList: string[]
    gmtCreate: string
    /**
     * 主键ID
     */
    id: number
    laborId: number
    /**
     * 劳务公司
     */
    laborName: string
    /**
     * 工单号
     */
    planNo: string
    roomAmount: number
    /**
     * 房客信息
     */
    roomInfo: string
    roomInfoDTOList: {
      currentMoney: number
      holidayMoney: number
      money: number
      roomAmount: number
      roomTypeName: string
      unitMoney: string
      weekendMoney: number
    }[]
    /**
     * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
     */
    status: number
    /**
     * 总费用
     */
    totalMoney: number
  }[]
  total: number
}

/* **请求函数** */
export async function getRoomPlanQuery(query: GetRoomPlanQueryQuery): Promise<any> {
  return request(`/room/plan/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [修改房量↗](http://fe.jiuworker.com:3000/project/25/interface/api/2115)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `PUT /room/plan/room/amount/{id}`
 * @更新时间 `2024-04-07 14:40:09`
 */

/* **请求query类型** */
export interface PutRoomPlanRoomAmountByIdQuery {
  /**
   * 负数代表减
   */
  amount?: string
}

/* **请求params类型** */
export interface PutRoomPlanRoomAmountByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutRoomPlanRoomAmountByIdResponse = boolean

/* **请求函数** */
export async function putRoomPlanRoomAmountById(
  query: PutRoomPlanRoomAmountByIdQuery,
  params: PutRoomPlanRoomAmountByIdParams,
): Promise<any> {
  return request(`/room/plan/room/amount/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [获取订单进度↗](http://fe.jiuworker.com:3000/project/25/interface/api/2117)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/schedule/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface GetRoomPlanScheduleByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetRoomPlanScheduleByIdResponse = {
  /**
   * 房间信息
   */
  enterRoomDetailInfoVO: {
    /**
     * 反馈信息
     */
    enterRoomDetailInfoRemarkVOList: {
      /**
       * 内容
       */
      infoContent: string
      /**
       * 时间
       */
      infoDate: string
      /**
       * 10:反馈；20回复
       */
      infoType: number
      pictureList: string[]
    }[]
    enterRoomOrderPersonVO: {
      attendanceDate: string
      faceUrl: string
      personId: number
      personName: string
    }
    floorId: number
    id: string
    /**
     * 图片列表
     */
    pictureList: string[]
    regionId: number
    /**
     * 房间备注
     */
    remark: string
    /**
     * 房间名称/房间号
     */
    room: string
    /**
     * 打扫完成时间
     */
    roomDate: string
    roomType: string
    /**
     * 10:未确认；20已确认
     */
    status: number
    unionId: string
  }[]
  /**
   * 人员信息
   */
  enterRoomOrderPersonVOList: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }[]
  /**
   * 时间
   */
  itemDate: string
  /**
   * 名称
   */
  itemName: string
}[]

/* **请求函数** */
export async function getRoomPlanScheduleById(params: GetRoomPlanScheduleByIdParams): Promise<any> {
  return request(`/room/plan/schedule/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [房间反馈↗](http://fe.jiuworker.com:3000/project/25/interface/api/2123)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/feedback`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomPlanFeedbackQuery {
  /**
   * content
   */
  content: string
  /**
   * id
   */
  id: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetRoomPlanFeedbackResponse = boolean

/* **请求函数** */
export async function getRoomPlanFeedback(query: GetRoomPlanFeedbackQuery): Promise<any> {
  return request(`/room/plan/feedback`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房间确认↗](http://fe.jiuworker.com:3000/project/25/interface/api/2131)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/feedback/confirm`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomPlanFeedbackConfirmQuery {
  /**
   * id
   */
  id: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetRoomPlanFeedbackConfirmResponse = boolean

/* **请求函数** */
export async function getRoomPlanFeedbackConfirm(query: GetRoomPlanFeedbackConfirmQuery): Promise<any> {
  return request(`/room/plan/feedback/confirm`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [客房派单计划数量查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2287)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/query/count`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export interface GetRoomPlanQueryCountResponse {
  /**
   * 待考勤确认
   */
  attendanceAmount: number
  /**
   * 已取消
   */
  cancelAmount: number
  /**
   * 已完成
   */
  finishAmount: number
  /**
   * 待劳务确认
   */
  laboringAmount: number
  /**
   * 待财务结算
   */
  moneyAmount: number
  /**
   * 接单中数量
   */
  orderingAmount: number
  /**
   * 已拒绝
   */
  refuseAmount: number
  /**
   * 待接单数量
   */
  waitingAmount: number
  /**
   * 进行中数量
   */
  workingAmount: number
}

/* **请求函数** */
export async function getRoomPlanQueryCount(): Promise<any> {
  return request(`/room/plan/query/count`, {
    method: Method.GET,
  })
}

/**
 * 接口 [修改房量↗](http://fe.jiuworker.com:3000/project/25/interface/api/2367)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `PUT /room/plan/room/amount`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PutRoomPlanRoomAmountBody {
  id?: number
  roomInfoDTOList?: {
    currentMoney?: number
    holidayMoney?: number
    money?: number
    roomAmount?: number
    roomTypeName?: string
    unitMoney?: string
    weekendMoney?: number
  }[]
}

/* **返回类型** */
export type PutRoomPlanRoomAmountResponse = boolean

/* **请求函数** */
export async function putRoomPlanRoomAmount(body: PutRoomPlanRoomAmountBody): Promise<any> {
  return request(`/room/plan/room/amount`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [房间类型查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2369)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/room/type`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomPlanRoomTypeQuery {
  /**
   * 格式：yyyy-MM-dd
   */
  date?: string
}

/* **返回类型** */
export type GetRoomPlanRoomTypeResponse = {
  currentMoney: number
  holidayMoney: number
  money: number
  roomTypeName: string
  unitMoney: string
  weekendMoney: number
}[]

/* **请求函数** */
export async function getRoomPlanRoomType(query: GetRoomPlanRoomTypeQuery): Promise<any> {
  return request(`/room/plan/room/type`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房间进度统计↗](http://fe.jiuworker.com:3000/project/25/interface/api/2383)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `GET /room/plan/room/static`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomPlanRoomStaticQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetRoomPlanRoomStaticResponse = {
  personCountDTOList: {
    amount: number
    roomType: string
  }[]
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function getRoomPlanRoomStatic(query: GetRoomPlanRoomStaticQuery): Promise<any> {
  return request(`/room/plan/room/static`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [发起结算↗](http://fe.jiuworker.com:3000/project/25/interface/api/2421)
 *
 * @分类 [房客派单↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_140)
 * @标签 `房客派单`
 * @请求头 `POST /room/plan/settlement`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomPlanSettlementBody {
  /**
   * 是否结清
   */
  isOver?: boolean
  /**
   * 结算金额
   */
  money?: number
  /**
   * 密码
   */
  passWord?: string
  /**
   * 用工计划ID
   */
  planId?: number
  /**
   * 备注
   */
  remark?: string
}

/* **返回类型** */
export type PostRoomPlanSettlementResponse = boolean

/* **请求函数** */
export async function postRoomPlanSettlement(body: PostRoomPlanSettlementBody): Promise<any> {
  return request(`/room/plan/settlement`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [打卡↗](http://fe.jiuworker.com:3000/project/25/interface/api/2119)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `POST /app/out/attendance/add`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppOutAttendanceAddBody {
  /**
   * 打卡时间
   */
  attendanceDate?: string
  /**
   * 用户身份证号
   */
  cardNo?: string
  /**
   * 用工计划ID
   */
  planId?: number
}

/* **返回类型** */
export type PostAppOutAttendanceAddResponse = boolean

/* **请求函数** */
export async function postAppOutAttendanceAdd(body: PostAppOutAttendanceAddBody): Promise<any> {
  return request(`/app/out/attendance/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [拍照计件↗](http://fe.jiuworker.com:3000/project/25/interface/api/2121)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `POST /app/out/attendance/item`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppOutAttendanceItemBody {
  /**
   * 用户身份证号
   */
  cardNo?: string
  floorId?: number
  /**
   * 图片列表
   */
  pictureList?: string[]
  /**
   * 用工计划ID
   */
  planId?: number
  regionId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 房间号
   */
  room?: string
  unionId?: string
}

/* **返回类型** */
export type PostAppOutAttendanceItemResponse = boolean

/* **请求函数** */
export async function postAppOutAttendanceItem(body: PostAppOutAttendanceItemBody): Promise<any> {
  return request(`/app/out/attendance/item`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [房间回复↗](http://fe.jiuworker.com:3000/project/25/interface/api/2125)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/feedback`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceFeedbackQuery {
  /**
   * content
   */
  content: string
  /**
   * id
   */
  id: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppOutAttendanceFeedbackResponse = boolean

/* **请求函数** */
export async function getAppOutAttendanceFeedback(query: GetAppOutAttendanceFeedbackQuery): Promise<any> {
  return request(`/app/out/attendance/feedback`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [重新拍照↗](http://fe.jiuworker.com:3000/project/25/interface/api/2127)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `POST /app/out/attendance/item/again`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求body类型** */
export interface PostAppOutAttendanceItemAgainBody {
  id?: string
  /**
   * 图片列表
   */
  pictureList?: string[]
  planId?: number
  /**
   * 备注
   */
  remark?: string
}

/* **返回类型** */
export type PostAppOutAttendanceItemAgainResponse = boolean

/* **请求函数** */
export async function postAppOutAttendanceItemAgain(body: PostAppOutAttendanceItemAgainBody): Promise<any> {
  return request(`/app/out/attendance/item/again`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [上传的房间信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/2129)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/room/info`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceRoomInfoQuery {
  /**
   * cardNo
   */
  cardNo: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppOutAttendanceRoomInfoResponse = {
  /**
   * 反馈信息
   */
  enterRoomDetailInfoRemarkVOList: {
    /**
     * 内容
     */
    infoContent: string
    /**
     * 时间
     */
    infoDate: string
    /**
     * 10:反馈；20回复
     */
    infoType: number
    pictureList: string[]
  }[]
  enterRoomOrderPersonVO: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }
  floorId: number
  id: string
  /**
   * 图片列表
   */
  pictureList: string[]
  regionId: number
  /**
   * 房间备注
   */
  remark: string
  /**
   * 房间名称/房间号
   */
  room: string
  /**
   * 打扫完成时间
   */
  roomDate: string
  roomType: string
  /**
   * 10:未确认；20已确认
   */
  status: number
  unionId: string
}[]

/* **请求函数** */
export async function getAppOutAttendanceRoomInfo(query: GetAppOutAttendanceRoomInfoQuery): Promise<any> {
  return request(`/app/out/attendance/room/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [打卡信息↗](http://fe.jiuworker.com:3000/project/25/interface/api/2271)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/attendance/info`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceAttendanceInfoQuery {
  /**
   * attendanceDate
   */
  attendanceDate?: string
  /**
   * cardNo
   */
  cardNo: string
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export interface GetAppOutAttendanceAttendanceInfoResponse {
  afterDate: string
  moonDate: string
}

/* **请求函数** */
export async function getAppOutAttendanceAttendanceInfo(query: GetAppOutAttendanceAttendanceInfoQuery): Promise<any> {
  return request(`/app/out/attendance/attendance/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [验证房间号↗](http://fe.jiuworker.com:3000/project/25/interface/api/2371)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/room/info/detail`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceRoomInfoDetailQuery {
  /**
   * floorId
   */
  floorId?: string
  /**
   * planId
   */
  planId: string
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName: string
}

/* **返回类型** */
export interface GetAppOutAttendanceRoomInfoDetailResponse {
  errorMsg: string
  roomName: string
  roomType: string
  success: boolean
}

/* **请求函数** */
export async function getAppOutAttendanceRoomInfoDetail(query: GetAppOutAttendanceRoomInfoDetailQuery): Promise<any> {
  return request(`/app/out/attendance/room/info/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2443)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/query/status`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceQueryStatusQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppOutAttendanceQueryStatusResponse = {
  roomPOList: {
    enterId: number
    enterRoomPersonVOList: {
      attendanceId: number
      enterId: number
      gmtCreate: string
      gmtModified: string
      id: number
      money: number
      planId: number
      roomId: number
      roomType: string
      userId: number
      userName: string
    }[]
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  roomType: string
}[]

/* **请求函数** */
export async function getAppOutAttendanceQueryStatus(query: GetAppOutAttendanceQueryStatusQuery): Promise<any> {
  return request(`/app/out/attendance/query/status`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取保洁数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/2487)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/user/list`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceUserListQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * planId
   */
  planId: string
  /**
   * roomType
   */
  roomType?: string
}

/* **返回类型** */
export type GetAppOutAttendanceUserListResponse = {
  planId: number
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function getAppOutAttendanceUserList(query: GetAppOutAttendanceUserListQuery): Promise<any> {
  return request(`/app/out/attendance/user/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [选择保洁↗](http://fe.jiuworker.com:3000/project/25/interface/api/2489)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/user/{id}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceUserByIdQuery {
  /**
   * planId
   */
  planId: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface GetAppOutAttendanceUserByIdParams {
  /**
   * 房间ID
   */
  id: string
}

/* **返回类型** */
export type GetAppOutAttendanceUserByIdResponse = boolean

/* **请求函数** */
export async function getAppOutAttendanceUserById(
  query: GetAppOutAttendanceUserByIdQuery,
  params: GetAppOutAttendanceUserByIdParams,
): Promise<any> {
  return request(`/app/out/attendance/user/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取房态数量↗](http://fe.jiuworker.com:3000/project/25/interface/api/2491)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/amount`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceAmountQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export interface GetAppOutAttendanceAmountResponse {
  hadClean: number
  todayClean: number
  waitingClean: number
}

/* **请求函数** */
export async function getAppOutAttendanceAmount(query: GetAppOutAttendanceAmountQuery): Promise<any> {
  return request(`/app/out/attendance/amount`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [工人端撤回↗](http://fe.jiuworker.com:3000/project/25/interface/api/2533)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/person/room/reset/{id}`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendancePersonRoomResetByIdQuery {
  /**
   * planId
   */
  planId: string
}

/* **请求params类型** */
export interface GetAppOutAttendancePersonRoomResetByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetAppOutAttendancePersonRoomResetByIdResponse = boolean

/* **请求函数** */
export async function getAppOutAttendancePersonRoomResetById(
  query: GetAppOutAttendancePersonRoomResetByIdQuery,
  params: GetAppOutAttendancePersonRoomResetByIdParams,
): Promise<any> {
  return request(`/app/out/attendance/person/room/reset/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [撤回(删除房间记录)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2535)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/room/reset`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceRoomResetQuery {
  /**
   * planId
   */
  planId: string
  /**
   * roomId
   */
  roomId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type GetAppOutAttendanceRoomResetResponse = boolean

/* **请求函数** */
export async function getAppOutAttendanceRoomReset(query: GetAppOutAttendanceRoomResetQuery): Promise<any> {
  return request(`/app/out/attendance/room/reset`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询区域和楼层(结合一起)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2757)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/query/room/all`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceQueryRoomAllQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppOutAttendanceQueryRoomAllResponse = {
  id: number
  isDefault: number
  name: string
  regionId: number
  roomFloorPOList: {
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    isDefault: number
    name: string
    regionId: number
  }[]
}[]

/* **请求函数** */
export async function getAppOutAttendanceQueryRoomAll(query: GetAppOutAttendanceQueryRoomAllQuery): Promise<any> {
  return request(`/app/out/attendance/query/room/all`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2759)
 *
 * @分类 [蓝色荣耀端：工人房客派单相关接口↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_141)
 * @标签 `蓝色荣耀端：工人房客派单相关接口`
 * @请求头 `GET /app/out/attendance/query/room/status`
 * @更新时间 `2024-12-11 15:22:04`
 */

/* **请求query类型** */
export interface GetAppOutAttendanceQueryRoomStatusQuery {
  /**
   * floorId
   */
  floorId?: string
  /**
   * planId
   */
  planId: string
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export type GetAppOutAttendanceQueryRoomStatusResponse = {
  enterId: number
  enterRoomPersonVOList: {
    attendanceId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    planId: number
    roomId: number
    roomType: string
    userId: number
    userName: string
  }[]
  floorId: number
  gmtCreate: string
  gmtModified: string
  id: number
  regionId: number
  roomName: string
  roomType: string
  status: number
}[]

/* **请求函数** */
export async function getAppOutAttendanceQueryRoomStatus(query: GetAppOutAttendanceQueryRoomStatusQuery): Promise<any> {
  return request(`/app/out/attendance/query/room/status`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [额度查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2303)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/amount`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export interface GetMoneyAmountResponse {
  commission: number
  enterId: number
  freezeMoney: number
  lockMoney: number
  totalMoney: number
}

/* **请求函数** */
export async function getMoneyAmount(): Promise<any> {
  return request(`/money/amount`, {
    method: Method.GET,
  })
}

/**
 * 接口 [处理充值记录:运营端↗](http://fe.jiuworker.com:3000/project/25/interface/api/2305)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/out/pay/hand/{id}`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostMoneyOutPayHandByIdQuery {
  /**
   * filePath
   */
  filePath?: string
  /**
   * remark
   */
  remark?: string
  /**
   * 20:成功；30失败
   */
  status?: string
}

/* **请求params类型** */
export interface PostMoneyOutPayHandByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PostMoneyOutPayHandByIdResponse = boolean

/* **请求函数** */
export async function postMoneyOutPayHandById(
  query: PostMoneyOutPayHandByIdQuery,
  params: PostMoneyOutPayHandByIdParams,
): Promise<any> {
  return request(`/money/out/pay/hand/${params.id}`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [充值记录分页查询:运营端↗](http://fe.jiuworker.com:3000/project/25/interface/api/2307)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/out/pay/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostMoneyOutPayQueryQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  name?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface PostMoneyOutPayQueryResponse {
  data: {
    bizData: string
    enterName: string
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    name: string
    orderNo: string
    payType: number
    phone: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function postMoneyOutPayQuery(query: PostMoneyOutPayQueryQuery): Promise<any> {
  return request(`/money/out/pay/query`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [充值↗](http://fe.jiuworker.com:3000/project/25/interface/api/2309)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/pay`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostMoneyPayBody {
  filePath?: string
  money?: number
  name?: string
  payType?: number
  phone?: string
  remainMoney?: number
  remark?: string
  sourceType?: number
}

/* **返回类型** */
export type PostMoneyPayResponse = boolean

/* **请求函数** */
export async function postMoneyPay(body: PostMoneyPayBody): Promise<any> {
  return request(`/money/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [充值记录分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2311)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/pay/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetMoneyPayQueryQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface GetMoneyPayQueryResponse {
  data: {
    bizData: string
    createId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    orderNo: string
    payStatus: number
    payType: number
    resource: number
  }[]
  total: number
}

/* **请求函数** */
export async function getMoneyPayQuery(query: GetMoneyPayQueryQuery): Promise<any> {
  return request(`/money/pay/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [充值记录导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2313)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/pay/query/export`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetMoneyPayQueryExportQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface GetMoneyPayQueryExportResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getMoneyPayQueryExport(query: GetMoneyPayQueryExportQuery): Promise<any> {
  return request(`/money/pay/query/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [明细分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2315)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetMoneyQueryQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface GetMoneyQueryResponse {
  data: {
    bizData: string
    createId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    orderNo: string
    payStatus: number
    payType: number
    resource: number
  }[]
  total: number
}

/* **请求函数** */
export async function getMoneyQuery(query: GetMoneyQueryQuery): Promise<any> {
  return request(`/money/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [明细导出↗](http://fe.jiuworker.com:3000/project/25/interface/api/2323)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/query/export`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetMoneyQueryExportQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface GetMoneyQueryExportResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getMoneyQueryExport(query: GetMoneyQueryExportQuery): Promise<any> {
  return request(`/money/query/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [余额查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2423)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/out/pay/money`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostMoneyOutPayMoneyQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export type PostMoneyOutPayMoneyResponse = {
  commission: number
  enterId: number
  freezeMoney: number
  lockMoney: number
  totalMoney: number
}[]

/* **请求函数** */
export async function postMoneyOutPayMoney(query: PostMoneyOutPayMoneyQuery): Promise<any> {
  return request(`/money/out/pay/money`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [佣金明细↗](http://fe.jiuworker.com:3000/project/25/interface/api/2531)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/commission`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **返回类型** */
export type GetMoneyCommissionResponse = {
  commission: string
  createDate: string
  remark: string
}[]

/* **请求函数** */
export async function getMoneyCommission(): Promise<any> {
  return request(`/money/commission`, {
    method: Method.GET,
  })
}

/**
 * 接口 [提现↗](http://fe.jiuworker.com:3000/project/25/interface/api/2651)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/withdraw`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostMoneyWithdrawBody {
  code?: string
  money?: number
  name?: string
  phone?: string
}

/* **返回类型** */
export type PostMoneyWithdrawResponse = boolean

/* **请求函数** */
export async function postMoneyWithdraw(body: PostMoneyWithdrawBody): Promise<any> {
  return request(`/money/withdraw`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [提现记录分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2653)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `GET /money/withdraw/query`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface GetMoneyWithdrawQueryQuery {
  '10:充值；20消费;30提现'?: string
  '10:待审核；20审核通过；30审核失败'?: string
  agentId?: string
  enterId?: string
  enterIdList?: string
  pageNum?: number
  pageSize?: number
  sourceType?: string
  用工开始时间?: string
  用工结束时间?: string
  订单号?: string
}

/* **返回类型** */
export interface GetMoneyWithdrawQueryResponse {
  data: {
    bizData: string
    createId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    orderNo: string
    payStatus: number
    payType: number
    resource: number
  }[]
  total: number
}

/* **请求函数** */
export async function getMoneyWithdrawQuery(query: GetMoneyWithdrawQueryQuery): Promise<any> {
  return request(`/money/withdraw/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [期末余额查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/4703)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/out/ending/money`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求query类型** */
export interface PostMoneyOutEndingMoneyQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * enterIdList
   */
  enterIdList: string
}

/* **返回类型** */
export type PostMoneyOutEndingMoneyResponse = {
  commission: number
  enterId: number
  freezeMoney: number
  lockMoney: number
  totalMoney: number
}[]

/* **请求函数** */
export async function postMoneyOutEndingMoney(query: PostMoneyOutEndingMoneyQuery): Promise<any> {
  return request(`/money/out/ending/money`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [支付宝网站充值链接↗](http://fe.jiuworker.com:3000/project/25/interface/api/5159)
 *
 * @分类 [金钱相关管理↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_154)
 * @标签 `金钱相关管理`
 * @请求头 `POST /money/ali/pay`
 * @更新时间 `2024-12-11 15:22:09`
 */

/* **请求body类型** */
export interface PostMoneyAliPayBody {
  money?: number
  qrPayMode?: string
}

/* **返回类型** */
export type PostMoneyAliPayResponse = string

/* **请求函数** */
export async function postMoneyAliPay(body: PostMoneyAliPayBody): Promise<any> {
  return request(`/money/ali/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [创建房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2373)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /room/add`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomAddBody {
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  id?: number
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
}

/* **返回类型** */
export type PostRoomAddResponse = boolean

/* **请求函数** */
export async function postRoomAdd(body: PostRoomAddBody): Promise<any> {
  return request(`/room/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [批量创建房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2375)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /room/add/batch`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PostRoomAddBatchBody {
  /**
   * 房间结束房号
   */
  endRoomNo?: number
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  /**
   * 房间前缀
   */
  preNumber?: string
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
  /**
   * 房间开始房号
   */
  startRoomNo?: number
}

/* **返回类型** */
export type PostRoomAddBatchResponse = boolean

/* **请求函数** */
export async function postRoomAddBatch(body: PostRoomAddBatchBody): Promise<any> {
  return request(`/room/add/batch`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2377)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `DELETE /room/delete/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface DeleteRoomDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteRoomDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteRoomDeleteById(params: DeleteRoomDeleteByIdParams): Promise<any> {
  return request(`/room/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [修改房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2379)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `PUT /room/edit`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求body类型** */
export interface PutRoomEditBody {
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  id?: number
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
}

/* **返回类型** */
export type PutRoomEditResponse = boolean

/* **请求函数** */
export async function putRoomEdit(body: PutRoomEditBody): Promise<any> {
  return request(`/room/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2381)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/query`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomQueryQuery {
  enterId?: string
  pageNum?: number
  pageSize?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
}

/* **返回类型** */
export interface GetRoomQueryResponse {
  data: {
    enterId: number
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getRoomQuery(query: GetRoomQueryQuery): Promise<any> {
  return request(`/room/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2447)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/query/status`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export type GetRoomQueryStatusResponse = {
  roomPOList: {
    enterId: number
    enterRoomPersonVOList: {
      attendanceId: number
      enterId: number
      gmtCreate: string
      gmtModified: string
      id: number
      money: number
      planId: number
      roomId: number
      roomType: string
      userId: number
      userName: string
    }[]
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  roomType: string
}[]

/* **请求函数** */
export async function getRoomQueryStatus(): Promise<any> {
  return request(`/room/query/status`, {
    method: Method.GET,
  })
}

/**
 * 接口 [房态状态改变↗](http://fe.jiuworker.com:3000/project/25/interface/api/2449)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/status/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetRoomStatusByIdQuery {
  /**
   * 10:待打扫；20已打扫(无需打扫)；30今日已打扫
   */
  status?: string
}

/* **请求params类型** */
export interface GetRoomStatusByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetRoomStatusByIdResponse = boolean

/* **请求函数** */
export async function getRoomStatusById(query: GetRoomStatusByIdQuery, params: GetRoomStatusByIdParams): Promise<any> {
  return request(`/room/status/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态状态批量改变↗](http://fe.jiuworker.com:3000/project/25/interface/api/2477)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/status/batch`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetRoomStatusBatchQuery {
  /**
   * ids
   */
  ids: string
  /**
   * 10:待打扫；20已打扫(无需打扫)；30今日已打扫
   */
  status?: string
}

/* **返回类型** */
export type GetRoomStatusBatchResponse = boolean

/* **请求函数** */
export async function getRoomStatusBatch(query: GetRoomStatusBatchQuery): Promise<any> {
  return request(`/room/status/batch`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取房态数量↗](http://fe.jiuworker.com:3000/project/25/interface/api/2481)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/amount`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **返回类型** */
export interface GetRoomAmountResponse {
  hadClean: number
  todayClean: number
  waitingClean: number
}

/* **请求函数** */
export async function getRoomAmount(): Promise<any> {
  return request(`/room/amount`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取保洁数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/2483)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/user/list`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetRoomUserListQuery {
  /**
   * roomType
   */
  roomType: string
}

/* **返回类型** */
export type GetRoomUserListResponse = {
  planId: number
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function getRoomUserList(query: GetRoomUserListQuery): Promise<any> {
  return request(`/room/user/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [选择保洁↗](http://fe.jiuworker.com:3000/project/25/interface/api/2485)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/user/{id}`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetRoomUserByIdQuery {
  /**
   * planId
   */
  planId: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface GetRoomUserByIdParams {
  /**
   * 房间ID
   */
  id: string
}

/* **返回类型** */
export type GetRoomUserByIdResponse = boolean

/* **请求函数** */
export async function getRoomUserById(query: GetRoomUserByIdQuery, params: GetRoomUserByIdParams): Promise<any> {
  return request(`/room/user/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [撤回(删除房间记录)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2537)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/room/reset`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomRoomResetQuery {
  /**
   * planId
   */
  planId: string
  /**
   * roomId
   */
  roomId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type GetRoomRoomResetResponse = boolean

/* **请求函数** */
export async function getRoomRoomReset(query: GetRoomRoomResetQuery): Promise<any> {
  return request(`/room/room/reset`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [创建房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2665)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/add`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutRoomAddBody {
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  id?: number
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
}

/* **返回类型** */
export type PostOutRoomAddResponse = boolean

/* **请求函数** */
export async function postOutRoomAdd(body: PostOutRoomAddBody): Promise<any> {
  return request(`/out/room/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [批量创建房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2667)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/add/batch`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutRoomAddBatchBody {
  /**
   * 房间结束房号
   */
  endRoomNo?: number
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  /**
   * 房间前缀
   */
  preNumber?: string
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
  /**
   * 房间开始房号
   */
  startRoomNo?: number
}

/* **返回类型** */
export type PostOutRoomAddBatchResponse = boolean

/* **请求函数** */
export async function postOutRoomAddBatch(body: PostOutRoomAddBatchBody): Promise<any> {
  return request(`/out/room/add/batch`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [房间类型查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2669)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/all/type`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface PostOutRoomAllTypeQuery {
  /**
   * 格式：yyyy-MM-dd
   */
  date?: string
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export type PostOutRoomAllTypeResponse = {
  currentMoney: number
  holidayMoney: number
  money: number
  roomTypeName: string
  unitMoney: string
  weekendMoney: number
}[]

/* **请求函数** */
export async function postOutRoomAllType(query: PostOutRoomAllTypeQuery): Promise<any> {
  return request(`/out/room/all/type`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [获取房态数量↗](http://fe.jiuworker.com:3000/project/25/interface/api/2671)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/amount`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface PostOutRoomAmountQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export interface PostOutRoomAmountResponse {
  hadClean: number
  todayClean: number
  waitingClean: number
}

/* **请求函数** */
export async function postOutRoomAmount(query: PostOutRoomAmountQuery): Promise<any> {
  return request(`/out/room/amount`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [删除房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2673)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/delete/{id}`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface PostOutRoomDeleteByIdQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **请求params类型** */
export interface PostOutRoomDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PostOutRoomDeleteByIdResponse = boolean

/* **请求函数** */
export async function postOutRoomDeleteById(
  query: PostOutRoomDeleteByIdQuery,
  params: PostOutRoomDeleteByIdParams,
): Promise<any> {
  return request(`/out/room/delete/${params.id}`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [修改房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/2675)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/edit`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutRoomEditBody {
  enterId?: number
  /**
   * 楼层ID
   */
  floorId?: number
  id?: number
  /**
   * 区域ID
   */
  regionId?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
  /**
   * 来源：10客房派单小程序
   */
  sourceType?: number
}

/* **返回类型** */
export type PostOutRoomEditResponse = boolean

/* **请求函数** */
export async function postOutRoomEdit(body: PostOutRoomEditBody): Promise<any> {
  return request(`/out/room/edit`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2677)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/query`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomQueryQuery {
  enterId?: string
  pageNum?: number
  pageSize?: number
  /**
   * 房间名称
   */
  roomName?: string
  /**
   * 房间类型
   */
  roomType?: string
}

/* **返回类型** */
export interface GetOutRoomQueryResponse {
  data: {
    enterId: number
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOutRoomQuery(query: GetOutRoomQueryQuery): Promise<any> {
  return request(`/out/room/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2679)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/query/status`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface PostOutRoomQueryStatusQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export type PostOutRoomQueryStatusResponse = {
  roomPOList: {
    enterId: number
    enterRoomPersonVOList: {
      attendanceId: number
      enterId: number
      gmtCreate: string
      gmtModified: string
      id: number
      money: number
      planId: number
      roomId: number
      roomType: string
      userId: number
      userName: string
    }[]
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  roomType: string
}[]

/* **请求函数** */
export async function postOutRoomQueryStatus(query: PostOutRoomQueryStatusQuery): Promise<any> {
  return request(`/out/room/query/status`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [撤回(删除房间记录)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2681)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/room/reset`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface PostOutRoomRoomResetQuery {
  /**
   * planId
   */
  planId: string
  /**
   * roomId
   */
  roomId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type PostOutRoomRoomResetResponse = boolean

/* **请求函数** */
export async function postOutRoomRoomReset(query: PostOutRoomRoomResetQuery): Promise<any> {
  return request(`/out/room/room/reset`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [房态状态改变↗](http://fe.jiuworker.com:3000/project/25/interface/api/2683)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/status/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface PostOutRoomStatusByIdQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * 10:待打扫；20已打扫(无需打扫)；30今日已打扫
   */
  status?: string
}

/* **请求params类型** */
export interface PostOutRoomStatusByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PostOutRoomStatusByIdResponse = boolean

/* **请求函数** */
export async function postOutRoomStatusById(
  query: PostOutRoomStatusByIdQuery,
  params: PostOutRoomStatusByIdParams,
): Promise<any> {
  return request(`/out/room/status/${params.id}`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [获取保洁数据↗](http://fe.jiuworker.com:3000/project/25/interface/api/2685)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/user/list`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface PostOutRoomUserListQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * roomType
   */
  roomType: string
}

/* **返回类型** */
export type PostOutRoomUserListResponse = {
  planId: number
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function postOutRoomUserList(query: PostOutRoomUserListQuery): Promise<any> {
  return request(`/out/room/user/list`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [选择保洁↗](http://fe.jiuworker.com:3000/project/25/interface/api/2687)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/user/{id}`
 * @更新时间 `2024-12-11 15:22:11`
 */

/* **请求query类型** */
export interface PostOutRoomUserByIdQuery {
  /**
   * planId
   */
  planId: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface PostOutRoomUserByIdParams {
  /**
   * 房间ID
   */
  id: string
}

/* **返回类型** */
export type PostOutRoomUserByIdResponse = boolean

/* **请求函数** */
export async function postOutRoomUserById(
  query: PostOutRoomUserByIdQuery,
  params: PostOutRoomUserByIdParams,
): Promise<any> {
  return request(`/out/room/user/${params.id}`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [fixData↗](http://fe.jiuworker.com:3000/project/25/interface/api/2733)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/fix/data`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **返回类型** */
export type GetOutRoomFixDataResponse = boolean

/* **请求函数** */
export async function getOutRoomFixData(): Promise<any> {
  return request(`/out/room/fix/data`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询房间详情↗](http://fe.jiuworker.com:3000/project/25/interface/api/2767)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/query/detail/{id}`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求params类型** */
export interface GetRoomQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetRoomQueryDetailByIdResponse {
  enterId: number
  floorId: number
  gmtCreate: string
  gmtModified: string
  id: number
  regionId: number
  roomName: string
  roomType: string
  status: number
}

/* **请求函数** */
export async function getRoomQueryDetailById(params: GetRoomQueryDetailByIdParams): Promise<any> {
  return request(`/room/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [创建区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2769)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/region/add`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutRoomRegionAddBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
}

/* **返回类型** */
export type PostOutRoomRegionAddResponse = boolean

/* **请求函数** */
export async function postOutRoomRegionAdd(body: PostOutRoomRegionAddBody): Promise<any> {
  return request(`/out/room/region/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [创建楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2771)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `POST /out/room/region/add/floor`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PostOutRoomRegionAddFloorBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
  /**
   * 区域Id
   */
  regionId?: number
}

/* **返回类型** */
export type PostOutRoomRegionAddFloorResponse = boolean

/* **请求函数** */
export async function postOutRoomRegionAddFloor(body: PostOutRoomRegionAddFloorBody): Promise<any> {
  return request(`/out/room/region/add/floor`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2773)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `DELETE /out/room/region/delete/floor/{id}`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface DeleteOutRoomRegionDeleteFloorByIdQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **请求params类型** */
export interface DeleteOutRoomRegionDeleteFloorByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteOutRoomRegionDeleteFloorByIdResponse = boolean

/* **请求函数** */
export async function deleteOutRoomRegionDeleteFloorById(
  query: DeleteOutRoomRegionDeleteFloorByIdQuery,
  params: DeleteOutRoomRegionDeleteFloorByIdParams,
): Promise<any> {
  return request(`/out/room/region/delete/floor/${params.id}`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [删除区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2775)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `DELETE /out/room/region/delete/{id}`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface DeleteOutRoomRegionDeleteByIdQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **请求params类型** */
export interface DeleteOutRoomRegionDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteOutRoomRegionDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteOutRoomRegionDeleteById(
  query: DeleteOutRoomRegionDeleteByIdQuery,
  params: DeleteOutRoomRegionDeleteByIdParams,
): Promise<any> {
  return request(`/out/room/region/delete/${params.id}`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [编辑区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2777)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `PUT /out/room/region/edit`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PutOutRoomRegionEditBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
}

/* **返回类型** */
export type PutOutRoomRegionEditResponse = boolean

/* **请求函数** */
export async function putOutRoomRegionEdit(body: PutOutRoomRegionEditBody): Promise<any> {
  return request(`/out/room/region/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [编辑楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2779)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `PUT /out/room/region/edit/floor`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求body类型** */
export interface PutOutRoomRegionEditFloorBody {
  enterId?: number
  id?: number
  /**
   * 名称
   */
  name?: string
  /**
   * 区域Id
   */
  regionId?: number
}

/* **返回类型** */
export type PutOutRoomRegionEditFloorResponse = boolean

/* **请求函数** */
export async function putOutRoomRegionEditFloor(body: PutOutRoomRegionEditFloorBody): Promise<any> {
  return request(`/out/room/region/edit/floor`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询区域↗](http://fe.jiuworker.com:3000/project/25/interface/api/2781)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/region/query`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomRegionQueryQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export type GetOutRoomRegionQueryResponse = {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefault: number
  name: string
}[]

/* **请求函数** */
export async function getOutRoomRegionQuery(query: GetOutRoomRegionQueryQuery): Promise<any> {
  return request(`/out/room/region/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询区域和楼层(结合一起)↗](http://fe.jiuworker.com:3000/project/25/interface/api/2783)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/region/query/all`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomRegionQueryAllQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export type GetOutRoomRegionQueryAllResponse = {
  id: number
  isDefault: number
  name: string
  regionId: number
  roomFloorPOList: {
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    isDefault: number
    name: string
    regionId: number
  }[]
}[]

/* **请求函数** */
export async function getOutRoomRegionQueryAll(query: GetOutRoomRegionQueryAllQuery): Promise<any> {
  return request(`/out/room/region/query/all`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询楼层↗](http://fe.jiuworker.com:3000/project/25/interface/api/2785)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/region/query/floor`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomRegionQueryFloorQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * regionId
   */
  regionId: string
}

/* **返回类型** */
export type GetOutRoomRegionQueryFloorResponse = {
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  isDefault: number
  name: string
  regionId: number
}[]

/* **请求函数** */
export async function getOutRoomRegionQueryFloor(query: GetOutRoomRegionQueryFloorQuery): Promise<any> {
  return request(`/out/room/region/query/floor`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房间查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2787)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/region/query/room`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomRegionQueryRoomQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * floorId
   */
  floorId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export interface GetOutRoomRegionQueryRoomResponse {
  data: {
    enterId: number
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOutRoomRegionQueryRoom(query: GetOutRoomRegionQueryRoomQuery): Promise<any> {
  return request(`/out/room/region/query/room`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/25/interface/api/2789)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /out/room/region/query/status`
 * @更新时间 `2024-12-11 15:22:10`
 */

/* **请求query类型** */
export interface GetOutRoomRegionQueryStatusQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * floorId
   */
  floorId?: string
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export type GetOutRoomRegionQueryStatusResponse = {
  enterId: number
  enterRoomPersonVOList: {
    attendanceId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    planId: number
    roomId: number
    roomType: string
    userId: number
    userName: string
  }[]
  floorId: number
  gmtCreate: string
  gmtModified: string
  id: number
  regionId: number
  roomName: string
  roomType: string
  status: number
}[]

/* **请求函数** */
export async function getOutRoomRegionQueryStatus(query: GetOutRoomRegionQueryStatusQuery): Promise<any> {
  return request(`/out/room/region/query/status`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [删除房间类型↗](http://fe.jiuworker.com:3000/project/25/interface/api/5203)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `DELETE /room/delete/type`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface DeleteRoomDeleteTypeQuery {
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type DeleteRoomDeleteTypeResponse = boolean

/* **请求函数** */
export async function deleteRoomDeleteType(query: DeleteRoomDeleteTypeQuery): Promise<any> {
  return request(`/room/delete/type`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [保存房间类型↗](http://fe.jiuworker.com:3000/project/25/interface/api/5205)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/type`
 * @更新时间 `2024-12-11 15:22:13`
 */

/* **请求query类型** */
export interface GetRoomTypeQuery {
  /**
   * currentMoney
   */
  currentMoney?: string
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type GetRoomTypeResponse = boolean

/* **请求函数** */
export async function getRoomType(query: GetRoomTypeQuery): Promise<any> {
  return request(`/room/type`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [修改房间价格↗](http://fe.jiuworker.com:3000/project/25/interface/api/5207)
 *
 * @分类 [房间↗](http://fe.jiuworker.com:3000/project/25/interface/api/cat_159)
 * @标签 `房间`
 * @请求头 `GET /room/money/type`
 * @更新时间 `2024-12-11 15:22:12`
 */

/* **请求query类型** */
export interface GetRoomMoneyTypeQuery {
  /**
   * currentMoney
   */
  currentMoney: string
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type GetRoomMoneyTypeResponse = boolean

/* **请求函数** */
export async function getRoomMoneyType(query: GetRoomMoneyTypeQuery): Promise<any> {
  return request(`/room/money/type`, {
    method: Method.GET,
    params: query,
  })
}

/* prettier-ignore-end */
